import { TypeMapping } from "../constants/component-type";

export const convertType = (type: string): string => {
    switch (type) {
      case TypeMapping.AutoNumber:
        return "AutoNumber";
      case TypeMapping.Table:
        return "Table";
      case TypeMapping.ShortText:
        return "ShortText";
      case TypeMapping.Calendar:
        return "Calendar";
      case TypeMapping.Attachment:
        return "Attachment";
      case TypeMapping.Radio:
        return "Radio";
      case TypeMapping.MultiLine:
        return "MultiLine";
      case TypeMapping.Decimal:
        return "Decimal";
      case TypeMapping.MultiChoice:
        return "MultiChoice";
      case TypeMapping.Dropdown:
        return "Dropdown";
      case TypeMapping.Editor:
        return "Editor";
      default:
        return "";
    }
  };
  
  const validTypes: Set<string> = new Set([
    TypeMapping.ShortText,
    TypeMapping.MultiLine,
    TypeMapping.Table,
    TypeMapping.Decimal,
    TypeMapping.Dropdown,
    TypeMapping.Calendar,
    TypeMapping.Attachment,
    TypeMapping.Radio,
    TypeMapping.MultiChoice,
    TypeMapping.Number,
    TypeMapping.AutoNumber,
    TypeMapping.Editor,
  ]);

  export const isValidType = (type: string): boolean => {
    return validTypes.has(type);
  }