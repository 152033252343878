import { ILogic, ILogicTypeDataLineApprove } from "../IRequestModel/ILogicModel";

export const onProcessLogicLineApprove = async (
    logic: ILogic,
    controlTemplate: any,
    controlValue: any,
    control: any
) => {
    const jsonValue: ILogicTypeDataLineApprove =
        logic.jsonvalue &&
        logic.jsonvalue.length > 0 &&
        JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));

    const conditionsValue = onFilterCondotionsValue(
        jsonValue,
        control,
        controlTemplate,
        controlValue
    );

    if (conditionsValue.length > 0) {
        return JSON.stringify({
            logicid: logic.logicid,
            conditions: conditionsValue,
        });
    }
    return false;
};

const onFilterCondotionsValue = (
    jsonValue: ILogicTypeDataLineApprove,
    _control: any,
    controlTemplate: any,
    controlValue: any
) => {
    let conditions: { label: string; value: string; action?: string }[] = [];
    for (let i = 0; i < jsonValue?.Conditions?.length; i++) {
        const condition = jsonValue?.Conditions[i];

        if (condition.label === controlTemplate.label) {
            conditions.push({
                label: condition.label,
                value: controlValue ? controlValue.toLocaleString() : "0",
                action: condition.action,
            });
        } else {
            for (let j = 0; j < _control.length; j++) {
                const item = _control[j];

                for (let k = 0; k < item.layout.length; k++) {
                    const layout = item.layout[k];

                    if (
                        condition.label === layout.template.label &&
                        layout.template.label !== controlTemplate.label
                    ) {
                        conditions.push({
                            label: condition.label,
                            value: layout.data.value
                                ? layout.data.value.toLocaleString()
                                : "0",
                            action: condition.action,
                        });
                    }
                }
            }
        }
    }
    return conditions;
};
