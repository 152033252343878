import { Col, ConfigProvider, Row, Table } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import "./BudgetTrackingScreen.css";

const Type = {
  BUDGET: "BUDGET",
  PR: "PR",
  PO: "PO",
  PV: "PV",
  GR: "GR",
}

function renderDescription(value: any, record: any) {
  let val = record.memoId ? (
    <Link
      className="text-form-running"
      target="_blank"
      to={`/Request?MemoID=${record.memoId}`}
    >
      {value}
    </Link>
  ) : (
    value
  );
  switch (record.type) {
    case Type.BUDGET:
      return <b style={{ color: "#282f6a" }}>{val}</b>;
    case Type.PR:
      return <b>{val}</b>;
    case Type.PV:
      return <span style={{ color: "grey" }}>{val}</span>;
    case Type.GR:
      return <i style={{ color: "grey" }}>{val}</i>;
    default:
      return val;
  }
}

function renderNumber(value: number, record: any, index: number, options: any) {
  let val = value ? value.toLocaleString() : "-";
  let view = null;
  let color = "#282f6a";
  if (options.col == "remaining") {
    let prefix = options.prefix;
    let alloc = record[`${prefix}_alloc`];
    let remain = record[`${prefix}_remaining`];
    if (alloc && remain) {
      let percentage = remain / alloc;
      if (percentage < 0.1) {
        color = "#f00";
      } else if (percentage < 0.2) {
        color = "#f80";
      }
    }
  }
  switch (record.type) {
    case Type.BUDGET:
      view = <b style={{ color: color }}>{val}</b>;
      break;
    case Type.PR:
      view = <b>{val}</b>;
      break;
    case Type.PV:
      view = <i style={{ color: "grey" }}>{val}</i>;
      break;
    default:
      view = val;
  }
  return <div style={{ textAlign: "right", width: "100%" }}>{view}</div>;
}

function genBudgetColumn(prefix = "", options = {}) {
  const budgetColumn: ColumnsType<any> = [
    {
      title: "ALLOCATE",
      dataIndex: `${prefix}_alloc`,
      key: `${prefix}_alloc`,
      ...options,
      render: (value, record, index) =>
        renderNumber(value, record, index, { prefix: prefix, col: "alloc" }),
    },
    {
      title: "RESERVED",
      dataIndex: `${prefix}_reserve`,
      key: `${prefix}_reserve`,
      ...options,
      render: (value, record, index) =>
        renderNumber(value, record, index, { prefix: prefix, col: "col" }),
    },
    {
      title: "USED",
      dataIndex: `${prefix}_used`,
      key: `${prefix}_used`,
      ...options,
      render: (value, record, index) =>
        renderNumber(value, record, index, { prefix: prefix, col: "used" }),
    },
    {
      title: "PAID",
      dataIndex: `${prefix}_paid`,
      key: `${prefix}_paid`,
      ...options,
      render: (value, record, index) =>
        renderNumber(value, record, index, { prefix: prefix, col: "paid" }),
    },
    {
      title: "REMAINING",
      dataIndex: `${prefix}_remaining`,
      key: `${prefix}_remaining`,
      ...options,
      render: (value, record, index) =>
        renderNumber(value, record, index, {
          prefix: prefix,
          col: "remaining",
        }),
    },
  ];
  return budgetColumn;
}

function BudgetSummary() {
  const columns: ColumnsType<any> = [
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      fixed: "left",
      width: 300,
      render: (value, record, index) => renderDescription(value, record),
    },
    {
      title: "Summary",
      children: genBudgetColumn("summary", { fixed: "left" }),
      fixed: "left",
    },
    ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) => {
      let day = dayjs().month(m);
      return {
        title: day.format("MMM-YY"),
        key: m,
        children: genBudgetColumn(day.format("MMM").toLocaleLowerCase()),
      };
    }),
  ];

  const [data, dataSet] = useState<any[]>([]);

  useEffect(() => {
    fetch("budget-summary.json")
      .then((r) => r.json())
      .then((r) => {
        dataSet(r.data);
      });
  }, []);

  return (
    <Fragment>
      {/* <ConfigProvider
        theme={{
          components: {
            Table: {
              borderColor: '#ddd',
            },
          },
        }}
      > */}
      <Row>
        <Col span={24} style={{ padding: "1rem" }}>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            size="small"
            scroll={{ x: 7000, y: 39 * 50 }}
            pagination={{ pageSize: 50 }}
          />
        </Col>
      </Row>
      {/* </ConfigProvider> */}
    </Fragment>
  );
}

export default BudgetSummary;
