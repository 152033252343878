export const ConcatDialog =(action :string)=>{
    return "Do you want to " + action + " this record?"
}

export const TextAbstract =(text: string, length: number , isReturnSpan ?:boolean)=>{
    if (!text) {
        return "";
      }
      if (text.length <= length) {
        return text;
      }
      text = text.substring(0, length);
      var last = text.lastIndexOf(" ");
      text = text.substring(0, last);

      if (isReturnSpan) return text + "<span>...</span>";
      return text + "...";
}
