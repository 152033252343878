import React, { useMemo, useState } from "react";
import {
  IControlLayout,
  IControlProperty,
} from "../../../Context/PDFEditorContext";
import _ from "lodash";

type Props = {
  layout: IControlLayout;
  onSelectPropertyChange: (props: IControlProperty) => void;
  selectionBlock?: IControlLayout;
};

const DisplayTable = ({
  layout,
  onSelectPropertyChange,
  selectionBlock,
}: Props) => {
  const controlAttribute = layout.template.attribute as IControlProperty;

  const [columnWidths, setColumnWidths] = useState(
    !controlAttribute.columsWidth
      ? controlAttribute?.column?.map(() => 150) || []
      : controlAttribute.columsWidth
  );
  const handleMouseDown = (index: number, event: React.MouseEvent) => {
    event.preventDefault();

    const startX = event.clientX;
    const startWidth = columnWidths[index];
    let savedWidths: any[] = [...columnWidths];

    const handleMouseMove = (moveEvent: MouseEvent) => {
      const newWidth = Math.max(startWidth + moveEvent.clientX - startX, 20);

      setColumnWidths((prev) => {
        const updatedWidths = [...prev];
        updatedWidths[index] = newWidth;
        savedWidths = updatedWidths;
        return updatedWidths;
      });
    };

    const handleMouseUp = () => {
      if (!_.isEqual(columnWidths, savedWidths)) {
        const widths = getColumnWidths();
        controlAttribute.columsWidth = widths;
        onSelectPropertyChange(controlAttribute);
      }
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const getColumnWidths = () => {
    const escapedId = CSS.escape(layout.i); // Escape the ID to make it valid
    const thElements = document.querySelectorAll(
      `#${escapedId} #table-header th`
    );
    return Array.from(thElements).map(
      (th) => (th as HTMLElement).getBoundingClientRect().width
    );
  };
  return (
    <table
      id={layout.i}
      className="content"
      style={{
        width: "100%",
        height: "100%",
        border: "1px solid",
      }}
    >
      <thead>
        <tr id={"table-header"}>
          {controlAttribute?.column?.map((e, index) => {
            return (
              <th
                id={"col_" + index}
                style={{
                  fontFamily: controlAttribute?.fontFamily,
                  fontSize: controlAttribute?.fontSize,
                  fontStyle: controlAttribute?.isItalic ? "italic" : "normal",
                  fontWeight: controlAttribute?.isBold ? 700 : 100,
                  width: columnWidths[index], // Apply dynamic width
                  position: "relative", // Required for the resizer
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent:
                      controlAttribute?.hAlign === "flex-start"
                        ? "left"
                        : controlAttribute?.hAlign === "flex-end"
                        ? "right"
                        : "center",
                    alignItems: controlAttribute?.vAlign,
                  }}
                >
                  {e.label}
                </div>
                {selectionBlock?.i === layout?.i && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      width: "5px",
                      cursor: "col-resize",
                      zIndex: 1,
                    }}
                    onMouseDown={(event) => handleMouseDown(index, event)}
                  ></div>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    </table>
  );
};

export default DisplayTable;
