/**
 * PDFPreviewComponent
 * component ที่ใช้ preview PDF file ที่ไม่ใช่ iframe
 * โดย PDF จะถูกอ่านด้วน lib PDF.JS ของ Mozilla
 * แล้วทำการวาดลง Canvas พร้อมป้องกันคลิกขวา (context menu)
 */
import React, { ReactElement, useEffect, useState, useRef } from "react";
import "./PDFPreviewComponent.css";
import { PDFDocumentProxy } from "pdfjs-dist/legacy/build/pdf"; // Updated import
import { Button } from "antd";
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  ExpandOutlined,
} from "@ant-design/icons";

// The workerSrc property shall be specified.
export function PDFPreviewComponent(props: { src: string }) {
  const [elements, setElements] = useState<ReactElement[]>([]);
  // The workerSrc property shall be specified.
  const pdfjs = (window as any).pdfjsLib;
  const [scale, setScale] = useState(1.5);

  useEffect(() => {
    const renderDocument = async () => {
      try {
        const base64Src = await convertToBase64(props.src);

        // Ensure pdfjs and getDocument are defined
        if (!pdfjs?.getDocument) throw new Error("PDF.js library not loaded");

        const loadingTask = pdfjs.getDocument({ data: atob(base64Src) });

        const pdf: PDFDocumentProxy = await loadingTask.promise;
        const pages: ReactElement[] = [];

        for (let i = 1; i <= pdf.numPages; i++) {
          pages.push(
            <div key={"page-" + i} className="page">
              <canvas
                key={"canvas-page-" + i}
                ref={(node) => node && renderPage(node, pdf, i)}
                role="presentation"
              ></canvas>
            </div>
          );
        }
        setElements(pages);
      } catch (error) { }
    };

    renderDocument();
  }, [props.src, pdfjs, scale]);

  const convertToBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary); // Convert binary string to base64
  };

  const renderPage = (
    canvas: HTMLCanvasElement | null,
    pdf: PDFDocumentProxy,
    pageNum: number
  ) => {
    if (!canvas) return;

    pdf.getPage(pageNum).then((page) => {
      const viewport = page.getViewport({ scale });
      const outputScale = window.devicePixelRatio || 1;

      canvas.width = Math.floor(viewport.width * outputScale);
      canvas.height = Math.floor(viewport.height * outputScale);
      canvas.style.width = `${Math.floor(viewport.width)}px`;
      canvas.style.height = `${Math.floor(viewport.height)}px`;

      const context = canvas.getContext("2d");
      if (!context) return;

      const transform =
        outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

      const renderContext: any = {
        canvasContext: context,
        viewport,
        transform,
        intent: "display",
      };

      page.render(renderContext);
    });
  };

  function disableContextMenu(e: React.MouseEvent) {
    e.preventDefault();
  }

  return (
    <>
      <Button
        type="primary"
        shape="circle"
        icon={<ZoomInOutlined rev={undefined} />}
        size="large"
        style={{
          position: "absolute",
          right: 0,
          bottom: "155px",
          margin: "1.5rem 3.5rem",
        }}
        onClick={() => setScale((prev) => Math.min(prev + 0.25, 4))}
      />
      <Button
        type="primary"
        shape="circle"
        icon={<ZoomOutOutlined rev={undefined} />}
        size="large"
        style={{
          position: "absolute",
          right: 0,
          bottom: "95px",
          margin: "1.5rem 3.5rem",
        }}
        onClick={() => setScale((prev) => Math.max(prev - 0.25, 0.5))}
      />
      <Button
        type="primary"
        shape="circle"
        icon={<ExpandOutlined rev={undefined} />}
        size="large"
        style={{
          position: "absolute",
          right: 0,
          bottom: "35px",
          margin: "1.5rem 3.5rem",
        }}
        onClick={() => setScale(1.5)}
      />
      <div className="pdf-preview-container" onContextMenu={disableContextMenu}>
        {elements}
      </div>
    </>
  );
}
