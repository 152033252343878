import { Button, Checkbox, Divider, Input } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import React, { useState } from "react";

const CheckboxGroup = Checkbox.Group;
type Props = {
  datasource: any[];
  onChange: (list: any) => void;
  checkList: CheckboxValueType[];
  // visibleChange: any;
};

const CheckboxGroupSelelect = ({ datasource, checkList, onChange }: Props) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);

  const checkOptions = Array.from(
    new Set(datasource.map((e) => (e ? e?.toString() : "")))
  );

  const onChangeAll = (e: any) => {

    let op: any = checkOptions.filter((item: string) =>
      item?.toString()?.toLowerCase().includes(searchValue.toLowerCase())
    )
    let value = {
      value: op,
      checked: e.target.checked,
      search: searchValue
    }
    let tmp = {
      value: [],
      checked: e.target.checked,
      search: searchValue
    }
    if (searchValue === "" && e.target.checked === true) {
      onChange(value)
    } else if (searchValue !== "" && e.target.checked === true) {
      onChange(value);
    } else if (searchValue === "" && e.target.checked === false) {
      onChange(tmp);
    } else if (searchValue !== "" && e.target.checked === false) {
      onChange(value);
    } else {
      onChange(value);
    }

  }

  return (
    <>
      <div>
        <Input
          value={searchValue}
          onChange={(e) => {
            // let op: any = checkOptions.filter((item: string) =>
            //   item?.toString()?.toLowerCase().includes(e.target.value.toLowerCase())
            // )
            // let value = {
            //   value: op,
            //   checked: false,
            //   search: searchValue
            // }
            // onChange(value)
            setSearchValue(e.target.value);
          }}
        />
      </div>

      <div>
        <Checkbox onChange={onChangeAll}>Select All</Checkbox>
        <Divider style={{ margin: 5 }} />
        <CheckboxGroup
          value={checkList}
          onChange={(e: any) => {
            if (searchValue === "" && checkList.length === 0) {
              let value = {
                value: e,
                checked: true,
                search: searchValue
              }
              onChange(value);
            } else if (searchValue !== "" && checkList.length === 0) {
              let value = {
                value: e,
                checked: true,
                search: searchValue
              }
              onChange(value);
            } else if (searchValue === "" && checkList.length !== 0) {
              let value = {
                value: e,
                checked: true,
                search: searchValue
              }
              onChange(value);
            } else if (searchValue !== "" && checkList.length !== 0 && e.length !== 0) {
              let value = {
                value: e,
                checked: true,
                search: searchValue
              }
              onChange(value);
            } else if (searchValue !== "" && checkList.length === 0 && e.length === 0) {
              let value = {
                value: checkOptions.filter((item: string) =>
                  item?.toString()?.toLowerCase().includes(searchValue.toLowerCase())
                ),
                checked: false,
                search: searchValue
              }
              onChange(value);
            } else {
              let value = {
                value: checkOptions.filter((item: string) =>
                  item?.toString()?.toLowerCase().includes(searchValue.toLowerCase())
                ),
                checked: false,
                search: searchValue
              }
              onChange(value);
            }
          }}

          options={checkOptions.filter((e: string) =>
            e?.toString()?.toLowerCase().includes(searchValue.toLowerCase())
          )}
          style={{
            paddingLeft: 20,
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            maxHeight: "20vh",
          }}
        />
      </div>
      {/* <div className="ant-table-filter-dropdown-btns" style={{ padding: 5 }}>
        <div></div>
        <Button
          size="middle"
          type="primary"
          onClick={() => {
            onChange(checkedList);
          }}
        >
          Ok
        </Button>
      </div> */}
    </>
  );
};

export default CheckboxGroupSelelect;
