import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { Link } from "react-router-dom";

export const ReportTable: React.FC<{ data: any[] }> = ({ data }) => {
  const columns: ColumnsType<IOTableInterface> = [
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      render: (value, record, index) =>
        record.memoId ? (
          <Link
            className="text-form-running"
            target="_blank"
            to={`/Request?MemoID=${record.memoId}`}
          >
            {value}
          </Link>
        ) : (
          value
        ),
    },
    {
      title: "Allocated",
      dataIndex: "allocated",
      key: "allocated",
      align: "right",
      render: (value) => formatNumber(value),
    },
    {
      title: "Reserved",
      dataIndex: "reserved",
      key: "reserved",
      align: "right",
      render: (value) => formatNumber(value),
    },
    {
      title: "Used",
      dataIndex: "used",
      key: "used",
      align: "right",
      render: (value) => formatNumber(value),
    },
    {
      title: "Remaining",
      dataIndex: "remaining",
      key: "remaining",
      align: "right",
      render: (value) => formatNumber(value),
    },
  ];
  function formatNumber(value: number | null): string {
    if (value === null) {
      return "-";
    }

    return value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      size="small"
      expandable={{
        expandRowByClick: true
      }}
    />
  );
};
