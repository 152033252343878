import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Row } from "antd";
import { TextHeaderComponents } from "../../components/TextHeaderComponents/TextHeaderComponents";
import moment, { Moment } from "moment";
import { ReportTable } from "./ReportTable";
import withPerMission from "../../components/HOC/withPermission";

const BudgetReportScreen = (props: BudgetReportProps) => {
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState<number>(moment().year());
  const [pickerVal, setPickerVal] = useState<Moment | null>(moment());
  const [data, setData] = useState<any[]>([]);

  const retrieveData = async () => {
    if (pickerVal == null) {
      return;
    }
    setLoading(true);
    const reqBody = {
      year: pickerVal?.year(),
    };
    let resData: IOTableInterface[] = await fetch(
      "api/Budget/GetSimpleReport",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      }
    )
      .then((r) => r.json())
      .then((r) => r.data);
    if (Array.isArray(resData)) {
      resData = resData.map((x) => {
        if (Array.isArray(x.children)) {
          x.children = x.children.map((y) => {
            y.key = `${x.desc}_${y.desc}`;
            return y;
          });
        }
        return x;
      });
    }
    setData([...resData]);
    setYear(reqBody.year);
    setLoading(false);
  };

  useEffect(() => {
    retrieveData();
  }, []);

  return (
    <>
      {loading && (
        <div className="logo-loading">
          <img src={props.responeConfig?.pathLoading} alt="loading..." />
        </div>
      )}
      <div className="main-container">
        <div className="worklist-container">
          <div>
            <br />
            <h3>Budget Report {year}</h3>
            <form>
              <div>
                <Row style={{ paddingBottom: "10px" }}>
                  <Col xs={24} sm={4} md={2}>
                    <TextHeaderComponents
                      textHeaderProps={"Budget Year"}
                      textSubProps={"ปีงบประมาณ"}
                      isRequir
                    />
                  </Col>
                  <Col xs={24} sm={20} md={22}>
                    <span style={{ width: "156px", display: "inline-block" }}>
                      <DatePicker
                        picker="year"
                        value={pickerVal}
                        onChange={(e) => setPickerVal(e)}
                      />
                    </span>
                    <span>&nbsp;</span>
                    {/* <Button label="GO" onClick={() => retrieveData()} /> */}
                    <Button type="primary" onClick={() => retrieveData()}>
                      GO
                    </Button>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
          <div>
            <ReportTable data={data} />
          </div>
        </div>
      </div>
    </>
  );
};
export default withPerMission(BudgetReportScreen);
