import { LocalStoreType } from "../constants/local-type";
import { getDataLocal } from "../Helper/localStoreFunction";

export const GetButtonMemoByMemoId = async (dataJson: any) => {
  const email = getDataLocal(LocalStoreType.email) || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Memo/GetButtonMemoByMemoId", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});

  return respone;
};
export const ActionMemoPage = async (dataJson: any) => {
  const email = getDataLocal(LocalStoreType.email) || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Memo/ActionMemoPage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err
    });

  return respone;
};
export const GetMemoDetailById = async (dataJson: any) => {
  const respone = await fetch("api/Memo/GetMemoDetailById", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});

  return respone;
};

export const GetMemoDetail = async (dataJson: any, empId?: number) => {
  try {
    const response = await fetch("api/Memo/GetMemoDetail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    });

    const data = await response.json();

    if (!data || !data.requestDetails?.listApprovalDetails) {
      throw new Error("Invalid response structure");
    }

    if (empId !== undefined) {
      data.requestDetails.listApprovalDetails = data.requestDetails.listApprovalDetails.filter(
        (detail: any) => detail.approver?.EmployeeId === empId
      );
    }

    return data;
  } catch (err) {
    console.error("Error in GetMemoDetail:", err);
    return null; // Return null or an appropriate fallback value
  }
};


export const GetMemoById = async (dataJson: any) => {
  const email = getDataLocal(LocalStoreType.email) || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Memo/GetMemoById", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return respone;
};

export const GetAttachmentFilesByMemoId = async (dataJson: any) => {
  const email = getDataLocal(LocalStoreType.email) || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Memo/GetAttachmentFilesByMemoId", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return respone;
};

export const GetMemoDetailOnlyById = async (dataJson: any) => {
  const email = getDataLocal(LocalStoreType.email) || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Memo/GetMemoDetailOnlyById", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});

  return respone;
};

export const GetRefDocTemp = async (dataJson: any) => {
  const email = getDataLocal(LocalStoreType.email) || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Memo/GetRefDocTemp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  return respone;
};

export const GetAllMemo = async (dataJson: any) => {
  const respone = await fetch("api/Memo/GetMemoById", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  return respone;
};

export const GetRefDocFormTable = async (dataJson: any) => {
  const email = getDataLocal(LocalStoreType.email) || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Memo/GetRefDocFormTable", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  return respone;
};

export const GetMemoHistoryDetail = async (dataJson: any) => {
  const respone = await fetch("api/Memo/GetMemoHistoryDetail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return [];
    });
  return respone;
};

export const SetCheckAcces = async (dataJson: any) => {
  const email = getDataLocal(LocalStoreType.email) || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Memo/SetCheckAccess", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      // window.location.href = "/UnAuthorize";
    });

  return respone;
};

export const SpecialActions = async (dataJson: any) => {
  const email = getDataLocal(LocalStoreType.email) || "";
  dataJson.UserPrincipalName = email;
  const respone = await fetch("api/Memo/SpecialActions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      // window.location.href = "/UnAuthorize";
    });

  return respone;
};

export const MAdvanceFormByMemoIds = async (memoIds: number[]) => {
  const response = await fetch("api/Memo/MAdvancveFormByMemoIds", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(memoIds),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});
  console.log("myres", response);
  return response;
};

export const ValidateDelegate = async (dataJson: any) => {
  const respone = await fetch("api/DelegateList/ValidateDelegate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("ValidateDelegate=>err", err);
    });
  return respone;
};

export const getStatusQueueByActorId = async (id: number) => {
  const respone = await fetch(`api/Memo/GetStatusQueueByActorId?id=${id}`, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});

  return respone;
};

export const UpdateDismissById = async (id: number) => {
  const respone = await fetch(`api/Memo/UpdateDismissById?id=${id}`, {
    method: "PATCH",
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {});

  return respone;
};

