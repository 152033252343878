import { Button, Divider, Form, Input, Select, Space } from "antd";
import React, { useEffect } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

type Props = { data: any; onChange: (data: any) => void };

const ConditionTab = ({ data, onChange }: Props) => {
  return (
    <>
      <Divider>FormatDate</Divider>
      <Form.List name={["Condition", "FormatDate"]}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item {...restField} name={[name, "LABEL"]}>
                    <Input placeholder="Label" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "ADDDAY"]}>
                    <Input placeholder="Add day" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "OLDFORMAT"]}>
                    <Input placeholder="Old format" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "NEWFORMAT"]}>
                    <Input placeholder="New format" />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <Divider>ChangeField</Divider>
      <Form.List name={["Condition", "ChangeField"]}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item {...restField} name={[name, "LABEL"]}>
                    <Input placeholder="Label" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "FIELD"]}>
                    <Input placeholder="Field" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "TABLE"]}>
                    <Input placeholder="Table" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "FIELDWHERE"]}>
                    <Input placeholder="Field where" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "CONDITIONAND"]}>
                    <Input placeholder="Condition and" />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <Divider>FormatReplace</Divider>
      <Form.List name={["Condition", "FormatReplace"]}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item {...restField} name={[name, "LABEL"]}>
                    <Input placeholder="Label" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "OLD"]}>
                    <Input placeholder="Old" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "NEW"]}>
                    <Input placeholder="New" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "SYMBOL"]}>
                    <Select
                      placeholder="SYMBOL"
                      style={{ minWidth: 100 }}
                      options={[
                        {
                          value: "",
                          label: "Some",
                        },
                        {
                          value: "=",
                          label: "All",
                        }
                      ]}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <Divider>FormatSplit</Divider>
      <Form.List name={["Condition", "FormatSplit"]}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item {...restField} name={[name, "KEY"]}>
                    <Input placeholder="Key" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "LABEL"]}>
                    <Input placeholder="Label" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "INDEX"]}>
                    <Input placeholder="Index" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "SYMBOL"]}>
                  <Input placeholder="Symbol" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, "TRIM"]}>
                    <Select
                      placeholder="TRIM"
                      style={{ minWidth: 100 }}
                      options={[
                        {
                          value: "True",
                          label: "True",
                        },
                        {
                          value: "False",
                          label: "False",
                        }
                      ]}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      {" "}
    </>
  );
};

export default ConditionTab;
