import React, { useEffect, useState } from "react";
import { BsNewspaper } from "react-icons/bs";
import {
  IoDocumentTextOutline,
  IoPeopleOutline,
  IoShieldCheckmarkOutline,
  IoCloudUploadOutline,
  IoNewspaperOutline,
} from "react-icons/io5";
import { FunctionOutlined } from "@ant-design/icons";
import { Button } from "../../components/Button/Button";
import { IoAlertCircleOutline } from "react-icons/io5";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { GetAllDynamic } from "../../Services/DynamicService";
import { log } from "console";
import { GetAllMasterData } from "../../Services/MasterDataService";
import { Col, Row } from "react-bootstrap";
interface Props {
  curPage: any;
  onSelectView: (text: string) => void;
  workList?: any;
  isCopy?: any;
  isVersion?: any;
}

type SideBarData = {
  component: any;
};

export const TemplateDetailSideBarElement = (props: Props) => {
  let [checkMergeApi, setCheckMergeApi] = useState<boolean>();
  const [sideBarData, setSideBarData] = useState<SideBarData[]>([]);

  useEffect(() => {
    GetAllMasterData().then((res) => {
      const MergeAPI = res.filter(
        (e: any) => e.MasterType?.toLowerCase() === "interface" && e.IsActive === true
      )[0];
      if (MergeAPI) {
        setCheckMergeApi(true);
      }
      let isEnabledPDFDesign = res.find(
        (e: any) =>
          e.MasterType == "PDFDesign" &&
          e.IsActive == true &&
          e.Value1 == "Enabled"
      );
      let _sideBarData: SideBarData[] = [];
      _sideBarData.push({
        component: (
          <Button
            onSelectView={(e) => props.onSelectView(e)}
            title={"Information"}
            icon={<IoAlertCircleOutline />}
            page={"information"}
            curPage={props.curPage}
          />
        ),
      });
      _sideBarData.push({
        component: (
          <Button
            onSelectView={(e) => props.onSelectView(e)}
            title={"Form Design"}
            icon={<BsNewspaper />}
            page={"form_setting"}
            curPage={props.curPage}
          />
        ),
      });
      _sideBarData.push({
        component: (
          <Button
            onSelectView={(e) => props.onSelectView(e)}
            title={"Form Logic"}
            icon={<FunctionOutlined rev={undefined} />}
            page={"form_logic"}
            curPage={props.curPage}
          />
        ),
      });
      _sideBarData.push({
        component: (
          <Button
            onSelectView={(e) => props.onSelectView(e)}
            title={"Reference Document"}
            icon={<IoDocumentTextOutline />}
            page={"reference_document"}
            curPage={props.curPage}
          />
        ),
      });

      _sideBarData.push({
        component: (
          <Button
            onSelectView={(e) => props.onSelectView(e)}
            title={"Reference Information"}
            icon={<IoPeopleOutline />}
            page={"reference_information"}
            curPage={props.curPage}
          />
        ),
      });
      _sideBarData.push({
        component: (
          <Button
            onSelectView={(e) => props.onSelectView(e)}
            title={"Approval Matrix"}
            icon={<IoMdCheckmarkCircleOutline />}
            page={"approval_matrix"}
            curPage={props.curPage}
          />
        ),
      });
      _sideBarData.push({
        component: (
          <Button
            onSelectView={(e) => props.onSelectView(e)}
            title={"Authorization"}
            icon={<IoShieldCheckmarkOutline />}
            page={"authorization"}
            curPage={props.curPage}
          />
        ),
      });
      if (isEnabledPDFDesign) {
        _sideBarData.push({
          component: (
            <Button
              onSelectView={(e) => props.onSelectView(e)}
              title={"PDF Design"}
              icon={<IoNewspaperOutline />}
              page={"pdf_design"}
              curPage={props.curPage}
            />
          ),
        });
      }
      if (!props.isCopy && props.isVersion) {
        _sideBarData.push({
          component: (
            <Button
              onSelectView={(e) => props.onSelectView(e)}
              title={"Version and History"}
              icon={<IoShieldCheckmarkOutline />}
              page={"version_and_history"}
              curPage={props.curPage}
            />
          ),
        });
      }
      if (checkMergeApi) {
        _sideBarData.push({
          component: (
            <Button
              onSelectView={(e) => props.onSelectView(e)}
              title={"Interface Mapping"}
              icon={<IoCloudUploadOutline />}
              page={"interface_mapping"}
              curPage={props.curPage}
            />
          ),
        });
      }
      setSideBarData([..._sideBarData]);
    });
  }, [props.curPage, props.isCopy, props.isVersion]);

  return (
    // <div
    // className="sidebar-create-element"
    // // style={{
    // //   display: "flex",
    // //   flexDirection: "column",
    // //   rowGap: "0.625rem",
    // //   width: "100%",
    // // }}
    // >
    //   {sideBarData.map((data, idx) => (
    //     <div key={idx}>{data.component}</div>
    //   ))}
    // </div>

    <Row className="sidebar-style-display">
      {sideBarData.map((data, idx) => (
        <Col xs={12} sm={6} md={4} lg={12} xl={12} xxl={12} style={{ padding: '4px 12px' }}>
          <div key={idx}>{data.component}</div>
        </Col>
      ))}
    </Row>
  );
};
