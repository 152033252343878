import React, { useState, useEffect, useRef } from "react";
import "./AttachmentComponent.css";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import moment from "moment";

import { AiFillEye } from "react-icons/ai";
import LogoLoading from "../../../assets/LoadingWOLFmini.gif";
import { Checkbox } from "primereact/checkbox";
import { BiUpload } from "react-icons/bi";
import { InputText } from "primereact/inputtext";
import { Form } from "react-bootstrap";
import { SplitButton } from "primereact/splitbutton";
import { ConfirmDialog } from "primereact/confirmdialog";
import DescriptionConfigComponent from "./DescriptionConfigComponent";
import axios from "axios";
import { UploadFileAttachFiles } from "../../../Services/AttachFileService";
import { IoMdAlert } from "react-icons/io";
import { Dialog } from "primereact/dialog";
import useAlert from "../../../hooks/useAlert";
import { GetPermissionByEmpId } from "../../../Services/RoleServices";
import {
  CannotDowLoadPDFDefaultConfiguration,
  getUploadFileSettingConfiguration,
} from "../../../Services/ConfigurationService";
import { Badge } from "antd";
import { set } from "react-hook-form";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import useMasterSetting from "../../../hooks/useMasterSetting";
import { useHistory } from "react-router-dom";
// import samplePDF from "./2410001_Retirement.pdf";
// import samplePDF from "./2- PR069889_WorkingReport2.pdf";
// import samplePDF from "./2410001_WorkingReport1.pdf";
// import samplePDF from "./3- PR069889_advance.pdf";
// import samplePDF from "./1- PR069889.pdf";

import { browserDetector } from "../../../Helper/DeviceDetect";
import { Device } from "../../../constants/device-type";
interface Props {
  listFileAttachDetails: any;
  setListFileAttachDetails: any;
  setMemoDetail: any;
  memoDetail: any;
  userData: any;
  canEditDoc: boolean;
  listFormNames: any;
  checkActionPage: string;
  masterATDLFT: any;
  t: any;
  fileAttact: any;
}

const AttachmentComponent: React.FC<Props> = ({
  t,
  listFileAttachDetails,
  setListFileAttachDetails,
  setMemoDetail,
  memoDetail,
  userData,
  canEditDoc,
  listFormNames,
  checkActionPage,
  masterATDLFT,
  fileAttact
}) => {
  const history = useHistory();
  const { toggleAlert } = useAlert();
  const toast = useRef<any>(null);
  const ref = React.useRef<any>();
  var _localStorage = JSON.parse(localStorage.getItem("userData") || "");
  const _sharepointSiteURL = _localStorage.SharepointSiteURL;
  let _tinyURL = _localStorage.TinyURL;
  const [requestFile, setRequestFile] = useState<any>(null);

  const [deleteVisible, setDeleteVisible] = useState(false);
  const [value, setValue] = useState("");
  const [visibleStatus, setVisibleStatus] = useState(false);

  const [fileData, setFileData] = useState<any>();
  const [pathData, setPathData] = useState<any>();
  const [description, setDescription] = useState("");

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [indexState, setIndexState] = useState<number>(0);
  const [descriptionRespone, setDescriptionRespone] = useState<string>();
  const [onLoading, setOnLoading] = useState<boolean>(false);
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [configFile, setConfigFile] = useState<any>({});
  const { masterSetting } = useMasterSetting();
  const editPdf = masterSetting?.PdfAnnotate?.Value1 ? masterSetting?.PdfAnnotate?.Value1 : "false";

  useEffect(() => {
    fechData();

    const defaultSeq = listFileAttachDetails.map((e: any) =>
      listFileAttachDetails.length > 0
        ? { name: e.attach_file, seq: e.sequence }
        : { name: "", seq: 0 }
    );
    const filterArr = defaultSeq.filter((e: any) => e.seq !== 0);
    setSelectedRows(filterArr);
  }, []);

  async function fechData() {
    const config = await getUploadFileSettingConfiguration();

    setConfigFile(config);
  }
  const formatDateTime = (value: string) => {
    if (value == null) {
      return "";
    } else {
      let momentObj = moment(value, "DD/MM/yyyy HH:mm:ss");
      return moment(momentObj).format("DD MMM yyyy");
    }
  };
  const convertDate = (data: any) => {
    return formatDateTime(data);
  };

  const onError = () => {
    toggleAlert({
      description: `Server Error Please try again.`,
      message: `Server Error`,
      type: "error",
    });
  };
  useEffect(() => {
    console.log(masterATDLFT, "masterATDLFT");
  }, []);

  const onFileSelect = async (e: any) => {
    var filename = e.target.value;
    var userName = filename.split("\\").pop();
    const files = e.target.files;
    const maxLength = parseInt(configFile.limitFileSize);
    const maxMb = 20971520 / 1024 / 1024;
    for (let i = 0; i < files.length; i++) {
      if (files[i].size >= maxLength) {
        toggleAlert({
          description: `Can not upload file size more than ${maxMb}.MB File name is ${files[i].name}`,
          message: `File size warning.`,
          type: "warning",
        });
        ref.current.value = null;
        return;
      }
    }

    setRequestFile(e.target.files);
    setFileData(userName);
    setPathData(filename);
  };

  function deleteFile() {
    setListFileAttachDetails(
      listFileAttachDetails.filter((_val: any, _idx: number) => {
        return _idx !== indexState;
      })
    );
    setSelectedRows(
      selectedRows.filter((e: any, idx: any) => {
        return idx !== indexState;
      })
    );
  }

  async function onUploadFile() {
    if (requestFile !== null) {
      setOnLoading(true);
      for (let i = 0; i < requestFile.length; i++) {
        const attachWorkList = listFileAttachDetails;
        const lastFile = attachWorkList[attachWorkList.length - 1];
        const NewDate = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
        let newFile = Object.assign({}, lastFile);
        const formData = new FormData();
        formData.append(`files`, requestFile[i]);
        formData.append("Doclib", "TempAttachment");
        formData.append("docSet", memoDetail.template_detail);
        formData.append("fileDesc", description);
        formData.append("actorID", userData.EmployeeId);

        var response: any = await UploadFileAttachFiles(formData);
        if (response.data?.result === true) {
          if (_sharepointSiteURL) {
            newFile.attach_path =
              _sharepointSiteURL + "" + response.data.pathUrl;
          } else {
            newFile.attach_path = response.data.pathUrl;
          }
          newFile.sequence = 0;
          newFile.memo_id = memoDetail.memoid;
          newFile.actor = userData;
          newFile.attach_date = NewDate;
          newFile.attach_file = response.data.fileName;
          newFile.is_merge_pdf = false;
          newFile.modified_date = NewDate;
          newFile.modified_by = userData.EmployeeId.toString();
          newFile.description = description;
          attachWorkList.push(newFile);
          setListFileAttachDetails([...attachWorkList]);
        } else {
          onError();
        }
      }
      setOnLoading(false);
      setRequestFile(null);
      setValue("");
      setDescription("");
      ref.current.value = null;
    } else {
      toggleAlert({
        description: `Please Select File.`,
        message: `File select warning.`,
        type: "warning",
      });
    }
  }
  const footer = (
    <div>
      <Button
        label="OK"
        onClick={() => {
          setVisibleDialog(false);
        }}
      />
    </div>
  );

  function renderDialog() {
    return (
      <>
        <Dialog
          style={{ width: "29vw", fontSize: "15px" }}
          header="Information"
          visible={visibleDialog}
          modal
          footer={footer}
          draggable={false}
          position={"top"}
          onHide={() => {
            setVisibleDialog(false);
          }}
        >
          <label className="set-css-content-attachment">
            {configFile.limitFileInfo}
          </label>
        </Dialog>
      </>
    );
  }

  const handleCheckboxChange = (event: any, name: any) => {
    if (event.target.checked) {
      let _selectedRows = selectedRows;
      _selectedRows.push({
        name: name,
        seq: _selectedRows.length + 1,
      });
      setSelectedRows([..._selectedRows]);
    } else {
      let _SecRow = selectedRows
        .filter((e) => e.name !== name)
        .map((e: any, idx: number) => {
          let mergeObj: { name: string; seq: number } = {
            name: e.name,
            seq: idx + 1,
          };

          return mergeObj;
        });

      listFileAttachDetails.map((e: any) => {
        e.sequence = 0;
      });
      setSelectedRows(_SecRow);
    }
  };

  useEffect(() => {
    selectedRows.map((item: any) => {
      listFileAttachDetails.map((e: any) => {
        if (e.attach_file === item.name) {
          e.sequence = item.seq;
        }
      });
    });
  }, [selectedRows, listFileAttachDetails]);
  const browser = browserDetector();
  const openWindowAttachment = async (attachData: {
    attach_path: string;
    attach_file: string;
  }) => {
    const getFile = attachData.attach_file.split(".").pop();
    let target = "";
    if (
      (getFile === "pdf" ||
        getFile === "jpg" ||
        getFile === "JPEG" ||
        getFile === "png" ||
        getFile === "gif") && browser !== Device.safari
    ) {
      target = "_blank";
    } else {
      target = "_self";
    }

    const _port = location.port;
    if (location.host !== "localhost:5001") {
      if (_port) {
        _tinyURL = _tinyURL = _localStorage.TinyURL + ":" + _port;
      }
    }
    _tinyURL = location.host;
    var link = "";
    const protocol = window.location.protocol;
    const attachPath = attachData.attach_path
      ? attachData.attach_path.charAt(0) !== "/"
        ? "/" + attachData.attach_path
        : attachData.attach_path
      : "";
    const splitPath = attachData.attach_path.split("/") || [];
    console.log(_sharepointSiteURL, "att=>");
    const cannotDownloadPdfAppSetting =
      await CannotDowLoadPDFDefaultConfiguration();
    if (!_sharepointSiteURL) {
      const rootPath = splitPath[1] || "";
      const docPath = splitPath[2] || "";
      const fullName = splitPath[3] || "";
      const lastIndex = fullName.lastIndexOf(".");
      let extension = fullName.substring(lastIndex + 1);
      const name = fullName.substring(0, lastIndex);
      link = `${protocol}//` + _tinyURL + attachPath;

      let tabWithoutSharedPoint: Window | null;

      const checkCanDownload = listFormNames?.RefDocDisplay?.split(",");
      const checkCanDownloadAttach =
        checkCanDownload?.length >= 5 ? checkCanDownload[5] : "";
      const response = await GetPermissionByEmpId({
        EmployeeId: userData.EmployeeId,
      });
      let checkRoleId = false;
      for (let i = 0; i < response.length; i++) {
        const element = response[i];
        if (element?.roleId === 1) {
          checkRoleId = true;
          break;
        } else {
          checkRoleId = false;
        }
      }
      let checkDLAttach = false;
      if (masterATDLFT) {
        const downloadFileType = masterATDLFT.Value1.split(",");
        if (downloadFileType?.length > 0) {
          downloadFileType.map((fileType: any) => {
            if (link.toLowerCase().endsWith(fileType)) {
              checkDLAttach = true;
            }
          });
        }
      }
      if (checkRoleId) {
        tabWithoutSharedPoint = window.open(link, target);
        if (tabWithoutSharedPoint) {
          tabWithoutSharedPoint.location.href = link;
        }

        // window.open(link, "_blank", "noreferrer");
      } else if (checkDLAttach) {
        tabWithoutSharedPoint = window.open(link, target);
        if (tabWithoutSharedPoint) {
          tabWithoutSharedPoint.location.href = link;
        }

        // window.open(link, "_blank", "noreferrer");
      } else if (memoDetail?.Permission?.AttachDownload === "F") {
        tabWithoutSharedPoint = window.open(link, target);
        if (tabWithoutSharedPoint) {
          tabWithoutSharedPoint.location.href = `${protocol}//${_tinyURL}/previewAttachment?rootPath=${rootPath}&docPath=${docPath}&ext=${extension}&name=${name}&tab=attachment`;
        }
        // window.open(
        //   `${protocol}//${_tinyURL}/previewAttachment?rootPath=${rootPath}&docPath=${docPath}&ext=${extension}&name=${name}`,
        //   "_blank",
        //   "noreferrer"
        // );
      } else if (
        checkCanDownloadAttach?.toLowerCase() === "yes" &&
        checkActionPage === "add"
      ) {
        tabWithoutSharedPoint = window.open(link, target);
        if (tabWithoutSharedPoint) {
          tabWithoutSharedPoint.location.href = link;
        }

        // window.open(link, "_blank", "noreferrer");
      } else if (cannotDownloadPdfAppSetting?.cannotDowLoadPDFDefault) {
        tabWithoutSharedPoint = window.open(link, target);
        if (tabWithoutSharedPoint) {
          tabWithoutSharedPoint.location.href = `${protocol}//${_tinyURL}/previewAttachment?rootPath=${rootPath}&docPath=${docPath}&ext=${extension}&name=${name}&tab=attachment`;
        }

        // window.open(
        //   `${protocol}//${_tinyURL}/previewAttachment?rootPath=${rootPath}&docPath=${docPath}&ext=${extension}&name=${name}`,
        //   "_blank",
        //   "noreferrer"
        // );
      } else {
        tabWithoutSharedPoint = window.open(link, target);
        if (tabWithoutSharedPoint) {
          tabWithoutSharedPoint.location.href = link;
        }
        // window.open(link, "_blank", "noreferrer");
      }
    }
    //_sharepointSiteURL
    else {
      // ปัจจุบันตอนนี้ไม่มี Sharepoint (25/ต.ค./2024)
      let tab: any = window.open("about:blank", "_blank"); // ก่อนหน้านี้ตัวแปรใช้ในการเปิดแท็บใหม่ทั้งมีหรือไม่มี Sharepoint แยกออกจากกันให้สะดวกกับการแก้ไข
      const rootPath = splitPath[5] || "";
      const docPath = splitPath[6] || "";
      const fullName = splitPath[7] || "";
      const lastIndex = fullName.lastIndexOf(".");
      let extension = fullName.substring(lastIndex + 1);
      const name = fullName.substring(0, lastIndex);
      link = attachPath;

      link =
        attachPath.charAt(0) === "/" ? attachPath.substring(1) : attachPath;
      const checkCanDownload = listFormNames?.RefDocDisplay?.split(",");
      const checkCanDownloadAttach =
        checkCanDownload?.length >= 5 ? checkCanDownload[5] : "";
      let checkDLAttach = false;
      if (masterATDLFT) {
        const downloadFileType = masterATDLFT.Value1.split(",");
        if (downloadFileType?.length > 0) {
          downloadFileType.map((fileType: any) => {
            if (link.toLowerCase().endsWith(fileType)) {
              checkDLAttach = true;
            }
          });
        }
      }

      const response = await GetPermissionByEmpId({
        EmployeeId: userData.EmployeeId,
      });
      let checkRoleId = false;
      for (let i = 0; i < response.length; i++) {
        const element = response[i];
        if (element?.roleId === 1) {
          checkRoleId = true;
          break;
        } else {
          checkRoleId = false;
        }
      }

      if (checkRoleId) {
        tab.location.href = link;
        // window.open(link, "_blank", "noreferrer");
      } else if (checkDLAttach) {
        tab.location.href = link;
        // window.open(link, "_blank", "noreferrer");
      } else if (memoDetail?.Permission?.AttachDownload === "F") {
        tab.location.href = `${protocol}//${_tinyURL}/previewAttachment?rootPath=${rootPath}&docPath=${docPath}&ext=${extension}&name=${name}&tab=attachment`;
        // window.open(
        //   `${protocol}//${_tinyURL}/previewAttachment?rootPath=${rootPath}&docPath=${docPath}&ext=${extension}&name=${name}`,
        //   "_blank",
        //   "noreferrer"
        // );
      } else if (
        checkCanDownloadAttach?.toLowerCase() === "yes" &&
        checkActionPage === "add"
      ) {
        tab.location.href = link;
        // window.open(link, "_blank", "noreferrer");
      } else if (cannotDownloadPdfAppSetting?.cannotDowLoadPDFDefault) {
        tab.location.href = `${protocol}//${_tinyURL}/previewAttachment?rootPath=${rootPath}&docPath=${docPath}&ext=${extension}&name=${name}&tab=attachment`;
        // window.open(
        //   `${protocol}//${_tinyURL}/previewAttachment?rootPath=${rootPath}&docPath=${docPath}&ext=${extension}&name=${name}`,
        //   "_blank",
        //   "noreferrer"
        // );
      } else {
        tab.location.href = link;
        // window.open(link, "_blank", "noreferrer");
      }
    }
  };

  const checkFileExistInDb = (attachData: any) => {
    const fileInTable = attachData
    const fileInDb = fileAttact
    // ถ้าไฟล์มีใน TRNAttachFile (Table ในฐานข้อมูล) ให้ไปหน้าแก้เอกสารไม่มีไปหน้าดูเอกสาร
    for (let i = 0; i < fileInDb.length; i++) {
      const file = fileInDb[i];
      if (file.attach_file === fileInTable.attach_file) {
        history.push({
          pathname: "/EditPdf",
          state: {
            data: fileInTable?.attach_path,
            // data: samplePDF,
            memo: memoDetail,
            fileName: fileInTable?.attach_file,
          },
        });
        return;
      }
    }
    openWindowAttachment(fileInTable);
  }

  return (
    <div>
      {renderDialog()}
      <Toast ref={toast}></Toast>
      {onLoading && (
        <div className="logo-loading cursor-loading">
          <img src={LogoLoading} alt="loading..." />
        </div>
      )}
      <div>
        <p className="Col-text-header">{t("attachment")}</p>
        <div className="Attach">
          <>
            <div className="">
              <span className="p-d-block p-mt-2">{t("Attach File")} : </span>
              <div className="p-inputgroup set-layout-att-input">
                <Form.Control
                  ref={ref}
                  id="attach-file"
                  type="file"
                  accept="*"
                  onChange={(e) => onFileSelect(e)}
                  multiple
                  className="attachForm-border-fttach-file"
                  placeholder="fdfsd"
                />
                <IoMdAlert
                  onClick={() => {
                    setVisibleDialog(true);
                  }}
                  className="set-css-icon-alert-in-upload-file"
                />
              </div>
            </div>
            <div className="setting-display">
              <span className="p-d-block">{t("Description")} : </span>
              <div className="p-inputgroup">
                <InputText
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                    setDescription(e.target.value);
                  }}
                  className="p-d-block p-inputgroup attachForm-border-description-inputText"
                />
                <Button
                  id="upload"
                  className="button-upload attachForm-border-description-button"
                  onClick={onUploadFile}
                >
                  <BiUpload />
                  {t("Upload")}
                </Button>
              </div>
            </div>
          </>
        </div>
      </div>

      <div>
        <div>
          <DataTable
            value={listFileAttachDetails ?? []}
            onRowReorder={(e) => {
              setListFileAttachDetails(e.value);
            }}
            stripedRows
          >
            <Column rowReorder style={{ width: "3rem" }} />
            <Column
              header={<div style={{ display: "flex" }}>#</div>}
              headerClassName="attach"
              body={(lineData, options) => options.rowIndex + 1}
            />
            <Column
              header={<div style={{ display: "flex" }}>Date</div>}
              headerClassName="attach"
              field={"attach_date"}
              body={(rowData, options) => (
                <div>{convertDate(rowData.attach_date)}</div>
              )}
            />
            <Column
              header={<div style={{ display: "flex" }}>Attached File</div>}
              headerClassName="attach"
              field={"attach_file"}
              body={(attachData, options) => (
                <div>
                  <div
                    className="set-pointer-css-attachment"
                    onClick={() => {
                      const fileType = attachData.attach_file.split(".").pop()
                      if (memoDetail.memoid !== 0) {
                        if (fileType === "pdf" && editPdf === "true") {
                          checkFileExistInDb(attachData)
                        } else {
                          openWindowAttachment(attachData);
                        }
                      } else {
                        openWindowAttachment(attachData);
                      }
                    }}
                    style={{ color: "#2769b2", margin: "0px" }}
                  >
                    <div>{attachData.attach_file}</div>
                  </div>
                </div>
              )}
            />
            <Column
              header={<div style={{ display: "flex" }}>Description</div>}
              headerClassName="attach"
              field={"description"}
              body={(rowData, options) => <div>{rowData?.description ? rowData?.description : "-"}</div>}
            />
            <Column
              header={<div style={{ display: "flex" }}>Operator</div>}
              headerClassName="attach"
              field={
                userData.Lang === "EN" ? "approver.NameEn" : "approver.NameTh"
              }
              style={{ justifyItems: "center" }}
              body={(rowData, options) => {
                return (
                  <div>
                    {userData.Lang === "EN"
                      ? rowData.actor.NameEn
                      : rowData.actor.NameTh}
                  </div>
                );
              }}
            />
            <Column
              header={<div style={{ display: "flex" }}>Merge</div>}
              headerClassName="attach"
              style={{ justifyItems: "center" }}
              body={(attachData, options) => {
                const idx = options.rowIndex;
                return (
                  <div className="detail-mergeAtt">
                    {attachData?.attach_file.toLowerCase().endsWith("pdf") ? (
                      <div className="set-checkbox">
                        <Badge
                          count={
                            attachData.sequence
                              ? attachData.sequence
                              : selectedRows.find(
                                (e) => e.name === attachData.attach_file
                              )?.seq
                          }
                          size="small"
                        >
                          <Checkbox
                            id={idx.toString()}
                            onChange={(e) => {
                              debugger;
                              let _listAttach = listFileAttachDetails;
                              _listAttach[idx].is_merge_pdf = e.checked;
                              _listAttach[idx].modified_date = moment(
                                new Date()
                              ).format("DD/MM/YYYY HH:mm:ss");
                              setListFileAttachDetails([..._listAttach]);
                              handleCheckboxChange(e, attachData.attach_file);
                            }}
                            checked={listFileAttachDetails[idx].is_merge_pdf}
                          ></Checkbox>
                        </Badge>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }}
            />
            <Column
              header={<div style={{ display: "flex" }}>Action</div>}
              headerClassName="attach ActionAtt"
              align={"center"}
              style={{ justifyItems: "center" }}
              body={(attachData, options) => {
                const idx = options.rowIndex;
                return (
                  <div className="detail-ActionAtt">
                    <SplitButton
                      model={[
                        {
                          label: "Edit Description",
                          icon: "pi pi-pencil",
                          command: () => {
                            setIndexState(idx);
                            setDescriptionRespone(attachData.description);
                            setVisibleStatus(true);
                          },
                        },
                        {
                          label: "Delete",
                          icon: "pi pi-trash",
                          command: () => {
                            setIndexState(idx);
                            setDeleteVisible(true);
                          },
                        },
                      ]}
                      dropdownIcon="pi pi-ellipsis-v"
                    />
                  </div>
                );
              }}
            />
          </DataTable>
        </div>
      </div>
      <ConfirmDialog
        visible={deleteVisible}
        onHide={() => setDeleteVisible(false)}
        message="Are you sure to Delete this File?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        accept={deleteFile}
        reject={() => setDeleteVisible(false)}
      />
      {visibleStatus == true && (
        <DescriptionConfigComponent
          visibleStatus={visibleStatus}
          setVisibleStatus={setVisibleStatus}
          Index={indexState}
          description={descriptionRespone}
          listFileAttachDetails={listFileAttachDetails}
          setListFileAttachDetails={setListFileAttachDetails}
        />
      )}
    </div>
  );
};

export default AttachmentComponent;
