import { Row, Col, Table, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { Fragment, useEffect, useState } from "react";
import { PlusOutlined, GlobalOutlined, CheckOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./style.css";

enum PublishStatus {
  NONE = -1,
  BLANK = 0,
  WAIT_FOR_APPROVE = 1,
  PUBLISH_ON_WEB = 2,
  SKIP = 99,
}
enum TrackStatus {
  NONE = -1,
  BLANK = 0,
  WAIT_FOR_APPROVE = 1,
  COMPLETED = 2,
}

export const ProcurementTracking = () => {
  function renderPublish(value: any = { status: -1 }) {
    let display = <></>;
    switch (value.status) {
      case PublishStatus.BLANK:
        display = (
          <span className="contain-plus-button">
            <Button icon={<PlusOutlined rev />} />
          </span>
        );
        break;
      case PublishStatus.WAIT_FOR_APPROVE:
        display = <>Wait for Approve</>;
        break;
      case PublishStatus.PUBLISH_ON_WEB:
        display = (
          <>
            <GlobalOutlined rev/>
            <span style={{ verticalAlign: "-0.125em" }}>
              &nbsp;Published on Web
            </span>
          </>
        );
        break;
      case PublishStatus.SKIP:
        display = <>-</>;
        break;
    }
    display = value.link ? (
      <Link target="_blank" to={value.link}>
        {display}
      </Link>
    ) : (
      display
    );
    return display;
  }
  function renderTrack(value: any = { status: -1 }) {
    let display = <></>;
    switch (value.status) {
      case TrackStatus.BLANK:
        display = (
          <span className="contain-plus-button">
            <Button icon={<PlusOutlined rev/>} />
          </span>
        );
        break;
      case TrackStatus.WAIT_FOR_APPROVE:
        display = <>Wait for Approve</>;
        break;
      case TrackStatus.COMPLETED:
        display = (
          <>
            <CheckOutlined rev/>
            <span style={{ verticalAlign: "-0.125em" }}>
              <span>
                <>&nbsp;</>
                {!value.value ? <>Completed</> : value.value}
              </span>
              {value.dateFrom && (
                <>
                  <br />
                  <span style={{ fontSize: "x-small" }}>{value.dateFrom}</span>
                  {value.dateTo && (
                    <>
                      {" - "}
                      {
                        <span style={{ fontSize: "x-small" }}>
                          {value.dateTo}
                        </span>
                      }
                    </>
                  )}
                </>
              )}
            </span>
          </>
        );
        break;
    }
    display = value.link ? (
      <Link target="_blank" to={value?.link}>
        {display}
      </Link>
    ) : (
      display
    );
    return display;
  }
  const columns: ColumnsType<any> = [
    {
      title: <b>TOR</b>,
      key: "groupTOR",
      children: [
        {
          title: <b>TOR</b>,
          dataIndex: "tor",
          key: "tor",
          width: "12.5%",
          render: (value) =>
            value?.link ? (
              <Link target="_blank" to={value.link}>
                {value.value}
              </Link>
            ) : (
              <>{value.value}</>
            ),
        },
        {
          title: <b>Standard Price</b>,
          dataIndex: "stdPrice",
          key: "stdPrice",
          width: "12.5%",
          render: (value) => renderPublish(value),
        },
        {
          title: <b>PR</b>,
          dataIndex: "pr",
          key: "pr",
          width: "12.5%",
          render: (value) => renderTrack(value),
        },
      ],
    },
    {
      title: <b>Procurement Process</b>,
      key: "pProcess",
      children: [
        {
          title: <b>Announce (RFP)</b>,
          dataIndex: "rfp",
          key: "rfp",
          render: (value) => renderPublish(value),
          width: "12.5%",
        },
        {
          title: <b>Compare & Negotiation</b>,
          dataIndex: "compare",
          key: "compare",
          width: "12.5%",
          render: (value) => renderTrack(value),
        },
        {
          title: <b>PO</b>,
          dataIndex: "po",
          key: "po",
          width: "12.5%",
          render: (value) => renderTrack(value),
        },
        {
          title: <b>Award</b>,
          dataIndex: "award",
          key: "award",
          width: "12.5%",
          render: (value) => renderTrack(value),
        },
        {
          title: <b>E-GP</b>,
          dataIndex: "eGP",
          key: "eGP",
          width: "12.5%",
          render: (value) => renderTrack(value),
        },
      ],
    },
  ];

  const retrieveFn =
    typeof (window as any).retreiveProcurement == "function"
      ? (window as any).retreiveProcurement
      : () =>
          Promise.resolve([
            {
              tor: {
                value: "PR-23-000183",
                link: "/Request?MemoID=174",
              },
              stdPrice: {
                status: PublishStatus.PUBLISH_ON_WEB,
                link: "/Request?MemoID=176",
              },
              pr: {
                status: TrackStatus.COMPLETED,
                link: "/Request?MemoID=187",
              },
              rfp: {
                status: PublishStatus.PUBLISH_ON_WEB,
              },
              compare: {
                status: TrackStatus.COMPLETED,
                link: "/Request?MemoID=185",
                value: "CN-01-2023-000001",
                dateFrom: "1 Aug 23",
                dateTo: "15 Aug 23",
              },
              po: {
                status: TrackStatus.COMPLETED,
                link: "/Request?MemoID=178",
                value: "PO-01-2023-000001",
                dateFrom: "15 Aug 23",
                dateTo: "16 Aug 23",
              },
              award: {
                status: TrackStatus.COMPLETED,
                link: "/Request?MemoID=188",
                value: "AW-01-2023-000001",
                dateFrom: "17 Aug 23",
                dateTo: "20 Aug 23",
              },
              eGP: {
                status: TrackStatus.COMPLETED,
              },
            },
            {
              tor: {
                value: "PR-23-000184",
              },
              stdPrice: {
                status: PublishStatus.PUBLISH_ON_WEB,
              },
              pr: {
                status: TrackStatus.COMPLETED,
              },
              rfp: {
                status: PublishStatus.PUBLISH_ON_WEB,
              },
              compare: {
                status: PublishStatus.BLANK,
                link: "/Request?MemoID=0&template=132",
              },
            },
            {
              tor: {
                value: "PR-23-000185",
              },
              stdPrice: {
                status: PublishStatus.SKIP,
              },
              pr: {
                status: TrackStatus.WAIT_FOR_APPROVE,
              },
            },
            {
              tor: {
                value: "PR-23-000186",
              },
              stdPrice: {
                status: PublishStatus.BLANK,
                link: "/Request?MemoID=0&template=113",
              },
              pr: {
                status: PublishStatus.BLANK,
                link: "/Request?MemoID=0&template=119",
              },
            },
          ]);
  const [data, dataSet] = useState<any>([]);
  useEffect(() => {
    retrieveFn().then((d: any[]) => {
      dataSet(d);
    });
  }, []);
  return (
    <Fragment>
      <div className="p2p">
        <Row>
          <Col>
            <h1>Procurement Tracking</h1>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: "1rem" }}>
            <Table columns={columns} dataSource={data} bordered />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
