import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { Link } from "react-router-dom";

export const ReportTable: React.FC<{ data: any[] }> = ({ data }) => {
  const columns: ColumnsType<Datum> = [
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      render: (value, record, index) => {
        let link = record.memoId ? (
          <Link
            className="text-form-running"
            target="_blank"
            to={`/Request?MemoID=${record.memoId}`}
          >
            {value}
          </Link>
        ) : (
          value
        );
        return record.level == 0 ? <b>{link}</b> : <>{link}</>;
      },
    },
    {
      title: "Method of payment",
      dataIndex: "methodOfPayment",
      key: "methodOfPayment",
    },
    {
      title: "Payment date",
      dataIndex: "paymentDate",
      key: "paymentDate",
    },
    {
      title: "Status",
      dataIndex: "statusName",
      key: "StatusName",
      align: "center",
      render: (value, record) => {
        return <>{value}</>;
      },
    },
    {
      title: "Net Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (value, record) => {
        return record.level == 0 ? (
          <b>{formatNumber(value)}</b>
        ) : (
          <>{formatNumber(value)}</>
        );
      },
    },
  ];
  function formatNumber(value: number | null): string {
    if (value === null) {
      return "-";
    }

    return value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      size="small"
      expandable={{
        expandRowByClick: true,
      }}
    />
  );
};
