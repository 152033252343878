export const truncateString = (str: string, no_words: number): string =>{
    if (!str || typeof str !== "string") return "";
    const words = str.split(" ");
    return words.length <= no_words ? str : words.splice(0, no_words).join(" ");
}
type map ={
  [key: string] :string
}
export const mapString = (
  value: string,
  mapping: { [key: string]: string },
  extraText?: string
) => {
  if(!value ) return ""
  const mappedValue = mapping[value] || '';
  return mappedValue + (extraText ?? '');
};
