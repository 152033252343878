import _ from "lodash";
export const findMaxInArrayObject = (arr: any[], key: string) => {
  const maxValue = arr.reduce((max, current) => {
    return current[key] > max[key] ? current : max;
  }, arr[0]);
  return maxValue;
};

export const uniqueArray = (data: any[]) => {
  return data.filter((value: any, index: any) => {
    const _value = JSON.stringify(value, Object.keys(value).sort());
    return (
      index ===
      data.findIndex((obj: any) => {
        return JSON.stringify(obj, Object.keys(obj).sort()) === _value;
      })
    );
  });
};

export function checkTheKeyMatch(
  arr1: any[],
  arr2: any[],
  key: string | number
) {
  // Extract MemoId from both arrays
  const arrItems1 = arr1?.map((item: { [x: string]: any }) => item[key]);
  const arrItems2 = arr2?.map((item: { [x: string]: any }) => item[key]);

  return _.isEqual(arrItems1, arrItems2);
}
