import { Button, DatePicker } from "antd";
import moment from "moment";
import React from "react";
import { FilterComponentProps } from "../DynamicTableV3";
const { RangePicker } = DatePicker;

const DataFilterComponent = ({
  colProp,
  options,
  dataSource,
  filtersState: filterState,
  allColumnProps,
}: FilterComponentProps) => {
  return (
    <div
      style={{
        padding: 5,
        gap: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <RangePicker
        value={options.value}
        ranges={{
          Today: [moment(), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "This Year": [moment().startOf("year"), moment().endOf("year")],
        }}
        format={"DD MMM YYYY"}
        onChange={(e) => {
          options.filterApplyCallback(e);
        }}
      />
    </div>
  );
};

export default DataFilterComponent;
