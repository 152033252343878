import React, { useEffect } from "react";
import { enumToObject } from "../../../../Helper/LogicHelper";
import { ILogicCondition } from "../../../../IRequestModel/ILogicModel";
import { Space, Form, Select, Input } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { LogicOperatorType } from "../../../../constants/logic-type";
import { useForm } from "antd/lib/form/Form";
import "./formLogic.css";

const { Option } = Select;

type Props = {
  key: number;
  name: number;
  remove: (index: number) => void;
  onRowChange: (rowData: { [name: number]: ILogicCondition }) => void;
  defaultValue: ILogicCondition;
};

const DataSourceConditionRow = ({
  key,
  name,
  remove,
  onRowChange,
  defaultValue,
}: Props) => {
  const [form] = useForm();
  const operators = enumToObject(LogicOperatorType);
  useEffect(() => {
    form.setFieldsValue({
      [name]: defaultValue
        ? defaultValue
        : {
            field: null,
            operator: "=",
            value: null,
            comparewith: null,
          },
    });
  }, []);

  const onValueChange = (changedValues: any, allValues: any) => {
    onRowChange(allValues);
  };

  return (
    <>
      <Form form={form} onValuesChange={onValueChange}>
        <Space
          className="form-logic-conditions"
          key={key}
          size={"middle"}
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          // }}
          align="start"
        >
          <Form.Item
            name={[name, "field"]}
            rules={[{ required: true, message: "Missing field" }]}
          >
            <Input placeholder="Field" style={{ minWidth: 200 }} />
          </Form.Item>

          <Form.Item name={[name, "action"]}>
            <Select
              placeholder="Action"
              style={{ minWidth: 200 }}
              showSearch
              options={[
                { label: "AND", value: "AND" },
                { label: "OR", value: "OR" },
              ]}
            />
          </Form.Item>
          <Form.Item
            name={[name, "condition"]}
            validateTrigger={["onChange", "onBlur"]}
            rules={[{ required: true, message: "Missing condition" }]}
          >
            <Select
              placeholder="Condition"
              style={{ minWidth: 200 }}
              showSearch
              allowClear
            >
              {Object.entries(operators)
                .filter(
                  ([value, label], index) =>
                    value !== "contain" &&
                    value !== "startwith" &&
                    value !== "endwith"
                )
                .map(([value, label], index) => (
                  <Option key={index} value={value}>
                    {label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={[name, "value"]}
            validateTrigger={["onChange", "onBlur"]}
          >
            <Input placeholder="Value" />
          </Form.Item>

          <MinusCircleOutlined onClick={() => remove(name)} rev={undefined} />
        </Space>
      </Form>
    </>
  );
};

export default DataSourceConditionRow;
