import React from "react";
import { AiFillAndroid, AiFillApple } from "react-icons/ai";
import { FaLine } from "react-icons/fa";
import { IoDesktopOutline } from "react-icons/io5";

enum platform {
  web = "web",
  ios = "ios",
  android = "android",
  line = "line",
}
export const formatPlatform = (value: platform) => {
  return (
    <>
      <p className="text-send-from">
        {value === "web" && (
          <>
            <IoDesktopOutline /> เว็ปไซต์ WOLF Approve | Web{" "}
          </>
        )}
        {value === "ios" && (
          <>
            <AiFillApple /> แอพพลิเคชั่น WOLF Approve | IOS{" "}
          </>
        )}
        {value === "android" && (
          <>
            <AiFillAndroid /> แอพพลิเคชั่น WOLF Approve | Andriod{" "}
          </>
        )}
        {value === "line" && (
          <>
            <FaLine /> แอพพลิเคชั่น WOLF Approve | Line{" "}
          </>
        )}
      </p>
    </>
  );
};
