import React, { useEffect, useState } from "react";
import { Col, Divider, Form, FormInstance, Input, Row, Table } from "antd";
import { ITemplateForm } from "../../../../IRequestModel/CreateFormControl/ITemplateForm";
import { IUserModel } from "../../../../IRequestModel/IUserModel";
import { SelectDataDialog } from "../../../../components/Select/SelectionDataDialog/SelectDataDialog";
import { GetAllEmployee } from "../../../../Services/EmployeeService";
import { useUserContext } from "../../../../Context/UserContext";
import {
  GetTemplateControlById,
  GetTemplateFromDDL,
} from "../../../../Services/TemplateService";
import { IListApprovalDetailsModel } from "../../../../IRequestModel/IListApprovalDetailsModel";
import { ITemplateRequestModel } from "../../../../IRequestModel/CreateFormControl/ITemplateRequestModel";
import { GetAllPosition } from "../../../../Services/PositionService";
import { GetAllDynamic } from "../../../../Services/DynamicService";
import { IPositionModel } from "../../../../IRequestModel/IPositionModel";
import { TextHeaderComponents } from "../../../../components/TextHeaderComponents/TextHeaderComponents";
import { onLoadDataLiveApprove } from "../../../../Services/LineApprovalsService";
import { IIntitailData } from "../SimLineApproveScreen";

const { Search } = Input;
interface Props {
  form: FormInstance<IIntitailData>;
  setLineapprovals: (lineapprovals: IListApprovalDetailsModel[]) => void;
}

export const InitialComponent = ({ form, setLineapprovals }: Props) => {
  const [userData] = useUserContext();
  const [openEmpSelector, setOpenEmpSelector] = useState<boolean>(false);
  const [openTemSelector, setOpenTemSelector] = useState<boolean>(false);
  const [employeeList, setEmployeeList] = useState<IUserModel[]>([]);
  const [positionList, setPositionList] = useState<IPositionModel[]>([]);
  const [matrixList, setMatrixList] = useState<any>([]);
  const [templateList, setTemplateList] = useState<ITemplateForm[]>([]);
  const [allMatrix, setAllMatrix] = useState<any[]>([]);
  const [loadTemplate, setLoadTemplate] = useState(false);
  const [allCondition, setAllCondition] = useState<string[]>([]);
  const [allLogic, setAllLogic] = useState<any[]>([]);
  const [templateData, setTemplateData] = useState<any>();
  const [requestorData, setRequestorData] = useState<any>();

  useEffect(() => {
    fecthEmployeeData();
    fecthPositionData();
    fetchMatrix();
    fecthTemplate();
  }, []);

  const fecthEmployeeData = async () => {
    const res = await GetAllEmployee();
    setEmployeeList([...res]);
  };

  const fecthPositionData = async () => {
    const res = await GetAllPosition();
    setPositionList([...res]);
  };

  async function fetchMatrix() {
    let matrix = await GetAllDynamic("ApprovalMatrix/GetAll", undefined);
    setMatrixList(matrix);
  }

  const fecthTemplate = async () => {
    const dataJson = {
      Username: userData.Username,
      DepartmentId: Number(userData.DepartmentId),
      EmployeeId: Number(userData.EmployeeId),
      Email: userData.Email,
      selectAll: false,
      DefultMode: false,
      OnlyActive: true,
    };

    let _dataTemplate = await GetTemplateFromDDL(dataJson);
    setTemplateList([..._dataTemplate]);
  };

  const onSelectTemplate = async (e: ITemplateForm) => {
    let _data = form.getFieldsValue();
    setOpenTemSelector(false);
    setLoadTemplate(true);

    const res = await GetTemplateControlById({
      TemplateId: e.TemplateId,
    });
    _data.template = res.templateForm;
    setTemplateData(res.templateForm);
    setAllLogic([...res.cMSTTemplateLogic]);
    const conditionObject = initApprovalMatrix(res);
    _data.control = conditionObject;

    form.setFieldsValue(_data);
    callLineApprove();
    setLoadTemplate(false);
  };

  const initApprovalMatrix = (templateForm: ITemplateRequestModel) => {
    try {
      let temp: any[] = templateForm.TemLineApprove;

      let spcApprove: any = templateForm.specificApprovers;

      let approvals: any = [];

      let conditionObject: any = {};

      let conditionControl: string[] = [];

      temp.forEach((tem: any, idx: number) => {
        let _con: any;
        let _ColumnJson = "";
        if (tem.Conditions && tem.Conditions !== "") {
          _con = JSON.parse(tem.Conditions);
          conditionControl = [
            ...conditionControl,
            ..._con.Conditions.map((con: any) => con.field),
          ];
        }
        if (tem.ColumnJsonCondValue) {
          if (tem.ColumnJsonCondValue !== "") {
            _ColumnJson = JSON.parse(tem.ColumnJsonCondValue);
          }
        }

        if (tem.ApproveType === 20) {
          let spcApproves: any = [];
          spcApprove.map((e: any) => {
            if (tem.Seq - 1 === e.TemLineId) {
              spcApproves.push(e);
            } else if (tem.TemLineId === e.TemLineId) {
              spcApproves.push(e);
            }
          });
          const approval: any = {
            IsParallel: tem.IsParallel,
            IsApproveAll: tem.IsApproveAll,
            ApproveSlot: tem.ApproveSlot,
            TemLineId: tem.TemLineId,
            Seq: tem.Seq,
            Specific_Approver: spcApproves,
            ApproveType: tem.ApproveType,
            Conditions: _con,
            ColumnJsonCondValue: _ColumnJson,
            Type: "Specific Approver",
          };
          approvals.push(approval);
        } else if (tem.ApproveType === 21) {
          let type = GetApproveType(tem.ApproveType);
          let maxLevelName = GetMatrixData(tem.ApprovalMatrixId);
          let approval: any = {
            TemLineId: tem.TemLineId,
            Seq: tem.Seq,
            MaxLevelId: tem.ApprovalMatrixId,
            MaxLevelName: maxLevelName,
            ApproveType: tem.ApproveType,
            Conditions: _con,
            ColumnJsonCondValue: _ColumnJson,
            Type: type,
          };
          approvals.push(approval);
        } else {
          let type = GetApproveType(tem.ApproveType);
          let maxLevelName = GetMaxLevelName(tem.MaxLevelId);
          let approval: any = {
            TemLineId: tem.TemLineId,
            Seq: tem.Seq,
            MaxLevelId: tem.MaxLevelId,
            MaxLevelName: maxLevelName,
            ApproveType: tem.ApproveType,
            Conditions: _con,
            ColumnJsonCondValue: _ColumnJson,
            Type: type,
          };
          approvals.push(approval);
        }
      });

      Array.from(new Set(conditionControl)).map(
        (e) => (conditionObject[e] = null)
      );
      setAllCondition([...Array.from(new Set(conditionControl))]);
      setAllMatrix([...approvals]);
      return conditionObject;
    } catch (error) {
      console.error("Matrix=>", error);
    }
  };

  function GetApproveType(ApproveTypeId: number) {
    const approveType = [
      { id: 19, name: "Line of Command" },
      { id: 20, name: "Specific Approver" },
      { id: 21, name: "Design of Authority" },
      { id: 22, name: "Direct to Position" },
      { id: 0, name: "Default" },
    ];
    let name: string = "";
    approveType.map((e: any) => {
      if (e.id === ApproveTypeId) {
        name = e.name;
      }
    });

    return name;
  }

  function GetMaxLevelName(maxLevelId: number) {
    let name: string = "";
    const positions = positionList;
    positions.map((e: any) => {
      if (e.PositionLevelId === maxLevelId) {
        if (userData.Lang === "EN") {
          name = e.NameEn;
        } else {
          name = e.NameTh;
        }
      }
    });
    return name;
  }

  function GetMatrixData(matrixId: number) {
    let name: string = "";
    const matrixes = matrixList;

    matrixes.map((e: any) => {
      if (e.ApproveMatrixId === matrixId) {
        if (userData.Lang === "EN") {
          name = e.NameEn;
        } else {
          name = e.NameTh;
        }
      }
    });
    return name;
  }

  const callLineApprove = async () => {
    const formData = form.getFieldsValue();
    let jsonValue;
    if (allLogic.length > 0) {
      jsonValue = {
        logicid: allLogic[0]?.logicid,
        conditions: formData.control
          ? Object.keys(formData.control).map((e) => {
              return { label: e, value: formData.control[e] };
            })
          : [],
      };
    }

    const dataJson = {
      employee: requestorData ?? userData,
      ComCode: 1,
      JsonCondition: jsonValue ? JSON.stringify(jsonValue) : undefined,
      templateForm: { template_id: formData.template.TemplateId },
      lstTRNLineApprove: [],
      Amount: 0,
    };
    const res = await onLoadDataLiveApprove(dataJson , userData?.EmployeeId);
    setLineapprovals([...res]);
  };

  return (
    <Form
      form={form}
      initialValues={{ requestor: userData }}
      onValuesChange={(changeValue, allValue) => {
        callLineApprove();
      }}
    >
      <Divider>Template</Divider>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="text-header-css-sim-line-component">
            {userData.Lang === "TH" ? "เลือกแบบฟอร์ม" : "Select Template"}
            <Form.Item name={["template", "TemplateName"]}>
              <Search
                className="set-input-component-css"
                placeholder={
                  userData.Lang === "TH" ? "เลือกแบบฟอร์ม" : "Select Template"
                }
                readOnly
                enterButton="Select"
                size="middle"
                onClick={() => setOpenTemSelector(true)}
                onSearch={() => setOpenTemSelector(true)}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="text-header-css-sim-line-component">
            Template ID
            <Form.Item name={["template", "TemplateId"]}>
              <Input className="set-input-component-css" disabled />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="text-header-css-sim-line-component">
            Total Amount
            <Form.Item name={["amount"]}>
              <Input className="set-input-component-css" />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Divider>Requestor</Divider>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="text-header-css-sim-line-component">
            {userData.Lang === "TH" ? "เลือกผู้ร้องขอ" : "Select requestor"}
            <Form.Item
              name={["requestor", userData.Lang === "TH" ? "NameTh" : "NameEn"]}
            >
              <Search
                className="set-input-component-css"
                placeholder={
                  userData.Lang === "TH" ? "เลือกผู้ร้องขอ" : "Select requestor"
                }
                readOnly
                enterButton="Select"
                size="middle"
                onClick={() => setOpenEmpSelector(true)}
                onSearch={() => setOpenEmpSelector(true)}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="text-header-css-sim-line-component">
            {userData.Lang === "TH" ? "อีเมล" : "Email"}
            <Form.Item name={["requestor", "Email"]}>
              <Input className="set-input-component-css" disabled />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="text-header-css-sim-line-component">
            {userData.Lang === "TH" ? "ตำแหน่งงาน" : "Position"}
            <Form.Item
              name={[
                "requestor",
                `Position${userData.Lang === "TH" ? "NameTh" : "NameEn"}`,
              ]}
            >
              <Input className="set-input-component-css" disabled />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Divider>Report To</Divider>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="text-header-css-sim-line-component">
            {userData.Lang === "TH" ? "เลือกผู้ร้องขอ" : "Select requestor"}
            <Form.Item
              name={["reportTo", userData.Lang === "TH" ? "NameTh" : "NameEn"]}
            >
              <Input className="set-input-component-css" disabled />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="text-header-css-sim-line-component">
            {userData.Lang === "TH" ? "อีเมล" : "Email"}
            <Form.Item name={["reportTo", "Email"]}>
              <Input className="set-input-component-css" disabled />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="text-header-css-sim-line-component">
            {userData.Lang === "TH" ? "ตำแหน่งงาน" : "Position"}
            <Form.Item
              name={[
                "reportTo",
                `Position${userData.Lang === "TH" ? "NameTh" : "NameEn"}`,
              ]}
            >
              <Input className="set-input-component-css" disabled />
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Divider>All Approvals</Divider>
      <Table
        loading={loadTemplate}
        dataSource={allMatrix}
        columns={[
          {
            title: (
              <TextHeaderComponents
                textHeaderProps={"Sequence"}
                textSubProps={"ลำดับ"}
              />
            ),
            render: (val, record, idx) => (idx += 1),
          },
          {
            title: (
              <TextHeaderComponents
                textHeaderProps={"Condition"}
                textSubProps={"เงื่อนไข"}
              />
            ),
            dataIndex: "Conditions",
            render: (value, record, index) => {
              try {
                let str = "";
                if (value !== null && value !== undefined && value !== "") {
                  value?.Conditions?.map((con: any, index: number) => {
                    if (index === 0) {
                      str = str + (con.field + ":" + con.value);
                    } else {
                      str = str + "," + (con.field + ":" + con.value);
                    }
                  });
                }
                return str;
              } catch (error) {}
            },
          },
          {
            title: (
              <TextHeaderComponents
                textHeaderProps={"Type of Approval"}
                textSubProps={"ประเภทการอนุมัติ"}
              />
            ),
            dataIndex: "Type",
          },
          {
            title: (
              <TextHeaderComponents
                textHeaderProps={
                  "Max Level / Approval Matrix / Specific Approver"
                }
                textSubProps={
                  "ลำดับการอนุมัติสูงสุด / ชื่อวงเงินอนุมัติเอกสาร / คนอนุมัติ"
                }
              />
            ),
            dataIndex: "MaxLevelName",
            render: (value, record, index) => {
              if (record["ApproveType"] === 20) {
                if (record["Specific_Approver"] !== undefined) {
                  let str = "";

                  record["Specific_Approver"].map(
                    (approver: any, index: number) => {
                      if (index === 0) {
                        str = str + approver.EmployeeName;
                      } else {
                        str = str + "," + approver.EmployeeName;
                      }
                    }
                  );
                  return str;
                }
              } else {
                return record.MaxLevelName;
              }
            },
          },
        ]}
      />
      <Divider>All Condition Control</Divider>
      <Row justify="center" align="stretch" gutter={[16, 16]}>
        {allCondition.map((e, idx) => {
          return (
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item name={["control", e]} label={e}>
                <Input className="set-input-component-css" />
              </Form.Item>
            </Col>
          );
        })}
      </Row>
      <SelectDataDialog
        dataList={employeeList}
        dialogKey={"Employee"}
        columns={[
          {
            field: userData.Lang === "TH" ? "NameTh" : "NameEn",
            headerTh: "ชื่อ",
            headerEn: "Name",
          },
          {
            field: "Email",
            headerTh: "อีเมล",
            headerEn: "Email",
          },
          {
            field: `Position${userData.Lang === "TH" ? "NameTh" : "NameEn"}`,
            headerTh: "ตำแหน่งงาน",
            headerEn: "Position",
          },
        ]}
        onSelectFunc={(e) => {
          let _data = form.getFieldsValue();

          _data.requestor = e;
          setRequestorData(e);
          _data.reportTo = employeeList.find(
            (emp) =>
              emp.EmployeeId.toString() === _data.requestor.ReportToEmpCode
          );
          form.setFieldsValue(_data);
          setOpenEmpSelector(false);
        }}
        dialogVisible={openEmpSelector}
        setDialogVisible={setOpenEmpSelector}
      />
      <SelectDataDialog
        dataList={templateList}
        dialogKey={"Template"}
        columns={[
          {
            field: "TemplateName",
            headerTh: "ชื่อ",
            headerEn: "Name",
          },
          {
            field: "TemplateNameWithCode",
            headerTh: "ชื่อ : รหัส",
            headerEn: "Name with code",
          },
        ]}
        onSelectFunc={onSelectTemplate}
        dialogVisible={openTemSelector}
        setDialogVisible={setOpenTemSelector}
      />
    </Form>
  );
};
