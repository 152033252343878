import { Device } from "../constants/device-type";


export const browserDetector = (): Device | string => {
    const userAgent = navigator.userAgent;

    if (userAgent.match(/Edg\//i)) {
        return Device.edge; // Edge detection should come first
    }
    if (userAgent.match(/OPR\/|Presto\//i)) {
        return Device.opera;
    }
    if (userAgent.match(/Chrome\//i) && !userAgent.match(/Edg\//i)) {
        return Device.chrome;
    }
    if (userAgent.match(/Firefox/i)) {
        return Device.firefox;
    }
    if (userAgent.match(/Safari\//i) && !userAgent.match(/Chrome\//i)) {
        return Device.safari; // Ensure it's not Chrome
    }

    return "unknown";
};
