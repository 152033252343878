import { LocalStoreType } from "../constants/local-type";
import { getDataLocal } from "../Helper/localStoreFunction";

export const GetAllDynamic = async (
  apiName: any,
  requestBody?: any,
  queryString?: any
) => {
  try {
    if (!requestBody && getDataLocal(LocalStoreType.email)) {
      const email = getDataLocal(LocalStoreType.email) || "";
      requestBody = { UserPrincipal: email, ConnectionString: "" };
    }

    let url = "";
    if (queryString) {
      let urlParams = new URLSearchParams({
        Scopes: queryString?.Scopes || "",
        IsActiveOnly: queryString?.IsActiveOnly || "",
      });
      url = "/api/" + apiName + "?" + urlParams.toString();
    } else {
      url = "/api/" + apiName;
    }

    const respone = await fetch(url, requestBody)
      .then((response: any) => response.json())
      .then((data: any) => {
        return data;
      })
      .catch((ex: any) => {
        return ex;
      });

    return respone;
  } catch (error) {
    console.log("service=>GetAllDynamic=>error", error);
  }
};

export const updateDynamic = async (apiName: any, formBody: any) => {
  if (apiName.endsWith("s")) {
    apiName = apiName.substring(0, apiName.length - 1);
  }

  const data = {
    name: apiName !== "MasterData" ? apiName.replace("Master", "") : apiName,
    model: JSON.stringify(formBody),
  };
  const respone = await fetch("/api/MasterData/UpdateData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return respone;
};
