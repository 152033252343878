import React, { useState, useEffect, useRef } from "react";
import EditPdfMainPage from "./EditPdfMainPage";
import { useLocation } from "react-router-dom";

interface LocationState {
    data: string;
    memo: any;
    fileName: any;
}

const EditPdfComponent = () => {
    const location = useLocation<LocationState>();
    const { data, memo, fileName } = location?.state || {};

    return (
        <>
            <EditPdfMainPage
                path={data}
                memo={memo}
                fileName={fileName}
            />
        </>
    )
}

export default EditPdfComponent;