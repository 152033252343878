import React, { useState, useEffect, FC } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "./LineApprovalsComponent.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { IListApprovalDetailsModel } from "../../../IRequestModel/IListApprovalDetailsModel";
import { IApproverModel } from "../../../IRequestModel/IApproverModel";
import { ButtonComponents } from "../../ButtonComponents/ButtonComponents";
import { InputNumber } from "primereact/inputnumber";
import { SelectDataDialog } from "../../Select/SelectionDataDialog/SelectDataDialog";
import { useUserContext } from "../../../Context/UserContext";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import useAlert from "../../../hooks/useAlert";
import { GetAllEmployee } from "../../../Services/EmployeeService";
import { Row, Col, Modal } from "antd";
import { GetAllApprovals } from "../../../Services/ApprovalService";
import { SaveLineApprovalsByMemoId } from "../../../Services/LineApprovalsService";
import { useHistory } from "react-router";
import useLoading from "../../../hooks/useLoading";

type Props = {
  lineApproval: any;
  setLineApproval: any;
  employeeList: any;
  signatureList: any[];
  canEditLineApproval: boolean;
  currentLevel: number;
  t: any;
  memoDetail: any;
  setEmployeeList: any;
  employee: any;
  getLineApproveForAmount: (
    amount: number,
    jsonCondition?: any,
    logicType?: string,
    template?: any,
    _requestor?: any
  ) => Promise<void>;
};

const LineApprovalsComponentFix: FC<Props> = ({
  lineApproval,
  employeeList,
  signatureList,
  canEditLineApproval,
  setLineApproval,
  currentLevel,
  t,
  memoDetail,
  setEmployeeList,
  employee,
  getLineApproveForAmount,
}) => {
  //PageSetting
  const [userData, setUserData] = useUserContext();
  const [visibleConfirmProp, setVisibleConfirmProp] = useState(false);
  const { lg } = useBreakpoint();
  const [rowDeleted, setRowDeleted] = useState<any>();
  const [dragIndex, setFromIndex] = useState(1);
  const [moveToNumber, setMoveToNumber] = useState(1);
  const [moveToDialogVisible, setMoveToDialogVisible] = useState(false);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [searchData, setSearchData] = useState<any[]>(employeeList);
  const { toggleAlert } = useAlert();
  const [showDialogEmp, setShowDialogEmp] = useState(true);
  const [hightLight, setHightLight] = useState<any[]>([]);
  const history = useHistory();

  useEffect(() => {
    // lineApproval.sort((a: any, b: any) => {
    //   return a.sequence - b.sequence;
    // });

    setHightLight(
      lineApproval.filter(
        (a: any) => memoDetail.current_approval_level === a.sequence
      )
    );
  }, [lineApproval]);

  const confirmDialogfunction = (rowDeleted: any) => {
    setVisibleConfirmProp(true);
    setRowDeleted(rowDeleted);
  };

  function rowDelete(rowIndex: any) {
    let data = lineApproval;
    data.splice(rowIndex, 1);
    const allSeq: number[] = Array.from(
      new Set(data.map((e: { sequence: any }) => e.sequence))
    );
    for (let i = 0; i < allSeq.length; i++) {
      const seq: number = allSeq[i];
      data.map((e: { sequence: number }) => {
        if (e.sequence === seq) {
          e.sequence = i + 1;
        }
        return e;
      });
    }

    setLineApproval([...data]);
  }

  const actionBodyTemplate = (rowData: any, options: any) => {
    if (canEditLineApproval && currentLevel < rowData.sequence) {
      return (
        <React.Fragment>
          <ButtonComponents
            setIconProps={"pi pi-trash"}
            setClassNameProps={
              " p-button-danger p-mr-2 set-icon-LineApprovalsComponent"
            }
            onClickProps={() => confirmDialogfunction(options.rowIndex)}
          />
        </React.Fragment>
      );
    }
  };

  async function toggleModal() {
    setDialogVisible(true);
    setShowDialogEmp(true);

    if (showDialogEmp && employee.length <= 0) {
      let req = {
        IsActiveOnly: true,
        Scopes: "name,email,org",
      };

      const employeeList = await GetAllEmployee(req);
      setEmployeeList(employeeList);
      setSearchData(employeeList);
    }

    setShowDialogEmp(false);
  }

  const onRowReorder = (e: any) => {
      
    if (
      currentLevel !== 0 &&
      ( lineApproval[e.dragIndex]?.sequence <= memoDetail.current_approval_level ||  lineApproval[e.dropIndex]?.sequence <= memoDetail.current_approval_level)
    ) {

      
      toggleAlert({
        description: `Cannot reorder to lower step.`,
        message: `Reorder`,
        type: "warning",
      });
    } else {
      if (currentLevel !== 0 && !userData.IsAdmin) {
        
        if (lineApproval[e.dragIndex]?.sequence > currentLevel) {
          const val = reorderLineapproval(
            e.value,
            e.dragIndex,
            e.dropIndex
          ); 

          setLineApproval(val);
        } else {
          toggleAlert({
            description: `Cannot reorder to lower step.`,
            message: `Reorder`,
            type: "warning",
          });
        }
      } else {
        const val = reorderLineapproval(e.value, e.dragIndex, e.dropIndex);
        setLineApproval(val);
      }
    }
  };
  const attachIndicesInRange = (
    array: any[],
    dragIndex: number,
    dropIndex: number
  ): any[] => {
    // Ensure dragIndex is smaller or equal to dropIndex
    const start = Math.min(dragIndex, dropIndex);
    const end = Math.max(dragIndex, dropIndex);
  
    // Map through the array and attach indices only for the specified range
    return array.map((item, index) => ({
      ...item,
      index: index >= start && index <= end ? index : item.index ?? null, // Update index if in range, otherwise keep existing or set null
    }));
  };
  const moveItemIn = (array: any[], dragIndex: number, dropIndex: number): any[] => {
    // Validate indices
    if (
      dragIndex < 0 || 
      dragIndex >= array.length || 
      dropIndex < 0 || 
      dropIndex >= array.length
    ) {
      console.error("Invalid dragIndex or dropIndex");
      return array; // Return the original array if indices are invalid
    }
  
    // Create a copy of the array to avoid mutating the original
    const updatedArray = [...array];
  
    // Remove the dragged item from its original position
    const [draggedItem] = updatedArray.splice(dragIndex, 1);
  
    // Insert the dragged item at the new position
    updatedArray.splice(dropIndex, 0, draggedItem);
  
    return updatedArray.map((item, index) => ({
      ...item,
      index // Attach the new index to each item
    }));
  };

  
  const reorderLineapproval = (
    lineApproval: any[],
    dragIndex: number,
    dropIndex: number
  ) => {
    if (
      dragIndex === dropIndex ||
      dragIndex < 0 ||
      dragIndex >= lineApproval.length ||
      dropIndex < 0 ||
      dropIndex > lineApproval.length
    ) {
      return lineApproval; // ไม่เปลี่ยนค่า
    }
    // จัดเรียง lineApproval ไม่ให้แตกกลุ่ม
    let newLine = reorderGroup([...lineApproval]);
    let groupedParallel: any = {};

    // จัดกลุ่ม parallel ตาม sequence
    for (let i = 0; i < newLine.length; i++) {
      let approver = newLine[i];

      if (approver.IsParallel) {
        if (!groupedParallel[approver.sequence]) {
          groupedParallel[approver.sequence] = [];
        }

        groupedParallel[approver.sequence].push(approver);
      }
    }

    let seq = dragIndex > dropIndex ? 0 : 1; // หาค่าเริ่มต้นตามทิศทาง
    let parallelSeq = 0; // pointer ตาม parallel

    for (let i = 0; i < newLine.length; i++) {
      let approver = newLine[i];

      // เปลี่ยค่า seq เมื่อถึง line ที่ ไม่ใช่ parallel
      if (!approver.IsParallel) {
        // เพิ่มขึ้นเสมอ
        seq = seq + 1;
        approver.sequence = seq; // เปลี่ยนค่า sequence

        // เปลี่ยค่า seq เมื่อถึง line ที่ เป็น parallel
      } else if (groupedParallel[approver.sequence]) {
        // เมื่อเจอกลุ่ม parallel ใหม่
        if (approver.sequence !== parallelSeq) {
          parallelSeq = approver.sequence; // เพิ่ม parallelSeq
          seq = seq + 1; // เพิ่มค่าสำหรับ parallel ใหม่
        }
        approver.sequence = seq; // เปลี่ยนค่า sequence
      }
    }

    // ลบ 1 เมื่อย้ายลง
    if (dragIndex < dropIndex) {
      for (let i = 0; i < newLine.length; i++) {
        newLine[i].sequence = newLine[i].sequence - 1;
      }
    }
    return newLine;
  };

  function reorderGroup(objects: any[]): any[] {
    const groupedMap = new Map<string, any[]>();
    for (const obj of objects) {
      const key = `${obj.sequence}-${obj.IsParallel}`;
      if (!groupedMap.has(key)) {
        groupedMap.set(key, []);
      }
      groupedMap.get(key)?.push(obj);
    }

    const reorderedObjects: any[] = [];
    for (const group of groupedMap.values()) {
      reorderedObjects.push(...group);
    }

    return reorderedObjects;
  }

  const onRowSelect = (data: any) => {
    addData(data);
  };

  function addData(empData: any) {
    try {
      const raw = lineApproval;
      const lastData = lineApproval[lineApproval.length - 1];
      let data: IApproverModel = empData;
      const defSignature = signatureList[0];

      if (lastData != undefined) {
        let newData: IListApprovalDetailsModel = {
          approver: data,
          emp_id: data.EmployeeId,
          lineid: lastData.lineid + 1,
          sequence: lastData.sequence + 1,
          signature_en: defSignature.Value2,
          signature_id: defSignature.MasterId,
          signature_th: defSignature.Value1,
          ColumnJsonCondValue: "",
        };
        raw.push(newData);
      } else {
        let newData: IListApprovalDetailsModel = {
          approver: data,
          emp_id: data.EmployeeId,
          lineid: 1,
          sequence: 1,
          signature_en: defSignature.Value2,
          signature_id: defSignature.MasterId,
          signature_th: defSignature.Value1,
          ColumnJsonCondValue: "",
        };
        raw.push(newData);
      }

      setLineApproval([...raw]);
      setDialogVisible(!isDialogVisible);
    } catch (error) {
      console.log("LineApproval=>addData=>error", error);
    }
  }

  const statusBodyTemplate = (rowData: any, option: any) => {
    if (canEditLineApproval && currentLevel <= rowData.sequence) {
      return (
        <Dropdown
          className="dropdown-lineapprove"
          value={
            signatureList.filter((e: any) => {
              if (e.MasterId === rowData.signature_id) {
                return e;
              }
            })[0]
          }
          options={signatureList}
          onChange={(e) => onSelected_Sinatures(e, option.rowIndex)}
          optionLabel={userData.Lang === "EN" ? "Value2" : "Value1"}
          placeholder="Select a Signature"
        />
      );
    } else {
      return (
        <div className="status-badge">
          <span>
            {userData.Lang === "EN"
              ? rowData.signature_en
              : rowData.signature_th}
          </span>
        </div>
      );
    }
  };

  function onSelected_Sinatures(event: any, rowInx: number) {
    let line: any = lineApproval;
    line[rowInx].signature_id = event.value.MasterId;
    line[rowInx].signature_th = event.value.Value1;
    line[rowInx].signature_en = event.value.Value2;
    setLineApproval([...line]);
  }

  const dynamicTable = (
    <DataTable
      className="wolf-table"
      value={lineApproval}
      onRowReorder={onRowReorder}
      // showGridlines
      tableStyle={{ width: "100%" }}
      // tableStyle={{ overflow: "scroll" }}
      dataKey="sequence"
      stripedRows
      scrollable
      scrollDirection="both"
      selection={hightLight}
      selectionMode="multiple"
      responsiveLayout="scroll"
    >
      {canEditLineApproval && (
        <Column
          rowReorder={lg ? true : false}
          // style={{ flexGrow: 1, flexBasis: "50px" }}
          style={{ width: "70px" }}
        />
      )}

      <Column
        header="#"
        body={(lineData, options) => lineData.sequence || options.rowIndex + 1}
        // style={{ flexGrow: 0, flexBasis: "50px" }}
        style={{ width: "70px" }}
      />
      <Column
        key={userData.Lang === "EN" ? "approver.NameEn" : "approver.NameTh"}
        columnKey={
          userData.Lang === "EN" ? "approver.NameEn" : "approver.NameTh"
        }
        field={userData.Lang === "EN" ? "approver.NameEn" : "approver.NameTh"}
        header={
          <>
            <p className="row headtext">Employee Name</p>
            <p className="row subtext">ชื่อพนักงาน</p>
          </>
        }
        // style={{ flexGrow: 1, flexBasis: "150px" }}?
        style={{ width: "25%" }}
      />

      <Column
        key={
          userData.Lang === "EN"
            ? "approver.PositionNameEn"
            : "approver.PositionNameTh"
        }
        columnKey={
          userData.Lang === "EN"
            ? "approver.PositionNameEn"
            : "approver.PositionNameTh"
        }
        field={
          userData.Lang === "EN"
            ? "approver.PositionNameEn"
            : "approver.PositionNameTh"
        }
        header={
          <>
            <p className="row headtext">Position</p>
            <p className="row subtext">ตำแหน่ง</p>
          </>
        }
        // style={{ flexGrow: 1, flexBasis: "150px" }}
        style={{ width: "25%" }}
      />

      <Column
        key={
          userData.Lang === "EN"
            ? "approver.DepartmentNameEn"
            : "approver.DepartmentNameTh"
        }
        columnKey={
          userData.Lang === "EN"
            ? "approver.DepartmentNameEn"
            : "approver.DepartmentNameTh"
        }
        field={
          userData.Lang === "EN"
            ? "approver.DepartmentNameEn"
            : "approver.DepartmentNameTh"
        }
        header={
          <>
            <p className="row headtext">Department</p>
            <p className="row subtext">หน่วยงาน</p>
          </>
        }
        // style={{ flexGrow: 1, flexBasis: "150px" }}
        style={{
          width: canEditLineApproval ? "calc(25% - 100px)" : "calc(25% - 30px)",
        }}
      />

      <Column
        key={userData.Lang === "EN" ? "signature_en" : "signature_th"}
        columnKey={userData.Lang === "EN" ? "signature_en" : "signature_th"}
        field={userData.Lang === "EN" ? "signature_en" : "signature_th"}
        header={
          <>
            <p className="row headtext">Signature </p>
            <p className="row subtext">คำลงท้าย</p>
          </>
        }
        body={statusBodyTemplate}
        // style={{ flexGrow: 1, flexBasis: "50px" }}
        style={{
          width: canEditLineApproval ? "calc(25% - 140px)" : "calc(25% - 40px)",
        }}
      />

      {canEditLineApproval && (
        <Column
          header={
            <>
              <p className="row headtext">Delete </p>
              <p className="row subtext">ลบ</p>
            </>
          }
          body={actionBodyTemplate}
          // style={{ width: "15%" }}
          style={{ width: "100px" }}
          exportable={false}
        ></Column>
      )}
    </DataTable>
  );

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => setMoveToDialogVisible(false)}
          style={{ height: "38px" }}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => acceptMoveto(dragIndex, moveToNumber)}
          style={{ height: "38px" }}
          autoFocus
        />
      </div>
    );
  };

  const acceptMoveto = (fromIndex: number, targetIndex: number) => {
    if (targetIndex > currentLevel) {
      moveItem(fromIndex, targetIndex - 1);
    } else {
      toggleAlert({
        description: `Cannot reorder to lower step.`,
        message: `Reorder`,
        type: "error",
      });
    }
  };

  const moveItem = (fromIndex: number, targetIndex: number) => {
    try {
      let rows = lineApproval !== null ? [...lineApproval] : [];
      let updateRow = rows[fromIndex];
      rows[fromIndex] = rows[targetIndex];
      rows[targetIndex] = updateRow;
      for (let i = 0; i < rows.length; i++) {
        let a = 1;
        a = a + i;
        rows[i].sequence = a;
      }
      setLineApproval([...rows]);
      setMoveToDialogVisible(false);
    } catch (error) {
      console.log("table=>error", error);
    }
  };

  const onResetClick = async () => {
    if (memoDetail.memoid == 0) {
      getLineApproveForAmount(
        Number(memoDetail.amount.replaceAll(",", "")),
        null,
        "reset"
      );
    } else {
      const res = await GetAllApprovals({ memoid: memoDetail.memoid });
      res.map((e: any) => {
        e.approver = {
          EmployeeId: e.approver.employeeId,
          NameEn: e.approver.nameEn,
          NameTh: e.approver.nameTh,
          PositionNameEn: e.approver.positionNameEn,
          PositionNameTh: e.approver.positionNameTh,
          DepartmentNameEn: e.approver.departmentNameEn,
          DepartmentNameTh: e.approver.departmentNameTh,
        };
        return e;
      });

      setLineApproval([...res]);
    }
  };

  const onSaveLineApprovals = async () => {
    try {
      const res = await SaveLineApprovalsByMemoId(
        memoDetail.memoid,
        memoDetail.current_approval_level,
        lineApproval
      );
      toggleAlert({
        description: `line approval saved Success.`,
        message: `Success`,
        type: "success",
      });
    } catch (error) {
      toggleAlert({
        description: `line approval cannot be saved.`,
        message: `Oops! Something went wrong`,
        type: "error",
      });
    }
  };

  return (
    <div>
      <ConfirmDialog
        visible={visibleConfirmProp}
        onHide={() => setVisibleConfirmProp(false)}
        message={"Do you want to delete this row?"}
        header="Confirmation"
        icon="pi pi-info-circle"
        className="z-index-confirm"
        acceptClassName="p-button-danger table-control-confirm-button p-button-accept-cancel"
        rejectClassName="p-button p-component p-confirm-dialog-reject p-button p-component p-button-outlined p-button-danger"
        position="top"
        accept={() => rowDelete(rowDeleted)}
        draggable={false}
      />
      {canEditLineApproval && (
        <>
          <Row gutter={[10, 10]} align="middle" justify="space-between">
            <Col
              style={{ display: "flex", flexDirection: "row", columnGap: 10 }}
            >
              <ButtonComponents
                setIconProps={"pi pi-plus"}
                setLabelProps={t("Add")}
                setStyleProps={{
                  marginBottom: "10px",
                  borderRadius: "6px",
                  height: "40px",
                  backgroundColor: "rgb(40, 47, 106)",
                  border: "1px solid rgb(40, 47, 106)",
                }}
                onClickProps={() => toggleModal()}
              />
              <ButtonComponents
                setIconProps={"pi pi-sync"}
                setLabelProps={t("Reset")}
                setClassNameProps={"reset-btn"}
                onClickProps={() => {
                  Modal.confirm({
                    title: userData.Lang === "en" ? "Confirm" : "ยืนยัน",
                    content:
                      userData.Lang === "en" ? (
                        <p>
                          Line approval will be re-setting by form condition.
                          Are you sure to reset?
                        </p>
                      ) : (
                        <p>
                          สายอนุมัติจะถูกตั้งค่าใหม่ตามเงื่อนไขของแบบฟอร์ม
                          คุณแน่ใจที่จะรีเซ็ตหรือไม่?
                        </p>
                      ),
                    okText: "Reset",
                    onOk: () => {
                      onResetClick();
                    },
                    okButtonProps: {
                      title: "Reset",
                      style: {
                        borderRadius: 6,
                        borderColor: "rgb(40, 47, 106);",
                        color: "rgb(40, 47, 106);",
                      },
                    },
                    cancelButtonProps: {
                      danger: true,
                      style: { borderRadius: 6 },
                    },
                  });
                }}
              />
            </Col>
            {userData.IsAdmin && memoDetail?.memoid !== 0 && (
              <Col>
                <ButtonComponents
                  setIconProps={"pi pi-save"}
                  setLabelProps={t("Save")}
                  setClassNameProps={"reset-btn"}
                  onClickProps={onSaveLineApprovals}
                />
              </Col>
            )}
          </Row>
        </>
      )}{" "}
      {dynamicTable}
      <Dialog
        header="Move To"
        visible={moveToDialogVisible}
        style={{ borderRadius: "16px" }}
        footer={renderFooter()}
        onHide={() => setMoveToDialogVisible(false)}
      >
        <Row>
          <InputNumber
            inputId="integeronly"
            value={moveToNumber}
            min={1}
            max={lineApproval?.length}
            onValueChange={(e: any) => {
              if (e.value <= lineApproval?.length) {
                setMoveToNumber(e.value);
              }
            }}
          />
        </Row>
      </Dialog>
      <SelectDataDialog
        dialogKey={"Employee"}
        dataList={employee}
        onSelectFunc={onRowSelect}
        mutiable
        columns={[
          {
            field: "EmployeeCode",
            headerEn: "EmployeeCode",
            headerTh: "รหัสพนักงาน",
          },
          {
            field: "NameEn",
            headerEn: "Name",
            headerTh: "ชื่อ",
          },
          {
            field: "PositionNameEn",
            headerEn: "Position",
            headerTh: "ตำแหน่ง",
          },
          {
            field: "DepartmentNameEn",
            headerEn: "Department",
            headerTh: "หน่วยงาน",
          },
        ]}
        dialogVisible={isDialogVisible}
        setDialogVisible={setDialogVisible}
        loading={showDialogEmp}
      />
    </div>
  );
};
export default LineApprovalsComponentFix;
