export enum TypeMapping {
    AutoNumber = "an",
    Table = "tb",
    ShortText = "t",
    Calendar = "d",
    Attachment = "at", 
    Radio = "r",
    MultiLine = "ta",
    Decimal = "c",
    MultiChoice = "cb",
    Dropdown = "dd",
    Editor = "ed",
    Number = "n",
}