import {
  Button,
  Col,
  Row,
  Select,
  InputNumber,
  Checkbox,
  Modal,
  Input,
  Popover,
  ConfigProvider,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import usePDFEditor from "../../../hooks/usePDFEditor";
import TabsButton from "./TabsButton";
import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
  LinkOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  IControlProperty,
  ILayoutProperty,
} from "../../../Context/PDFEditorContext";
import ColorPicker from "./ColorPicker";
import { UploadTinyMce } from "../../../Services/UploadFileService";
import { useUserContext } from "../../../Context/UserContext";

type Props = { fontList: any[] };

const PDFEditorToolBar = ({ fontList }: Props) => {
  const {
    pageProperty,
    changeDocType,
    updatePageProperties,
    refHtml,
    paperLayout,
    setPaperLayout,
    selectionBlock,
    onSelectPropertyChange,
  } = usePDFEditor();
  const [activeKey, setActiveKey] = useState("text");
  const [userData, setUserData] = useUserContext();
  const [controlProperty, setControlProperty] = useState<any>();
  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [linkUrl, setLinkUrl] = useState<string>("");
  var _userData = JSON.parse(window.localStorage.getItem("userData") || "");

  ConfigProvider.config({ theme: { primaryColor: "#f8a51c" } });

  useEffect(() => {
    if (!selectionBlock) {
      setActiveKey("layout");
    } else {

      setControlProperty({ ...selectionBlock?.template.attribute });
      setActiveKey("text");
    }
  }, [selectionBlock]);

  useEffect(() => {
    if (controlProperty) onSelectPropertyChange(controlProperty);
  }, [controlProperty]);

  const checkFile = (file: File) => {
    setLoading(true);
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage;
  };

  const getLink = (val: string) => {
    let imgUrl = "";
    const protocol = window.location.protocol;

    const _port = window.location.port;
    let _tinyURL = _userData.TinyURL;
    if (_port) {
      _tinyURL = _userData.TinyURL;
    }
    imgUrl = `${protocol}//` + _tinyURL + val;

    return imgUrl !== ""
      ? imgUrl
      : "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";
  };

  const layoutSettingToolComponent = () => {
    try {
      const layoutAttribute = selectionBlock?.template
        .attribute as ILayoutProperty;
      return (
        <>
          <Col>
            <Upload
              beforeUpload={checkFile}
              showUploadList={false}
              onChange={async (e) => {
                if (e.file.originFileObj) {
                  const uploadedFile = e.file.originFileObj;
                  if (uploadedFile) {
                    const file = new File([uploadedFile], uploadedFile.name, {
                      lastModified: Date.now(),
                    });
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("Doclib", "TempAttachment");
                    formData.append("docSet", "TinyImages");
                    formData.append("fileDesc", "");
                    formData.append("actorID", userData.EmployeeId.toString());

                    var response: any = await UploadTinyMce(formData);
                    if (response?.data?.result) {
                      setPaperLayout([
                        ...paperLayout.map((e) => {
                          if (e.i === selectionBlock?.i) {
                            let eAttribute = e.template
                              .attribute as ILayoutProperty;
                            if (!eAttribute) {
                              eAttribute = {
                                backgroundImage: getLink(response.data.pathUrl),
                                objectFit: "contain",
                              };
                            } else {
                              eAttribute.backgroundImage = getLink(
                                response.data.pathUrl
                              );
                            }
                          }
                          return e;
                        }),
                      ]);
                    }
                  }
                }
              }}
              // fileList={fileList}
              style={{ height: "100%" }}
            >
              <Button icon={<PlusOutlined rev={undefined} />}>
                Click to Upload
              </Button>
            </Upload>
          </Col>
          <Col>
            <Select
              value={layoutAttribute?.objectFit}
              placeholder="Display type"
              onChange={(e) => {
                if (controlProperty) {
                  let newProps: ILayoutProperty = { ...controlProperty };
                  newProps.objectFit = e;
                  setPaperLayout([
                    ...paperLayout.map((layout) => {
                      if (layout.i === selectionBlock?.i) {
                        if (layout.template.attribute) {
                          layout.template.attribute = newProps;
                        }
                      }
                      return layout;
                    }),
                  ]);
                }
              }}
              options={[
                { label: "Cover", value: "cover" },
                { label: "Contain", value: "contain" },
                { label: "Fill ", value: "fill" },
              ]}
            />
          </Col>
        </>
      );
    } catch (error) {
      console.error(error);
    }
    return <></>;
  };

  const imageToolComponent = (
    <>
      <Col span={4}>
        <Select
          value={controlProperty?.objectFit}
          placeholder="Display type"
          onChange={(e) => {
            if (controlProperty) {
              let newProps: ILayoutProperty = { ...controlProperty };
              newProps.objectFit = e;
              setControlProperty(newProps);
            }
          }}
          options={[
            { label: "Cover", value: "cover" },
            { label: "Contain", value: "contain" },
            { label: "Fill ", value: "fill" },
          ]}
        />
      </Col>
    </>
  );

  const textToolComponent = (
    <>
      <Col span={4}>
        <Select
          value={controlProperty?.fontFamily}
          placeholder="Font family"
          onChange={(e) => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.fontFamily = e;
              setControlProperty(newProps);
            }
          }}
          options={fontList}
        />
      </Col>
      <Col span={3}>
        <InputNumber
          placeholder="Font size"
          value={controlProperty?.fontSize}
          addonAfter="px"
          onChange={(e) => {
            if (controlProperty && e) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.fontSize = e;
              setControlProperty(newProps);
            }
          }}
        />
      </Col>
      <Col>
        <ColorPicker
          color={controlProperty?.color || "#ffff"}
          onChageColor={(e) => {
            if (controlProperty && e) {
              let newProps: IControlProperty = {
                ...controlProperty,
              };
              newProps.color = e.hex;
              setControlProperty(newProps);
            }
          }}
        />
      </Col>
      <Col style={{ display: "flex", flexDirection: "row", columnGap: 5 }}>
        <Button
          icon={<BoldOutlined rev={undefined} />}
          type={controlProperty?.isBold ? "primary" : "default"}
          onClick={() => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.isBold = !newProps.isBold;
              setControlProperty(newProps);
            }
          }}
        />
        <Button
          icon={<ItalicOutlined rev={undefined} />}
          type={controlProperty?.isItalic ? "primary" : "default"}
          onClick={() => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.isItalic = !newProps.isItalic;

              setControlProperty(newProps);
            }
          }}
        />
        <Button
          icon={<UnderlineOutlined rev={undefined} />}
          type={controlProperty?.isUnderlined ? "primary" : "default"}
          onClick={() => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.isUnderlined = !newProps.isUnderlined;
              setControlProperty(newProps);
            }
          }}
        />
      </Col>
      <Col style={{ display: "flex", flexDirection: "row", columnGap: 5 }}>
        <Button
          icon={<AlignLeftOutlined rev={undefined} />}
          type={
            controlProperty?.hAlign === "flex-start" ? "primary" : "default"
          }
          onClick={() => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.hAlign = "flex-start";
              setControlProperty(newProps);
            }
          }}
        />
        <Button
          icon={<AlignCenterOutlined rev={undefined} />}
          type={controlProperty?.hAlign === "center" ? "primary" : "default"}
          onClick={() => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.hAlign = "center";
              setControlProperty(newProps);
            }
          }}
        />
        <Button
          icon={<AlignRightOutlined rev={undefined} />}
          type={controlProperty?.hAlign === "flex-end" ? "primary" : "default"}
          onClick={() => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.hAlign = "flex-end";
              setControlProperty(newProps);
            }
          }}
        />
      </Col>
      <Col style={{ display: "flex", flexDirection: "row", columnGap: 5 }}>
        <Button
          icon={<VerticalAlignBottomOutlined rev={undefined} />}
          type={controlProperty?.vAlign === "flex-end" ? "primary" : "default"}
          onClick={() => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.vAlign = "flex-end";
              setControlProperty(newProps);
            }
          }}
        />
        <Button
          icon={<VerticalAlignMiddleOutlined rev={undefined} />}
          type={controlProperty?.vAlign === "center" ? "primary" : "default"}
          onClick={() => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.vAlign = "center";
              setControlProperty(newProps);
            }
          }}
        />
        <Button
          icon={<VerticalAlignTopOutlined rev={undefined} />}
          type={
            controlProperty?.vAlign === "flex-start" ? "primary" : "default"
          }
          onClick={() => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.vAlign = "flex-start";
              setControlProperty(newProps);
            }
          }}
        />
      </Col>
      {selectionBlock !== undefined && (
        <Col>
          <Button
            icon={<LinkOutlined rev={undefined} />}
            type={selectionBlock?.template.value !== "" ? "primary" : "default"}
            onClick={() => {
              if (
                selectionBlock?.template.value &&
                selectionBlock?.template.value !== ""
              ) {
                setLinkUrl(selectionBlock?.template?.value);
              }
              setIsLinkModalOpen(!isLinkModalOpen);
            }}
          />
        </Col>
      )}{" "}
      <Col>
        <Checkbox
          checked={controlProperty?.hideLabel}
          onChange={(e) => {
            if (controlProperty) {
              let newProps: IControlProperty = { ...controlProperty };
              newProps.hideLabel = e.target.checked;
              setControlProperty(newProps);
            }
          }}
        >
          Hide label
        </Checkbox>
      </Col>
    </>
  );

  const tabsItems = [
    {
      key: "layout",
      label: "Layout",
      children: (
        <>
          {" "}
          <Col>Page size :</Col>
          <Col span={4}>
            <Select
              value={pageProperty?.doctype}
              placeholder="Font family"
              onChange={(e) => {
                changeDocType(e);
                // updatePageProperties({ doctype: e });
              }}
              options={[
                { label: "A4", value: "A4" },
                { label: "Letter", value: "Letter" },
              ]}
            />
          </Col>
          <Col>Grid size :</Col>
          <Col span={3}>
            <InputNumber
              placeholder="Grid size"
              value={pageProperty?.gridSize}
              addonAfter="px"
              onChange={(e) => {
                if (e) updatePageProperties({ gridSize: e });
              }}
            />
          </Col>
          <Col>Padding :</Col>
          <Col span={3}>
            <InputNumber
              placeholder="Padding"
              value={pageProperty?.padding}
              addonAfter="px"
              onChange={(e) => {
                if (e) updatePageProperties({ padding: e });
              }}
            />
          </Col>
        </>
      ),
    },
    {
      key: "text",
      label:
        selectionBlock?.template.type === "staticImage"
          ? "Image"
          : selectionBlock?.template.layoutType === "content"
          ? "Text"
          : selectionBlock?.template.type,

      children:
        selectionBlock?.template.type === "staticImage"
          ? imageToolComponent
          : selectionBlock?.template.layoutType === "content"
          ? textToolComponent
          : layoutSettingToolComponent(),
    },
  ];

  return (
    <>
      <TabsButton
        key={"tabs"}
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key);
        }}
        items={tabsItems.filter((e) =>
          selectionBlock ? true : e.key === "layout"
        )}
      />
      <Row className="tab-panel" align="middle" gutter={[10, 10]}>
        {tabsItems.find((e) => e.key === activeKey)?.children}
      </Row>
      <Modal
        title="Link"
        centered
        visible={isLinkModalOpen}
        onOk={() => {
          if (selectionBlock) {
            setPaperLayout([
              ...paperLayout.map((e) => {
                if (e.children?.length === 0) return e;

                e.children = e.children?.map((child) => {
                  if (child.i === selectionBlock.i) {
                    child.template.value = linkUrl;
                  }
                  return child;
                });

                return e;
              }),
            ]);
          }
          setLinkUrl("");

          setIsLinkModalOpen(!isLinkModalOpen);
        }}
        onCancel={() => {
          setLinkUrl("");

          setIsLinkModalOpen(!isLinkModalOpen);
        }}
      >
        <Input
          type="text"
          placeholder="Link url"
          value={linkUrl}
          onChange={(e) => {
            setLinkUrl(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};

export default PDFEditorToolBar;
