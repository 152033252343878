import { AutoComplete, Button, Checkbox, Divider, Input, Popover } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { ColumnsProps, FilterComponentProps } from "../DynamicTableV3";
import { ColumnFilterElementTemplateOptions, ColumnFilterClearTemplateOptions } from "primereact/column";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import CheckboxGroupSelelect from "./CheckboxGroupSelelect";
import { filterData } from "../../../../Helper/TableHelper";
import { log } from "node:console";
const CheckboxGroup = Checkbox.Group;

const StringFilterComponent = ({
  colProp,
  options,
  dataSource,
  filtersState: filterState,
  allColumnProps,
}: FilterComponentProps) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [columnOption, setColumnOption] = useState<any[]>([]);
  const [sourceOptions, setSourceOptions] = useState<any[]>([]);
  const [autoComplateOptions, setAutoComplateOptions] = useState<any[]>([]);
  // const [visibleChange, setVisibleChange] = useState<boolean>(false);

  useEffect(() => {
    const defColumnOption = () => {
      const data = Array.from(
        new Set(
          dataSource?.map((e) => {
            return e[colProp.key];
          })
        )
      ).map((e) => {
        return { value: e };
      });
      setSourceOptions([...data]);
      setAutoComplateOptions([...data]);
    };
    defColumnOption();
  }, []);

  useEffect(() => {

    if (!options.value) {
      setSearchValue("");
      setCheckedList([]);

    }
  }, [options]);

  const onSearchOptions = (e: string) => {
    if (e === "" && checkedList.length === 0) {
      options.filterApplyCallback(null);
    } else {
      options.filterApplyCallback({
        searchText: e,
        checkedList: checkedList,
      });
    }
    setSearchValue(e);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 5,
      }}
    >

      <AutoComplete
        key={colProp.key + "filter"}
        style={{ width: 200 }}
        value={searchValue}
        options={autoComplateOptions}
        onDropdownVisibleChange={(e) => {
          let _autoComplateOptions = [...autoComplateOptions];
          if (e) {
            const result = filterData(
              dataSource,
              filterState,
              allColumnProps,
              colProp
            );

            _autoComplateOptions = result.map((e) => {
              return e[colProp.key];
            });
          }

          setAutoComplateOptions([
            ...Array.from(new Set(_autoComplateOptions)).map((e) => ({
              value: e,
            })),
          ]);
        }}
        onSearch={(e) => {
          setAutoComplateOptions([
            ...sourceOptions.filter((data) =>
              data.value?.toLowerCase().includes(e?.toLowerCase())
            ),
          ]);
        }}
        onChange={onSearchOptions}
        placeholder={`Search by ${colProp.label}`}
      />

      <Popover
        content={
          <CheckboxGroupSelelect
            datasource={columnOption}
            checkList={checkedList}
            // visibleChange={visibleChange}
            onChange={(list: any) => {

              if (searchValue === "" && list.search === "" && list.checked === false) {
                setCheckedList([]);
                options.filterApplyCallback(null);
              } else if (searchValue === "" && list.search === "" && list.checked === true) {

                setCheckedList(list.value);
                options.filterApplyCallback({
                  searchText: searchValue,
                  checkedList: list.value,
                });
              } else if (searchValue === "" && list.search !== "" && list.checked === false) {

                setCheckedList([]);
                options.filterApplyCallback({
                  searchText: searchValue,
                  checkedList: list.value,
                });
              } else if (searchValue === "" && list.search !== "" && list.checked === true) {

                setCheckedList(list.value);
                options.filterApplyCallback({
                  searchText: searchValue,
                  checkedList: list.value,
                });
              } else {
                setCheckedList(list.value);
                options.filterApplyCallback({
                  searchText: searchValue,
                  checkedList: list.value,
                });
              }


            }}
          />
        }
        placement="bottom"
        trigger="click"
        onVisibleChange={(e: any) => {
          // setVisibleChange(e);
          if (e === true) {
            const res = filterData(dataSource, filterState, undefined, colProp);
            if (res)
              setSearchValue("");
            setColumnOption([
              ...res
                .map((e) => e[colProp.key]?.toString())
                .filter((e) =>
                  options.value?.searchText
                    ? e.includes(options.value?.searchText)
                    : true
                ),
            ]);
          } else {
            setSearchValue("");
            setColumnOption([]);
          }
        }}
      >
        <Button
          icon={<i className="pi pi-filter" style={{ color: "slateblue" }}></i>}
          type="text"
          shape="circle"
        />
      </Popover>
    </div>
  );
};

export default StringFilterComponent;
