import React, { useEffect, useState } from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { uploadFilePDF } from "../../Services/AttachFileService";
import useAlert from "../../hooks/useAlert";
import Fontkit from "@pdf-lib/fontkit"
import usefont from "./NotoSansJP-Regular.ttf";
import useThaifont from "./Sarabun-Regular.ttf";

interface UploadPdfComponent {
    buttonType: string;
    pdf: string;
    result: Array<{
        type: 'text' | 'freehand';
        text?: string;
        x?: number;
        y?: number;
        page: number;
        ref: React.RefObject<HTMLDivElement>;
        arr?: Array<{ get: (key: string) => number }>;
    }>;
    bounds: { x: number; y: number };
    resetButtonType: () => void;
    memo: any;
    fileName: any;
    setLoading: any;
}

const UploadPdfComponent: React.FC<UploadPdfComponent> = (props) => {

    const fileName = props?.fileName;
    const { toggleAlert } = useAlert();

    useEffect(() => {
        if (props.buttonType === "download") {
            modifyPdf();
            props.resetButtonType();
        }
    }, [props.buttonType]);

    const uint8ArrayToBase64 = (uint8Array: any) => {
        const binaryString = uint8Array.reduce((data: any, byte: any) => data + String.fromCharCode(byte), '');
        return btoa(binaryString);
    }

    const modifyPdf = async () => {
        props.setLoading(true);
        const existingPdfBytes = await fetch(props.pdf).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(Fontkit)
        const fetchfont = await fetch(usefont).then(res => res.arrayBuffer());
        const fetchThaiFont = await fetch(useThaifont).then(res => res.arrayBuffer());
        const font = await pdfDoc.embedFont(fetchfont);
        const fontThai = await pdfDoc.embedFont(fetchThaiFont);
        const pages = pdfDoc.getPages();
        
        props.result.forEach((res: any) => {
            if (res?.type === "text" && res.text) {

                const charCode = res.text.charCodeAt(0);
                let selectedFont;
                if (charCode >= 3585 && charCode <= 3675) {
                    selectedFont = fontThai;
                } else if (
                    (charCode >= 12352 && charCode <= 12543) ||
                    (charCode >= 19968 && charCode <= 40959)
                ) {
                    selectedFont = font;
                }
                const pageHeight = pages[res.page - 1].getHeight();
                // const textY = pageHeight - (res?.ref?.current?.offsetTop - props?.bounds?.y + res?.ref?.current?.offsetHeight + 75);
                const textY = pageHeight - res?.y - props?.bounds?.y + 300;
                const textX = res?.x - props?.bounds.x + 25;
                // const textX = res?.ref?.current?.getBoundingClientRect().left - props?.bounds.x + 25;
                pages[res.page - 1].drawText(res.text, {
                    x: textX,
                    y: textY,
                    size: 16,
                    font: selectedFont,
                    color: rgb(0, 0, 1),
                    // color: rgb(0.95, 0.1, 0.1),
                    // maxWidth: res.ref.current.getBoundingClientRect().width,
                    lineHeight: 15
                });
            }
            if (res?.type === "freehand" && res?.arr) {
                const pathData = "M " +
                    res.arr.map((p: any) => `${p.get('x')},${p.get('y')}`).join(" L ");
                pages[res.page - 1].moveTo(0, pages[0].getHeight());
                pages[res.page - 1].drawSvgPath(pathData, {
                    borderColor: rgb(0, 0, 1),
                    // borderColor: rgb(0.95, 0.1, 0.1),
                    borderWidth: 1,
                });
            }
        });

        const pdfBytes = await pdfDoc.save();
        const toBase64 = uint8ArrayToBase64(pdfBytes);

        const data = {
            MemoId: props.memo.memoid,
            FileName: fileName,
            FileBase64: toBase64,
            connectionString: "",
        }

        const response = await uploadFilePDF(data);
        props.setLoading(false);
        if (response) {
            toggleAlert({
                description: response,
                message: `Upload`,
                type: "success",
            });
        }

        // อย่าลบไว้สำหรับทดสอบ
        // ฟังก์ชันสำหรับโหลดไฟล์ลงเครื่องเพื่อดูความถูกต้องของเส้น SVG ว่าตำแหน่งตรงกับที่วาดมั้ย
        // ถ้าไม่ตรงเป็นเพราะ CSS minwidth ในไฟล์ DrawPdfArea
        // const blob = new Blob([pdfBytes], { type: "application/pdf" });
        // const link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        // const filePDFName = fileName;
        // link.download = filePDFName;
        // link.click();
        // อย่าลบไว้สำหรับทดสอบ
    }

    return (
        <>
            <div></div>
        </>
    );
};

export default UploadPdfComponent;