import { Button } from "primereact/button";
import React, { useState, useEffect, useRef } from "react";
import { TableInputProps } from "../TableComponent/TableComponent";
import { Form as AntForm, Popover } from "antd";
import { Toast } from "primereact/toast";
import "./AttachmentComponent.css";
import { AiOutlineClose } from "react-icons/ai";
import { ConfirmDialog } from "primereact/confirmdialog";
import ComponentLabel from "../../ControlComponents/ComponentLabel";
import { Col } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { AttachmentModal } from "./AttachmentModal";
import { checkRequireFromTemplate } from "../../../Helper/RequestScreenHelper";
import { getDataLocal } from "../../../Helper/localStoreFunction";
import { LocalStoreType } from "../../../constants/local-type";
import { browserDetector } from "../../../Helper/DeviceDetect";
import { Device } from "../../../constants/device-type";

type Props = {
  template: any;
  data: any;
  rowIdx: number;
  colIdx: number;
  col?: any;
  colText?: number;
  colAction?: number;
  // statusMemoDetail?: boolean;
  name: string;
  control: any;
  canEditDoc: boolean;
  checkActionPage: string;
  buttonType: string;
};

type InputAttachmentProps = {
  ref?: any;
  rowIdx?: any;
  colIdx?: any;
  template: any;
  value?: any;
  status?: any;
  onChange: (value: any) => void;
  canEditDoc: boolean;
  checkActionPage: string;
  id?: string;
};

export const AttachmentComponent: React.FC<InputAttachmentProps> = ({
  ref,
  rowIdx,
  colIdx,
  template,
  value,
  status,
  onChange,
  id,
  canEditDoc,
  checkActionPage,
  ...props
}: InputAttachmentProps) => {
  const toast = useRef<any>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [checkHasValue, setCheckHasValue] = useState<boolean>(false);
  const [link, setLink] = useState<any>();
  const [nameFile, setNameFile] = useState<string>("");
  const _userData = JSON.parse(getDataLocal(LocalStoreType.userData) || "");
  const [visibleConfirmDialog, setVisibleConfirmDialog] =
    useState<boolean>(false);
  const [selected, setSelected] = useState<any>();
  const [uploadType, setUploadType] = useState<string>("");
  const _sharepointSiteURL = _userData.SharepointSiteURL;

  useEffect(() => {
    deFaultValue();
    
  }, [value]);

  const deFaultValue = async () => {
    try {
      
      const def = template.attribute.default || "";

      if (!value && !def) {
        return;
      }
      if (value === "" && def === "") {
        return;
      }
      if(value === null || !!value){
        const [nameFile, linkFile] = value?.split("|") || def?.split("|");
        const linkUrl = linkFile;
  
        setLink(linkUrl);
        setNameFile(nameFile);
        setCheckHasValue(true);
      }
      if(value === ""){
        setLink("");
        setNameFile("");
        setCheckHasValue(false);
      }

    } catch (error) {
      console.error(error);
    }
  };

  // function openWindow() {
  // var pathArray = link.split("/");
  // console.log("at=>link", link);
  // console.log("at=>pathArray", pathArray);
  //   console.log({ link });
  //   const _baseUrl = window.location.hostname;

  //   const TmpUrl =
  //     process.env.NODE_ENV === "development"
  //       ? "qar2.wolfapprove.com"
  //       : _baseUrl;
  //   console.log({ ddd: `https://${TmpUrl}/${link}` });

  //   window.open(`https://${TmpUrl}/${link}`, "_blank", "noreferrer");
  // }
 async function openWindow() {
    const pathArray = link.split("/");
    const _link = link
        ? link.charAt(0) !== "/" && !_sharepointSiteURL
            ? "/" + link
            : link
        : "";

    const protocol = window.location.protocol;
    const browser = browserDetector();

    if (_sharepointSiteURL) {
        if (_link.startsWith(_sharepointSiteURL)) {
            console.log("att=>", _link);
            if (browser === Device.safari) {
                window.location.href = `${_link}`;
            } else {
                window.open(`${_link}`, "_blank", "noreferrer");
            }
        } else {
            console.log("att=>", `${_sharepointSiteURL}${_link}`);
            if (browser === Device.safari) {
                window.location.href = `${_sharepointSiteURL}${_link}`;
            } else {
                window.open(`${_sharepointSiteURL}${_link}`, "_blank", "noreferrer");
            }
        }
    } else if (_userData.TinyURL) {
        if (location.host !== "localhost:5001") {
            const _port = location.port;
            if (_port) {
                _userData.TinyURL = _userData.TinyURL + ":" + _port;
            }
        }

        if (
            _link.includes("www") ||
            _link.includes(".com") ||
            _link.includes(".tv") ||
            _link.includes(".net") ||
            _link.includes("https") ||
            _link.includes("http") ||
            _link.includes(".com") ||
            _link.includes(".co.th")
        ) {
            const newLink = _link.replace(/^https?:\/\//, "");
            const fullLink = _link.startsWith("https")
                ? `https://${newLink}`
                : `http://${newLink}`;
            if (browser === Device.safari) {
                  window.location.href = fullLink;
            } else {
                window.open(fullLink, "_blank", "noreferrer");
            }
        } else {
            const fullLink = `${protocol}//${_userData.TinyURL}${_link}`;
            if (browser === Device.safari) {
                window.location.href = fullLink;
            } else {
                window.open(fullLink, "_blank", "noreferrer");
            }
        }
    } else if (!_userData.TinyURL) {
        if (browser === Device.safari) {
            window.location.href = `${_link}`;
        } else {
            window.open(`${_link}`, "_blank", "noreferrer");
        }
    }
}


  const confirmUploadDialog = () => {
    return (
      <>
        <ConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          message="Are you sure you want to proceed?"
          header="Confirmation"
          icon="pi pi-exclamation-triangle"
          position="top"
          accept={acceptUpload}
          acceptClassName="p-button p-component p-button-raised p-button-success"
          rejectClassName="p-button p-component p-button-outlined p-button-danger"
          draggable={false}
        />
      </>
    );
  };

  const acceptUpload = () => {
    
    if (uploadType === "Attach file") {
      setCheckHasValue(true);
      setIsModalVisible(false);
    } else if (uploadType === "Attach link") {
      setCheckHasValue(true);
      setIsModalVisible(false);
    } else {
      setCheckHasValue(false);
      setSelected(undefined);
      setLink("");
      setNameFile("");
      setUploadType("Attach file");
      onChange("");
      setIsModalVisible(false);
    }
  };
  const isButtonDisable = !(checkHasValue || canEditDoc )||template.attribute.readonly === "Y"
  return (
    <>
      <Toast ref={toast}></Toast>
      {confirmUploadDialog()}
      <div className="set-button-close" style={{cursor : isButtonDisable ?"not-allowed" : "default"}}>
        <Popover placement="bottom" content={nameFile}>
          <Button
            // fixed issue readonly
            //  id
            id={rowIdx + "_" + colIdx + "_" + template.label}
            label={
              checkHasValue
                ? nameFile.length <= 20
                  ? nameFile
                  : nameFile.substring(0, 20) + " ..."
                : "Upload"
            }
            onClick={() => {
              if (checkHasValue) {
                openWindow();
              } else if (canEditDoc) {
                setIsModalVisible(true);
              }
            }}
            className="r"
            type="button"
            style={{
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
              borderTopRightRadius:
                checkHasValue && canEditDoc && template.attribute.readonly
                  ? undefined
                  : "6px",
              borderBottomRightRadius:
                checkHasValue && canEditDoc && template.attribute.readonly
                  ? undefined
                  : "6px",
              backgroundColor: "rgb(40, 47, 106)",
              border: "1px solid rgb(40, 47, 106)",
              boxShadow: "none",
              width: "100%",
              height: "38px",
              // cursor: checkHasValue || canEditDoc ? "pointer" : "default",
              // opacity: checkHasValue && canEditDoc ? 1 : 0.6,
            }}
            disabled={isButtonDisable}
          />
        </Popover>

        {checkHasValue && canEditDoc &&(
          <div
            className="border-icon"
            style={{
              display: status ? "none" : "",
            }}
            onClick={() => {
              setUploadType("Clear");

              setVisibleConfirmDialog(true);
            }}
          >
            <AiOutlineClose />
          </div>
        )}
      </div>
      <AttachmentModal
        visibleDialog={isModalVisible}
        setVisibleDialog={setIsModalVisible}
        setCheckHasValue={setCheckHasValue}
        toast={toast}
        template={template}
        onChange={onChange}
        nameFile={nameFile}
        setNameFile={setNameFile}
        link={link}
        setLink={setLink}
        SharepointSiteURL={_userData.SharepointSiteURL}
        TinyURL={_userData.TinyURL}
        selected={selected}
        setSelected={setSelected}
        visibleConfirmDialog={visibleConfirmDialog}
        setVisibleConfirmDialog={setVisibleConfirmDialog}
        value={uploadType}
        setValue={setUploadType}
      />
    </>
  );
};

export const AttachmentControlComponent: React.FC<Props> = ({
  colIdx,
  rowIdx,
  control,
  name,
  data,
  template,
  col,
  colAction,
  colText,
  canEditDoc,
  checkActionPage,
  buttonType,
  ...props
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          required:
            canEditDoc && checkRequireFromTemplate(template, buttonType) && `${template.label}`,
        }}
        render={({
          field: { onChange, value, onBlur, name, ref },
          formState: { errors, isSubmitted },
        }) => (
          <>
            <ComponentLabel
              // renderInTable={props.renderInTable}
              col={col}
              colText={colText}
              rowIdx={rowIdx}
              colIdx={rowIdx}
              template={template}
            />
            <Col
              sm={col === undefined ? 12 : 12}
              md={col === undefined ? colAction : 12}
              xs={col === undefined ? 12 : 12}
              xl={col === undefined ? colAction : 12}
              className={"padding-controller"}
            >
              <div className={`input-component-container`}>
                <AttachmentComponent
                  {...{ checkActionPage }}
                  canEditDoc={canEditDoc}
                  template={template}
                  onChange={onChange}
                  value={value}
                  status={
                    isSubmitted &&
                    errors?.items &&
                    errors?.items[rowIdx] &&
                    errors?.items[rowIdx].layout[colIdx] &&
                    "error"
                  }
                />
                {isSubmitted &&
                  errors?.items &&
                  errors?.items[rowIdx] &&
                  errors?.items[rowIdx].layout[colIdx] && (
                    <small id="Name-help" className="p-error p-d-block">
                      {template.label} is required.
                    </small>
                  )}
              </div>
            </Col>
          </>
        )}
      />
    </>
  );
};

export const AttachmentTableComponent: React.FC<TableInputProps> = ({
  name,
  template,
  saveFunc,
  inputRef,
  isEditing,
  onEdit,
  value,
  children,
  canEditDoc,
  checkActionPage,
  buttonType,

  ...props
}) => {
  if (!isEditing) {
    return (
      <div style={{ width: "100%" }} onMouseEnter={onEdit}>
        <AttachmentComponent
          {...{ checkActionPage }}
          canEditDoc={canEditDoc}
          template={template}
          onChange={saveFunc}
          value={children[1]}
        />
      </div>
    );
  }
  return (
    <>
      <AntForm.Item
        style={{ margin: 0 }}
        name={name}
        normalize={(value: any) => value}
        rules={
          canEditDoc && [
            {
              required: checkRequireFromTemplate(template, buttonType),
              message: `${template.label} is required.`,
            },
          ]
        }
      >
        <AttachmentComponent
          {...{ checkActionPage }}
          canEditDoc={canEditDoc}
          template={template}
          onChange={saveFunc}
          value={value}
        />
      </AntForm.Item>
    </>
  );
};
