import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { IoSaveOutline } from "react-icons/io5";
import useAlert from "../../../hooks/useAlert";
import { Calendar, Col, InputNumber, Row } from "antd";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Controller, useForm } from "react-hook-form";
import AutoCompleteComponents from "../../AutoCompleteComponents/AutoCompleteComponents";
import moment from "moment";
import { RadioButtonComponents } from "../../RadioButtonComponents/RadioButtonComponents";
import { useUserContext } from "../../../Context/UserContext";
import { GetAllDynamic } from "../../../Services/DynamicService";
import { ReportTemplateSelectByReportID } from "../../../Services/ReportService";

interface Props {
  setViewModal: any;
  viewModal: any;
  setAttributeButton: any;
  attributeButton: any;
}

export const AddQueryComponent = (props: Props) => {
  const toast = useRef<any>(null);
  const { toggleAlert } = useAlert();
  const [userData] = useUserContext();
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState<any>({
    name: "Document No",
  });
  const [filterValueSelected, setFilterValueSelected] = useState<any>([]);
  const [selectedField, setSelectedField] = useState<any[]>([]);
  const [selectedReport, setSelectedReport] = useState<number>(-1);
  const [repostList, setReportList] = useState<any[]>([]);
  const [reportLoading, setReportLoading] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<any>([]);
  const [templateFieldMulti, setTemplateFieldMulti] = useState<any>([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });
  const fecthReportList = async () => {
    const _dataDynamic: any[] = await GetAllDynamic("DynamicReport/GetAll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        EmployeeId: userData?.EmployeeId.toString(),
        UserPrincipalName: userData.Email,
      }),
    });
    setReportList([..._dataDynamic]);
  };
  function onChangeDropdownSelectForm(e: any, editReport?: any) {
    let _dataFilter: any;
    let _dataArrya: any = [];

    let dataFilterValueSelected: any = filterValueSelected;

    e.map((_data: any) => {
      let _objJson = {
        ID: 0,
        FieldCode: _data.key,
        FieldDisplay: _data.label,
        IsExcludeBlankData: true,
        FieldType: _data.type,
        IsEquals: false,
        FieldText: [],
        FieldTextFrom: null,
        FieldTextTo: null,
        FieldBit: null,
        FieldTypeFilterStatic: _data.FieldTypeFilterStatic,
        FieldTypeFilterDynamic: _data.FieldTypeFilterDynamic,
        IsTodayFrom: false,
        IsTodayTo: false,
        FilterParameter: "",
        indexHideColumn: null,
        SecretId: null,
      };
      if (dataFilterValueSelected.length !== 0) {
        let FilterValueSelected = dataFilterValueSelected.filter(
          (item: any) => item.FieldCode === _objJson.FieldCode
        );
        if (FilterValueSelected.length === 0) {
          dataFilterValueSelected.push(_objJson);
        } else {
          for (let i = 0; i < dataFilterValueSelected.length; i++) {
            const filter = dataFilterValueSelected[i];
            if (filter.FieldCode === FilterValueSelected[0].FieldCode) {
              dataFilterValueSelected[i] = filter;
            }
          }
        }
      } else {
        dataFilterValueSelected.push(_objJson);
      }
      onChangeFilterValue(dataFilterValueSelected);

      setFilterValueSelected([...dataFilterValueSelected]);
    });
    function onChangeFilterValue(e: any) {
      setFilterValue(e);
    }

    if (e.length !== filterValueSelected.length) {
      e.map((_data: any) => {
        _dataFilter = filterValueSelected.filter(
          (item: any) => _data.key === item.FieldCode
        );
        if (_dataFilter.length !== 0) {
          _dataArrya.push(_dataFilter[0]);
        }
      });
      console.log("dataFilterValueSelected", _dataArrya);
      setFilterValueSelected([..._dataArrya]);
    }

    setSelectedField(e);
  }
  async function fetchGetReportById(reportId: number) {
    setReportLoading(true);

    if (reportId !== -1) {
      let _ReportById = await ReportTemplateSelectByReportID(reportId);
      setTemplateFieldMulti([..._ReportById?.Selectedfieldlist]);
    }
    setSelectedReport(reportId ?? -1);

    setReportLoading(false);
  }

  function onSaveQuery() {
    if (!inputValue || !selectedValue?.name) {
      toggleAlert({
        description: `Please fill in all required fields.`,
        message: `Require field warning.`,
        type: "warning",
      });

      return;
    }
    let _items = props.attributeButton.items;
    const dataRequest = {
      layout: [
        {
          paramiter: {
            value: inputValue,
          },
          control: {
            label: selectedValue.name,
          },
          reportFilter: filterValueSelected.map(
            (filter: { FilterParameter: any; FieldText: any[] }) => {
              return {
                FilterParameter: filter.FilterParameter,
                labels: filter.FieldText.map(
                  (e: { FieldText: any }) => e.FieldText
                ),
              };
            }
          ),
        },
      ],
    };

    _items.push(dataRequest);
    props.setAttributeButton((prevState: any) => ({
      ...prevState,
      items: [..._items],
    }));
    props.setViewModal(false);
  }
  const footer = (
    <div>
      <button
        type="button"
        onClick={() => {
          onSaveQuery();
        }}
        className="hover-color-css-blue set-css-button-save-in-modal"
      >
        <IoSaveOutline /> Save
      </button>
    </div>
  );
  function formatDate(date: string) {
    let arrDate = date.split("/");
    const mm = Number(arrDate[1]);
    if (mm === 1) {
      arrDate[1] = "Jan";
    } else if (mm === 2) {
      arrDate[1] = "Feb";
    } else if (mm === 3) {
      arrDate[1] = "Mar";
    } else if (mm === 4) {
      arrDate[1] = "Apr";
    } else if (mm === 5) {
      arrDate[1] = "May";
    } else if (mm === 6) {
      arrDate[1] = "Jun";
    } else if (mm === 7) {
      arrDate[1] = "Jul";
    } else if (mm === 8) {
      arrDate[1] = "Aug";
    } else if (mm === 9) {
      arrDate[1] = "Sep";
    } else if (mm === 10) {
      arrDate[1] = "Oct";
    } else if (mm === 11) {
      arrDate[1] = "Nov";
    } else if (mm === 12) {
      arrDate[1] = "Dec";
    }
    return arrDate.join(" ");
  }

  const searchTextEnter = (event: any, key: any, rowData: any) => {
    try {
      if (event.length < 1) return;
      let _rowData: any = rowData;

      let _filterValueSelected = filterValueSelected;
      _rowData.FieldText.push({ FieldText: event });
      _filterValueSelected.map((_data: any) => {
        if (_data.FieldCode === rowData.FieldCode) {
          _data.FieldText = _rowData.FieldText;
        }
      });
      setFilterValueSelected([..._filterValueSelected]);
    } catch (error) {
      console.error(error);
    }
  };

  const _AutoCompleteText = (data: any, key: any, rowData: any) => {
    let _filterValueSelected = filterValueSelected;

    for (let i = 0; i < _filterValueSelected.length; i++) {
      const element = _filterValueSelected[i];
      if (rowData.FieldCode === element.FieldCode) {
        rowData.FieldText = [];

        _filterValueSelected[i].FieldText = data;
      }
    }
    setFilterValueSelected([..._filterValueSelected]);
  };

  function onChangeDropdownfilterValue(e: any, data: any) {
    let _FilterValueSelected = filterValueSelected;
    let _dataFilter = _FilterValueSelected.filter(
      (item: any) => item.FieldCode === e.FieldCode
    );
    if (_dataFilter.length !== 0) {
      for (let index = 0; index < _FilterValueSelected.length; index++) {
        const element = _FilterValueSelected[index].FieldCode;
        if (_dataFilter[0].FieldCode === element) {
          _FilterValueSelected[index] = {
            ..._dataFilter[0],

            FilterParameter:
              data.Filter === "Contains"
                ? "Like"
                : data.Filter === "Not Contains"
                ? "Not Like"
                : data.Filter,
          };
        }
      }
    }

    setFilterValueSelected([..._FilterValueSelected]);
  }

  return (
    <>
      <Dialog
        header="Button"
        visible={props.viewModal}
        style={{
          width: "70.20833333333333vw",
          height: "43.5vw",
          borderRadius: "16px",
        }}
        onHide={() => props.setViewModal(false)}
        breakpoints={{ "960px": "75vw" }}
        blockScroll
        draggable={false}
        resizable={false}
        footer={footer}
        baseZIndex={2}
      >
        <div className="set-card-add-control set-css-layout-table-condition-css-padding">
          <div className="row set-margin-in-row-add-control">
            <div className="col-lg-2">
              <tr>
                <th>
                  <div className="label-text-container">
                    <span className="headtext-form">Parameter</span>

                    <span className="headtext-form text-Is-require">*</span>
                  </div>
                  <p className="subtext-form">ตัวแปร</p>
                </th>
              </tr>
            </div>
            <div className="col-lg-10">
              <InputText
                className="set-input-component-css"
                value={inputValue}
                disabled={selectedValue?.name === "Report"}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
          </div>
          <div className="row set-margin-in-row-add-control">
            <div className="col-lg-2">
              <tr>
                <th>
                  <div className="label-text-container">
                    <span className="headtext-form">Control</span>

                    <span className="headtext-form text-Is-require">*</span>
                  </div>
                  <p className="subtext-form">ควบคุม</p>
                </th>
              </tr>
            </div>
            <div className="col-lg-10">
              <Dropdown
                className="set-dnd-revision-css"
                value={selectedValue}
                options={[{ name: "Document No" }, { name: "Report" }]}
                onChange={(e: any) => {
                  if (e.value.name === "Report") {
                    fecthReportList();
                    setInputValue("reportfilter");
                  } else {
                    setInputValue("");
                  }

                  setSelectedValue(e.value);
                }}
                optionLabel="name"
                placeholder="--Select--"
              />
            </div>
          </div>
          {selectedValue.name === "Report" && (
            <div className="row set-margin-in-row-add-control">
              <div className="col-lg-2">
                <tr>
                  <th>
                    <div className="label-text-container">
                      <span className="headtext-form">Report</span>

                      <span className="headtext-form text-Is-require">*</span>
                    </div>
                    <p className="subtext-form">ชื่อรายงาน</p>
                  </th>
                </tr>
              </div>
              <div className="col-lg-10">
                <Dropdown
                  className="set-dnd-revision-css"
                  value={selectedReport}
                  options={repostList}
                  showClear
                  onChange={(e: any) => {
                    fetchGetReportById(e.value);
                  }}
                  optionLabel="ReportName"
                  optionValue="ReportTemplateId"
                  placeholder="--Select--"
                />
              </div>
            </div>
          )}
          {selectedReport !== -1 && (
            <Row justify="space-between" gutter={10}>
              <Col span={12}>
                <div className="report-card">
                  <div className="report-card-header">
                    <p className="card-header-text">Selected Field</p>
                  </div>
                  <div
                    className="report-card-body"
                    style={{ height: "550px", overflow: "auto" }}
                  >
                    <DataTable
                      value={templateFieldMulti}
                      responsiveLayout="scroll"
                      selection={selectedField}
                      scrollable
                      loading={reportLoading}
                      onSelectionChange={(e) => {
                        onChangeDropdownSelectForm(e.value);
                      }}
                      scrollHeight="flex"
                      size="small"
                    >
                      <Column
                        rowReorder
                        style={{ flexGrow: 1, flexBasis: "40px" }}
                      />
                      <Column
                        header={
                          <>
                            <p
                              style={{
                                marginRight: "290px",
                                marginBottom: "0",
                              }}
                            >
                              Field
                            </p>
                          </>
                        }
                        field="label"
                        style={{ flexGrow: 1, flexBasis: "500px" }}
                      ></Column>
                      <Column
                        selectionMode="multiple"
                        style={{ flexGrow: 1, flexBasis: "40px" }}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                {selectedField.length > 0 && (
                  <>
                    <div className="report-card">
                      <div className="report-card-header">
                        <p className="card-header-text">Filter Value</p>
                      </div>
                      <div
                        className="report-card-body"
                        style={{ height: "550px", overflow: "auto" }}
                      >
                        <DataTable
                          value={filterValueSelected}
                          // onRowReorder={onRowReorderFilter}
                          selection={filterValue}
                          // onSelectionChange={(e: any) =>
                          //   // onChangeFilterValue(e.value)
                          // }
                          size="small"
                          scrollable
                          scrollHeight="flex"
                          responsiveLayout="scroll"
                        >
                          <Column
                            rowReorder
                            style={{ flexGrow: 1, flexBasis: "40px" }}
                          />
                          <Column
                            header={
                              <>
                                <p
                                  style={{
                                    marginRight: "79px",
                                    marginBottom: "0",
                                  }}
                                >
                                  Field
                                </p>
                              </>
                            }
                            field="FieldDisplay"
                            style={{ flexGrow: 1, flexBasis: "110px" }}
                          ></Column>
                          <Column
                            style={{ flexGrow: 1, flexBasis: "200px" }}
                            body={(rowData: any) => {
                              if (
                                rowData.FieldTypeFilterDynamic === "d" ||
                                rowData.FieldTypeFilterStatic === "Datetime"
                              ) {
                                return (
                                  <>
                                    {filterValueSelected !== undefined ? (
                                      filterValueSelected.length !== 0 ? (
                                        filterValueSelected.map((data: any) => {
                                          let dateFormat: any =
                                            rowData.FieldTextFrom !==
                                              undefined &&
                                            rowData.FieldTextFrom !== null &&
                                            rowData.FieldTextFrom.length !== 0
                                              ? moment(
                                                  moment(
                                                    formatDate(
                                                      rowData.FieldTextFrom
                                                    )
                                                  ).format("DD MMM YYYY"),
                                                  "DD MMM YYYY"
                                                ).toDate()
                                              : "";

                                          if (
                                            data.FieldCode === rowData.FieldCode
                                          ) {
                                            return (
                                              <>
                                                <p>
                                                  {"From("}
                                                  {"Today"} {")"}
                                                </p>
                                              </>
                                            );
                                          }
                                        })
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              }
                              let _dataFilter: any = [];
                              if (filterValueSelected.length !== 0) {
                                _dataFilter = filterValueSelected.filter(
                                  (item: any) =>
                                    rowData.FieldCode === item.FieldCode
                                );
                              }
                              if (
                                rowData.FieldTypeFilterDynamic !== "d" &&
                                rowData.FieldTypeFilterStatic !== "Datetime"
                              ) {
                                return (
                                  <>
                                    <Dropdown
                                      value={
                                        _dataFilter.length !== 0
                                          ? _dataFilter[0].IsEquals === true
                                            ? { Filter: "Equals" }
                                            : {
                                                Filter:
                                                  _dataFilter[0]
                                                    .FilterParameter === "Like"
                                                    ? "Contains"
                                                    : _dataFilter[0]
                                                        .FilterParameter ===
                                                      "Not Like"
                                                    ? "Not Contains"
                                                    : _dataFilter[0]
                                                        .FilterParameter,
                                              }
                                          : []
                                      }
                                      id={"Template_version"}
                                      placeholder="--- Please select ---"
                                      optionLabel="Filter"
                                      className="report-input"
                                      options={[
                                        { Filter: "Equals" },
                                        { Filter: "Not Equals" },
                                        { Filter: "Contains" },
                                        { Filter: "Not Contains" },
                                      ]}
                                      onChange={(e: any) => {
                                        onChangeDropdownfilterValue(
                                          rowData,
                                          e.value
                                        );
                                      }}
                                    />
                                  </>
                                );
                              }
                            }}
                          ></Column>
                          <Column
                            style={{ flexGrow: 1, flexBasis: "200px" }}
                            body={(rowData: any) => {
                              if (
                                rowData.FieldTypeFilterDynamic === "d" ||
                                rowData.FieldTypeFilterStatic === "Datetime"
                              ) {
                                return (
                                  <>
                                    {filterValueSelected !== undefined ? (
                                      filterValueSelected.length !== 0 ? (
                                        filterValueSelected.map((data: any) => {
                                          let dateFormat: any =
                                            rowData.FieldTextTo !== undefined &&
                                            rowData.FieldTextTo !== null &&
                                            rowData.FieldTextTo.length !== 0
                                              ? moment(
                                                  moment(
                                                    formatDate(
                                                      rowData.FieldTextTo
                                                    )
                                                  ).format("DD MMM YYYY"),
                                                  "DD MMM YYYY"
                                                ).toDate()
                                              : "";

                                          if (
                                            data.FieldCode === rowData.FieldCode
                                          ) {
                                            return (
                                              <>
                                                <p>
                                                  To (
                                                  {/* <RadioButtonComponents
                                                    inputIdProps={true}
                                                    nameProps={true}
                                                    valueProps={
                                                      false
                                                    }
                                                    
                                                    checkedProps={
                                                      rowData.IsTodayTo === true
                                                    }
                                                    keyProps={"Today"}
                                                    labelProps={""}
                                                  /> */}
                                                  Today )
                                                </p>
                                              </>
                                            );
                                          }
                                        })
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    {filterValueSelected !== undefined ? (
                                      filterValueSelected.length !== 0 ? (
                                        filterValueSelected.map((data: any) => {
                                          if (
                                            data.FieldCode === rowData.FieldCode
                                          ) {
                                            return (
                                              <>
                                                <AutoCompleteComponents
                                                  onChangeProps={(e: any) =>
                                                    _AutoCompleteText(
                                                      e,
                                                      "FieldText",
                                                      rowData
                                                    )
                                                  }
                                                  onBlur={(e: any) =>
                                                    searchTextEnter(
                                                      e,
                                                      "FieldText",
                                                      rowData
                                                    )
                                                  }
                                                  onKeyPress={(e: any) => {
                                                    searchTextEnter(
                                                      e,
                                                      "FieldText",
                                                      rowData
                                                    );
                                                  }}
                                                  fieldProps="FieldText"
                                                  valueProps={
                                                    rowData.FieldText !==
                                                      undefined &&
                                                    rowData.FieldText.length !==
                                                      0 &&
                                                    typeof rowData.FieldText ===
                                                      "object"
                                                      ? rowData.FieldText
                                                      : []
                                                  }
                                                  keyProps={"FieldText"}
                                                  styleProps={{
                                                    height:
                                                      rowData?.FieldText !==
                                                      undefined
                                                        ? rowData?.FieldText
                                                            .length === 0
                                                          ? "38px"
                                                          : "100%"
                                                        : "38px",
                                                    width: "100%",
                                                    borderRadius:
                                                      "6px 6px 6px 6px",
                                                  }}
                                                />

                                                {/* <InputText
                                                      id={"Select_Form"}
                                                      className="report-input"
                                                    /> */}
                                              </>
                                            );
                                          }
                                        })
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );

                                //   <>
                                //     <InputText
                                //       id={"Select_Form"}
                                //       className="report-input"
                                //     />
                                //   </>
                                // );
                              }
                            }}
                          ></Column>
                        </DataTable>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          )}
        </div>

        <Toast ref={toast} />
      </Dialog>
    </>
  );
};
