import moment from "moment";
import { GetAllDynamic, updateDynamic } from "../Services/DynamicService";
import { ColumnsProps } from "../components/TableComponents/DynamicTableV2/DynamicTable";

export const exportExcel = (
  columnHeader: { label: string; key: string }[],
  fileName: string,
  data: any[],
  setLoad?: (val: boolean) => void
) => {
  const excelData = data.map((item) => {
    const rowData: any = {};
    columnHeader.forEach(({ label, key }) => {
      rowData[label] = item[key] || "";
    });
    return rowData;
  });

  import("xlsx").then((xlsx: any) => {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    if (setLoad) {
      setLoad(true);
    }

    saveAsExcelFile(
      excelBuffer,
      `${fileName} : ${moment(new Date()).format("DD MMM YYYY")}`
    );

    if (setLoad) {
      setLoad(false);
    }
  });
};

export function reconStructionForExport(
  datasource: any[],
  columnsProps: ColumnsProps[]
) {
  let dataExport: any[] = [];
  try {
    datasource.forEach((currentData) => {
      let exportRow = new Map<string, any>();

      columnsProps.forEach((col: ColumnsProps) => {
        if (!currentData[col.key]) return "";
        const value = currentData[col.key];
        if (col.type === "date" || col.type === "datetime") {
          exportRow.set(col.label, value?.format(col.dateFormat) ?? "");
        } else if (col.type === "number") {
          exportRow.set(col.label, Number(value));
        } else {
          exportRow.set(col.label, value);
        }
      });

      if (exportRow.size > 0) {
        dataExport.push(exportRow);
      }
    });
  } catch (error) {
    console.error("table=>error", error);
  }
  const excelData = dataExport.map((mapRow) => {
    const orderedRow: Record<string, any> = {};
    columnsProps.forEach((col) => {
      orderedRow[col.label] = mapRow.get(col.label) ?? "";
    });
    return orderedRow;
  });

  excelData.forEach((obj) => {
    delete obj[""];
  });

  return excelData;
}

const saveAsExcelFile = async (buffer: any, fileName: string) => {
  const { saveAs } = await import("file-saver");

  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const EXCEL_EXTENSION = ".xlsx";

  const data = new Blob([buffer], { type: EXCEL_TYPE });
  saveAs(data, `${fileName}_export_${EXCEL_EXTENSION}`);
};

export const importExcel = async (e: any, apiName: string) => {
  try {
    const file = e.target.files[0];

    return import("xlsx").then((xlsx) => {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const wb = xlsx.read(e.target.result, { type: "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
        const cols: any = data[0];
        data.shift();
        let _importedData = data.map((d: any) => {
          return cols.reduce((obj: any, c: any, i: any) => {
            obj[c] = d[i];
            return obj;
          }, {});
        });
        console.log("table=>_importedData", _importedData);

        if (_importedData.length !== 0) {
          for (let i = 0; i < _importedData.length; i++) {
            const element = _importedData[i];
            var apiNamereplace = apiName?.replace("/GetAll", "");
            let res = await updateDynamic(apiNamereplace, element);
            if (res.result === "success") {
              let _dataDynamic = await GetAllDynamic(apiName, undefined);
              return { respone: true, data: _dataDynamic };
            } else {
              return { respone: false };
            }
          }
        } else {
          return { respone: false };
        }
      };
      reader.readAsArrayBuffer(file);
    });
  } catch (error: any) {
    return { respone: false, data: error };
  }
};
