import moment from "moment";

export const sorterFunc = (
  a: any,
  b: any,
  key: string,
  type: "dec" | "asc" | null,
  filterType: string,
  dateFormat?: string | null
) => {
  try {
    let nameA: any;
    let nameB: any;

    if (filterType === "date") {
      nameA = a[key] || a[key] !== "" ? new Date(dateFormat != null ? moment(a[key], dateFormat).format("DD MMM YYYY") : a[key]).getTime() : "";
      nameB = b[key] || b[key] !== "" ? new Date(dateFormat != null ? moment(b[key], dateFormat).format("DD MMM YYYY") : b[key]).getTime() : "";
      if (type === "asc") {
        return nameA - nameB;
      } else {
        return nameB - nameA;
      }
    } else {
      if (typeof a[key] !== "string") {
        return 0;
      }

      if (!isNaN(parseFloat(a[key].replace(/,/g, '')))) {
        nameA = Number(a[key]?.replace(/,/g, ''));
        nameB = Number(b[key]?.replace(/,/g, ''));
        return type === "asc" ? nameA - nameB : nameB - nameA;
      } else {
        nameA = a[key]?.toString().toLowerCase();
        nameB = b[key]?.toString().toLowerCase();
      
        if (nameA === nameB) return 0;
      
        return type === "asc" ? (nameA > nameB ? 1 : -1) : (nameA > nameB ? -1 : 1);
      }
      
    }
  } catch (error) {
    console.error("helper=>sorterFunc=>error", error);
    return 0;
  }
};
