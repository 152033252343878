import { createContext, ReactNode, useRef, useState } from "react";
import React from "react";
import { v4 as uuidv4 } from "uuid";

interface PDFEditorProviderProps {
  children: ReactNode;
}

export interface ILayoutTemplate {
  action: string;
  label: string;
  type: string;
  count: number;
  layoutType: string;
  value: string;
  attribute?: IControlProperty | ILayoutProperty;
}

export interface ILayoutProperty {
  objectFit: "contain" | "cover" | "fill";
  backgroundImage: string | null;
}

export interface IControlProperty {
  backgroundSize: "contain" | "cover" | "fill";
  default: string;
  fontSize: number;
  isItalic: boolean;
  isUnderlined: boolean;
  isBold: boolean;
  color: string;
  hAlign: string;
  vAlign: string;
  urlLink: string;
  fontFamily: "Arial";
  hideLabel?: boolean;
  columsWidth?: number[];
  column?: ILayoutTemplate[];
}

export interface IControlLayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  static: boolean;
  minW?: number;
  minH?: number;
  isDraggable?: boolean;
  isResizable?: boolean;
  moved?: boolean;
  type: "header" | "body" | "footer" | "content";
  template: ILayoutTemplate;
  children?: IControlLayout[];
}

export interface IPageProperty {
  doctype: "A4" | "Letter" | "custom";
  isDragingFromOutSide: boolean;
  gridSize: number;
  padding: number;
  dragingType?: "header" | "body" | "footer" | "content";
  pageAmount: number;
  pageSize: { width: number; height: number };
}

export interface IPaperLayout {
  header: IControlLayout;
  body: IControlLayout;
  foot: IControlLayout;
}

interface IPDFContextType {
  paperLayout: IControlLayout[];
  pageProperty: IPageProperty;
  setPaperLayout: (panelLayout: IControlLayout[]) => void;
  addLayout: (
    parent: "header" | "body" | "footer" | "content",
    item: any,
    template: ILayoutTemplate
  ) => void;
  updatePageProperties: (params: Partial<IPageProperty>) => void;
  refHtml: React.MutableRefObject<HTMLDivElement | null>;
  selectionBlock?: IControlLayout;
  onSelectChange: (template: IControlLayout) => void;
  onSelectPropertyChange: (props: IControlProperty) => void;
  onDeselected: () => void;
  removeSelectedChild: (parent: string) => void;
  changeDocType: (docType: IPageProperty["doctype"]) => void;
}

const PDFEditorContext = createContext<IPDFContextType | null>(null);

const PDFEditorProvider: React.FC<PDFEditorProviderProps> = (props) => {
  const papers: { [name: string]: IPageProperty["pageSize"] } = {
    Letter: {
      width: 816,
      height: 1056,
    },
    A4: {
      width: 794,
      height: 1123,
    },
  };
  const [paperLayout, setPaperLayout] = useState<IControlLayout[]>([
    {
      i: "header",
      x: 0,
      y: 0,
      w: 12,
      h: 4,
      static: false,
      minW: 12,
      type: "header",
      template: {
        label: "header",
        type: "header",
        action: "",
        count: 0,
        layoutType: "",
        value: "",
        attribute: { backgroundImage: "", objectFit: "contain" },
      },
      children: [],
    },
    {
      i: "body",
      x: 0,
      y: 4,
      w: 12,
      h: 4,
      static: false,
      minW: 12,
      type: "body",
      template: {
        label: "body",
        type: "body",
        action: "",
        count: 0,
        layoutType: "",
        value: "",
        attribute: { backgroundImage: "", objectFit: "contain" },
      },
      children: [],
    },
    {
      i: "footer",
      x: 0,
      y: 8,
      w: 12,
      h: 4,
      static: false,
      minW: 12,
      type: "footer",
      template: {
        label: "footer",
        type: "footer",
        action: "",
        count: 0,
        layoutType: "",
        value: "",
        attribute: { backgroundImage: "", objectFit: "contain" },
      },
      children: [],
    },
  ]);

  const [pageProperty, setPageProperty] = useState<IPageProperty>({
    doctype: "A4",
    pageAmount: 1,
    gridSize: 15,
    padding: 5,
    pageSize: papers["A4"],
    isDragingFromOutSide: false,
  });

  const [selectionBlock, setSelectionBlock] = useState<IControlLayout>();

  const refHtml = useRef<HTMLDivElement | null>(null);

  const addLayout = (
    parent: "header" | "body" | "footer" | "content",
    layout: any,
    template: ILayoutTemplate
  ) => {
    try {
      let targetParent: IControlLayout | undefined = paperLayout?.find(
        (e) => e.type === parent
      );

      if (targetParent) {
        let childList = targetParent.children;
        layout.i = uuidv4();
        layout.w = 4;
        layout.h = 1;
        layout.maxW = 40;
        if (template.type === "tb") {
          layout.w = Math.round(
            pageProperty.pageSize.width / pageProperty.gridSize
          );
          layout.h = 3;
          layout.minH = 3;
          layout.minW = Math.round(
            pageProperty.pageSize.width / pageProperty.gridSize
          );
        } else if (template.type === "staticImage") {
          layout.w = 10;
          layout.h = 10;
          layout.minH = 5;
          layout.minW = 5;
        }
        const tempAttibute: IControlProperty =
          template.attribute as IControlProperty;
        template.attribute = {
          default: tempAttibute?.default || "",
          fontSize: 14,
          fontFamily: "Arial",
          color: "#000000",
          isBold: false,
          isItalic: false,
          isUnderlined: false,
          hAlign: "center",
          vAlign: "center",
          urlLink: "",
          hideLabel: false,
          columsWidth:
            template.type === "tb"
              ? tempAttibute?.column?.map((e) => 150)
              : undefined,
          column: tempAttibute?.column,
          backgroundSize: "contain",
        } as IControlProperty;

        layout.template = template;

        childList?.push(layout);

        onSelectChange(layout);
        setPaperLayout((prev) =>
          prev.map((e) => {
            if (e.type === parent) {
              e.children = childList;
            }
            return e;
          })
        );
      }
      updatePageProperties({ isDragingFromOutSide: false });
    } catch (error) {
      console.error(error);
      updatePageProperties({ isDragingFromOutSide: false });
    }
  };

  const removeSelectedChild = (parent: string) => {
    setPaperLayout((prev) =>
      prev.map((e) => {
        if (parent === parent) {
          e.children = e.children?.filter(
            (child) => child.i !== selectionBlock?.i
          );
        }
        return e;
      })
    );
  };

  const updatePageProperties = (params: Partial<IPageProperty>) => {
    setPageProperty((prevSettings) => ({
      ...prevSettings,
      ...params,
    }));
  };

  const onSelectChange = (template: IControlLayout) => {
    setSelectionBlock(template);
  };

  const onDeselected = () => {
    setSelectionBlock(undefined);
  };

  const onSelectPropertyChange = (props: IControlProperty) => {
    if (selectionBlock) {
      let _selectionBlock: IControlLayout = selectionBlock;
      _selectionBlock.template.attribute = props;
      const newLayout = paperLayout.map((layout) => {
        layout.children = layout.children?.map((child) => {
          if (child.i === selectionBlock.i) {
            child.template = _selectionBlock.template;
          }
          return child;
        });
        return layout;
      });
      if (newLayout) setPaperLayout(newLayout);
      setSelectionBlock(_selectionBlock);
    }
  };

  const changeDocType = (docType: IPageProperty["doctype"]) => {
    updatePageProperties({ doctype: docType, pageSize: papers[docType] });
  };

  return (
    <PDFEditorContext.Provider
      value={{
        paperLayout,
        pageProperty,
        selectionBlock,
        setPaperLayout,
        addLayout,
        updatePageProperties,
        refHtml,
        onSelectChange,
        onSelectPropertyChange,
        onDeselected,
        removeSelectedChild,
        changeDocType,
      }}
    >
      {props.children}
    </PDFEditorContext.Provider>
  );
};

export { PDFEditorProvider, PDFEditorContext };
