import moment from "moment";
import { confirmDialog } from "primereact/confirmdialog";
import { SplitButton } from "primereact/splitbutton";
import React, { useEffect, useMemo, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { ButtonComponents } from "../../components/ButtonComponents/ButtonComponents";
import { ReportDialog } from "../../components/SettingDialogComponents/ReportDialog/ReportDialog";
import DynamicTable from "../../components/TableComponents/DynamicTableV2/DynamicTable";
import { TreeSelectNewRequest } from "../../components/TreeSelectNewRequest/TreeSelectNewRequest";
import { useUserContext } from "../../Context/UserContext";
import useLoading from "../../hooks/useLoading";
import { CheckAutoReport } from "../../Services/AppSettingService";
import { CheckRolePermission } from "../../Services/AuthorizedService";
import { GetAllDynamic } from "../../Services/DynamicService";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import {
  DeleteReport,
  ReportTemplateSelectByReportID,
} from "../../Services/ReportService";
import { GetTemplateeBindFormNameDDL } from "../../Services/TemplateService";
import { IReportModel } from "../../IRequestModel/IReportModel";
import SelectedFieldComponent from "../../components/ReportScreenComponent/SelectedFieldComponent";
import { ProgressSpinner } from "primereact/progressspinner";
import { IoArrowBackSharp } from "react-icons/io5";
import { TablePaginationConfig } from "antd";
import DynamicReportFix from "./DynamicReportFix";
import { uniqueArray } from "../../Helper/arrayHelper";
import { isWolfLocal } from "../../Helper/WolfFunction";
import { v4 as uuidv4 } from "uuid";

const reportModelIReportModel: IReportModel = {
  ReporttemplateID: 0,
  ReportName: "",
  TemplateId: "",
  Selectedfieldlist: [],
  ReportDescription: "",
  IsPrivate: false,
  IsActive: true,
  CreatedBy: "",
  CreatedByname: "",
  CreatedDate: "",
  ModifiedBy: "",
  ModifiedByname: "",
  ModifiedDate: "",
  Selectedfieldlistfilter: [],
  Columns: [],
  Rows: [],
  TemplateNewVersion: false,
  PageIndex: 0,
  PageSize: 10,
  CanDelete: false,
  RoleId: "",
  RoleEmp: "",
  Mode: false,
};

const ReportScreenFix = () => {
  const location = useLocation();
  const apiName = location.pathname.replace("/", "");
  const [text, setText] = useState<string>("");
  const [itemsCount, setItemsCount] = useState<number>(0);
  const [reportList, setReportList] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [reportColumns, setReportColumns] = useState<any[]>();
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>([]);
  const { isLoad, setLoad } = useLoading();
  const [editReport, setEditReport] = useState<any>(false);
  const [checkReport, setCheckReport] = useState<any>(false);
  const [dataEditReport, setDataEditReport] = useState<any>([]);
  const [userData] = useUserContext();
  const [emp_data, setEmp_data] = useState<any>();
  const [_baseUrl, setbaseUrl] = useState<string>();
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const { search, pathname } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [isAutoReport, setIsAutoReport] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const { url } = useRouteMatch();
  let history = useHistory();
  const [uid, setUid] = useState<string | null>(null);

  useEffect(() => {
    const generatedUid = uuidv4();
    setUid(generatedUid);
  }, []);
  
  useEffect(() => {
    if (uid) {
      const searchParams = new URLSearchParams(search);
      searchParams.delete("WFID");
      const replaceUrl = uid.replace(/-/g, "").toUpperCase();
      searchParams.set("WFID", replaceUrl);
      const finalUrl = `${pathname}?${searchParams.toString()}`;
      history.replace(finalUrl);
    }
  },[uid, search, pathname, history])

  const [paginationOption, setPaginationOption] =
    useState<TablePaginationConfig>({
      position: ["bottomLeft"],
      size: "small",
      current: 1,
      showSizeChanger: true,
      onChange: (page, pageSize) => {
        let _page: TablePaginationConfig = paginationOption;
        _page.current = page;
        _page.pageSize = pageSize;

        setPaginationOption(_page);
      },
    });

  useEffect(() => {
    setLoad(true);
    const path = apiName.split("/");
    if (path[2]) {
      // history.push(
      //   `${url}/${path[1].replaceAll("/", "\\")}/${path[2]}/${isAutoReport}`
      // );
    } else {
      checkAutoReport();
    }
  }, []);

  useEffect(() => {
    setLoad(true);

    fetchTemplate();
  }, [isAutoReport]);

  useEffect(() => {
    fetchReport();
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      if (tableData) {
        const findedData = tableData.find((e: any) => {
          if (
            e.CreatedBy === userData.NameEn ||
            e.CreatedBy === userData.NameTh
          ) {
            return e;
          }
        });
        if (findedData) {
          setCanEdit(true);
        }
      }
    }
  }, [tableData]);

  const checkAutoReport = async () => {
    const mode = query.get("mode");
    const isEnable = await CheckAutoReport();
    if (mode === "test") {
      setIsAutoReport(!isEnable);
    } else {
      setIsAutoReport(isEnable);
    }
  };

  async function fetchReport() {
    try {
      let empList: any[] = [];
      let _emp_dataFind: any = undefined;
      const userId = query.get("usrid")
        ? () => {
            setIsAdmin(false);
            return query.get("usrid");
          }
        : userData.EmployeeId;

      const _dataDynamic: any[] = await GetAllDynamic(apiName + "/GetAll", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          EmployeeId: userId?.toString(),
          UserPrincipalName: userData.Email,
        }),
      });

      const _isAdmin = await CheckRolePermission(
        _emp_dataFind ? _emp_dataFind?.EmployeeId : userData.EmployeeId
      );

      if (query.get("usrid") && _isAdmin) {
        _emp_dataFind = empList.find(
          (item: any) => item.EmployeeId === Number(query.get("usrid"))
        );
      }

      if (reportList === undefined) {
        setReportList(_dataDynamic);
      }

      if (!emp_data) {
        empList = await GetAllDynamic("Employee" + "/GetAll", undefined);
        setEmp_data(empList);
      } else {
        empList = emp_data;
      }

      // const hasMy = _dataDynamic?.find(
      //   (_data: any) =>
      //     _data.CreatedBy === userData.NameTh ||
      //     _data.CreatedBy === userData.NameEn
      // );

      const hasMy = Array.isArray(_dataDynamic)
        ? _dataDynamic.find(
            (_data: any) =>
              _data.CreatedBy === userData.NameTh ||
              _data.CreatedBy === userData.NameEn
          )
        : undefined;
      if (hasMy) {
        setCanEdit(true);
      }
      if (empList.length > 0) {
        _dataDynamic?.map((e: any) => {
          let emp = empList?.find(
            (_emp: any) => _emp.EmployeeId === e.CreatedBy
          );
          if (emp) {
            e.CreatedBy = userData.Lang === "TH" ? emp?.NameTh : emp?.NameEn;
          } else {
            e.CreatedBy = "";
          }
        });
        _dataDynamic?.map((e: any) => {
          let emp = empList?.find(
            (_emp: any) => _emp.EmployeeId === e.ModifiedBy
          );
          if (emp) {
            e.ModifiedBy = userData.Lang === "TH" ? emp?.NameTh : emp?.NameEn;
          } else {
            e.ModifiedBy = "";
          }
        });
      }

      const getUniqueArray = uniqueArray(_dataDynamic);

      setItemsCount(getUniqueArray.length);
      setIsAdmin(_isAdmin);
      setTableData([...getUniqueArray]);
      setReportColumns(undefined);
      setText("Report List");
      setLoad(false);
    } catch (error) {
      console.error("report=>error", error);
      setLoad(false);
    }
  }

  async function fetchTemplate() {
    const empid = userData.EmployeeId;
    const DepartmentId = userData.DepartmentId;

    const dataJsonn = {
      CreatedBy: empid.toString(),
      DepartmentId: DepartmentId,
      Username: userData.Username,
      Email: userData.Email,
    };

    let _dataTemplatee = await GetTemplateeBindFormNameDDL(dataJsonn);

    setTemplate(_dataTemplatee);
  }

  async function fetchGetReportById(rePortId: any) {
    let _ReportById = await ReportTemplateSelectByReportID(rePortId);
    setDataEditReport(_ReportById);
  }

  const actionBodyTemplate = (record: any) => {
    const adminActionBody = [
      {
        label: "Edit",
        icon: "pi pi-pencil",
        command: () => {
          fetchGetReportById(record.ReportTemplateId);
          setVisibleDialog(true);
          setCheckReport(true);
          setEditReport(true);
        },
      },
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          confirmDialog({
            message: "Do you want to delete this report.",
            header: "Delete",
            icon: "p-confirm-dialog-icon pi pi-info-circle",
            rejectClassName: "hide",
            acceptClassName:
              "p-button p-component p-confirm-dialog-accept table-accept p-button-danger table-control-confirm-button p-button-accept-cancel",
            accept: async () => {
              const res = await DeleteReport(record.ReportTemplateId);
              if (res) {
                setLoad(true);
                fetchReport();
              }
            },
          });
        },
      },
    ];
    let _user: any = userData;
    if (query.get("usrId")) {
      _user = emp_data.find((e: any) => e.EmployeeId == query.get("usrId"));
    }

    if (isAdmin && !query.get("usrId")) {
      return (
        <React.Fragment>
          <SplitButton
            // id
            className="p-button-secondary"
            model={adminActionBody}
            dropdownIcon="pi pi-ellipsis-v"
          />
        </React.Fragment>
      );
    } else if (
      record.CreatedBy === _user.NameTh ||
      record.CreatedBy === _user.NameEn
    ) {
      return (
        <React.Fragment>
          <SplitButton
            className="p-button-secondary"
            model={adminActionBody}
            dropdownIcon="pi pi-ellipsis-v"
          />
        </React.Fragment>
      );
    }
  };

  function toggleDialog() {
    setVisibleDialog(!visibleDialog);
    fetchReport();
    setEditReport(false);
  }

  if (isLoad) {
    return <></>;
  } else {
    return (
      <Switch>
        <Route exact path={`${url}/:reportName/:reportId`}>
          <DynamicReportFix />
        </Route>
        <React.Fragment>
          <div>
            <div className="main-container">
              <div className="worklist-container">
                <div className="header-container">
                  <div className="button-container">
                    <TreeSelectNewRequest setDataTemplateTreeProps={null} />
                  </div>
                  <div
                    className="route-text-container"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="route-text">
                      {text.replace("Dynamic", "") + " (" + itemsCount + ")"}
                    </p>
                    {!isWolfLocal() && (
                      <ButtonComponents
                        setLabelProps={"Create Report"}
                        setStyleProps={{
                          height: "38px",
                          backgroundColor: "#282F6A",
                          color: "#FFFFFF",
                          border: "1px solid rgb(40, 47, 106)",
                          width: 170,
                          whiteSpace: "nowrap",
                        }}
                        onClickProps={() => {
                          toggleDialog();
                        }}
                      />
                    )}
                  </div>
                </div>
                <DynamicTable
                  tableName={text}
                  dataSource={tableData}
                  customColumns={reportColumns}
                  setItemsCount={setItemsCount}
                  actionBodyTemplate={actionBodyTemplate}
                  paginationOption={paginationOption}
                  canEdit={isAdmin || canEdit}
                  rowClickAction={(record: any) => {
                    history.push(
                      `${url}/${record.ReportName.replaceAll("/", "\\")}/${
                        record.ReportTemplateId
                      }`
                    );
                  }}
                  canExport={text !== "Report List"}
                />
              </div>
              <ReportDialog
                visible={visibleDialog}
                toggleDialog={toggleDialog}
                templateDataProps={template}
                checkSetProps={setCheckReport}
                checkProps={checkReport}
                editProps={editReport}
                dataEditReportProps={dataEditReport}
                setEditReportProps={setDataEditReport}
                setOnLoading={setLoad}
              />
            </div>
          </div>
        </React.Fragment>
      </Switch>
    );
  }
};

export default ReportScreenFix;
