import { LocalStoreType } from "../constants/local-type";

const ignore = ['favorite', 'memoid'];
export const clearLocalStorageOnLogout = () => {
  Object.keys(localStorage).forEach((key) =>
    !ignore.includes(key) ? localStorage.removeItem(key) : ""
  );
};


export const getDataLocal = (Local :LocalStoreType) => {
  if (!Local) return "" 
  const data = window.localStorage.getItem(Local) || "";
  return data
}