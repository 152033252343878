import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { confirmDialog } from "primereact/confirmdialog";
import "../Dialog.css";

import { FiSave } from "react-icons/fi";
import { Col, Row } from "react-bootstrap";
import { isEmptyObject } from "jquery";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { GetAllDynamic, updateDynamic } from "../../../Services/DynamicService";
import moment from "moment";
import { TextHeaderComponents } from "../../TextHeaderComponents/TextHeaderComponents";
import useAlert from "../../../hooks/useAlert";
import { AddCostCenter } from "../../../Services/CostCenterService";
interface Props {
  dialogHeader: string;
  formData: any;
  tableData?: any;
  setTableData?: (items: any) => void;
  tableComfirmDialog?: boolean;
  setTableComfirmDialog?: (bool: boolean) => void;
  mainDialogVisible?: boolean;
  toggleMainDialog: (state: boolean, action: string) => void;
  setMainLoading?: (bool: boolean) => void;
}

export const CostCenterDialog = (props: Props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });
  const { toggleAlert } = useAlert();
  const [messageConfirmDialog, setMessageConfirmDialog] = useState<string>("");
  const [positionLevelList, setPositionLevelList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const userData = JSON.parse(window.localStorage.getItem("userData") ?? "");
  const [action, setAction] = useState<string>("");
  const [dataEmployeeList, setDataEmployeeList] = useState<any[]>([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [searchData, setSearchData] = useState<any[]>([]);
  const [subDialogVisible, setSubDialogVisible] = useState<boolean>(false);
  const [subDialogVisible2, setSubDialogVisible2] = useState<boolean>(false);
  const [masterCompany, setMasterCompany] = useState<any>();
  const [selectComCode, setSelectComCode] = useState<any>();

  const toast = useRef<any>();

  useEffect(() => {
    if (!isEmptyObject(props.formData)) {
      setAction("edit");
    } else {
      setAction("add");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    setMessageConfirmDialog("Do you want to " + action + " this record?");
  }, [action]);

  const getFormErrorMessage = (name: any) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  useEffect(() => {
    if (props.mainDialogVisible) {
      fecthCompany();
      setLoading(false);
    }
  }, [props.mainDialogVisible]);

  async function fecthCompany() {
    const company = await GetAllDynamic(
      "MasterCompany/GetAll?IsActive=true",
      undefined
    );
    if (company) {
      company.map((e: any) => {
        if (e.CompanyCode === props.formData?.CompanyCode) {
          if (control._fields.CompanyCode !== undefined) {
            control._fields.CompanyCode._f.value = e;
            control._formValues.CompanyCode = e;
          }
          setSelectComCode({ ...e });
        }
      });
      if (company.length === 1) {
        setSelectComCode(company[0]);
      }
      setMasterCompany([...company]);
    }
  }

  const acceptSave = async (data: any) => {
    try {
      let formData: any = {};
      if (action === "add") {
        formData.CostCenterId = 0;
        formData.CostCenterCode = data.CostCenterCode;
        formData.Description = data.Description;
        formData.Year = data.Year;
        formData.CompanyCode = selectComCode.CompanyCode;
        formData.Active = data.Active;
        formData.AccountId = userData.employeeData.EmployeeId.toString();
        formData.ModifiedDate = new Date().toLocaleString("en-UK", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        formData.ModifiedBy = userData.employeeData.EmployeeId.toString();
        formData.CreatedDate = new Date().toLocaleString("en-UK", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        formData.CreatedBy = userData.employeeData.EmployeeId.toString();
      } else {
        formData = props.formData;

        formData.CostCenterId = props.formData.CostCenterId;
        formData.CostCenterCode = data.CostCenterCode;
        formData.Description = data.Description;
        formData.CompanyCode = selectComCode.CompanyCode;
        formData.Year = data.Year;
        formData.Active = data.Active;
        formData.AccountId = userData.employeeData.EmployeeId.toString();
        formData.ModifiedDate = new Date().toLocaleString("en-UK", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        formData.ModifiedBy = userData.employeeData.EmployeeId.toString();
      }

      let res = await AddCostCenter(formData);
      if (res === "success") {
        props.toggleMainDialog(false, "save");
      } else {
        if (props.setMainLoading !== undefined) {
          props.setMainLoading(false);
        }
        toggleAlert({
          description: `"Duplicate Cost Center"`,
          message: `Error`,
          type: "error",
        });
      }
    } catch (error) {
      if (props.setMainLoading !== undefined) {
        props.setMainLoading(false);
      }
      toggleAlert({
        description: `${error}`,
        message: `Error`,
        type: "error",
      });
    }
  };

  const updateChanges = (data: any) => {
    if (!subDialogVisible && !subDialogVisible2 && props.mainDialogVisible) {
      confirmDialog({
        message: messageConfirmDialog,
        header:
          action === "add" ? "Add" + " Confirmation" : "Edit" + " Confirmation",
        icon: "p-confirm-dialog-icon pi pi-info-circle",
        acceptClassName:
          "p-button p-component p-confirm-dialog-accept table-accept p-button-danger table-control-confirm-button p-button-accept-cancel",
        accept: () => {
          if (props.setMainLoading !== undefined) {
            props.setMainLoading(true);
          }
          acceptSave(data);
        },
      });
    }
  };

  return (
    <div>
      <Dialog
        visible={props.mainDialogVisible}
        breakpoints={{}}
        style={{ width: "70vw", borderRadius: "16px" }}
        header={props.dialogHeader}
        modal
        className="p-fluid"
        onHide={() => {
          reset();
          props.toggleMainDialog(false, "close");
        }}
        draggable={false}
        closable={false}
        blockScroll
      >
        <form onSubmit={handleSubmit(updateChanges)} className="p-fluid">
          <div>
            <Row style={{ paddingBottom: "10px" }}>
              <Col xs={2} sm={2} xl={2}>
                <TextHeaderComponents
                  textHeaderProps={"Cost Center Code"}
                  textSubProps={"รหัสของหน่วยงาน"}
                  isRequir
                />
              </Col>
              <Col xs={4} sm={4} xl={4}>
                <Controller
                  name="CostCenterCode"
                  control={control}
                  defaultValue={props.formData.CostCenterCode}
                  rules={{ required: "Cost Center Code is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("CostCenterCode")}
              </Col>
              <Col xs={2} sm={2} xl={2}>
                <TextHeaderComponents
                  textHeaderProps={"Description"}
                  textSubProps={"คำอธิบาย"}
                  isRequir
                />
              </Col>
              <Col xs={4} sm={4} xl={4}>
                <Controller
                  name="Description"
                  control={control}
                  defaultValue={props.formData.Description}
                  rules={{ required: "Description is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("Description")}
              </Col>
            </Row>
            <Row style={{ paddingBottom: "10px" }}>
              <Col xs={2} sm={2} xl={2}>
                <TextHeaderComponents
                  textHeaderProps={"Company Code"}
                  textSubProps={"รหัสบริษัท"}
                  isRequir
                />
              </Col>
              <Col xs={4} sm={4} xl={4}>
                <Controller
                  name="CompanyCode"
                  control={control}
                  rules={{
                    required: isEmptyObject(selectComCode)
                      ? "Company Code is required."
                      : undefined,
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.error,
                      })}
                      value={selectComCode}
                      options={masterCompany}
                      optionLabel={"CompanyCode"}
                      filter
                      showClear
                      filterBy={"CompanyCode"}
                      placeholder="Select a Company Code"
                      onChange={(e: any) => setSelectComCode(e.value)}
                    />
                  )}
                />
                {!selectComCode ? getFormErrorMessage("CompanyCode") : null}
              </Col>{" "}
              <Col xs={2} sm={2} xl={2}>
                <TextHeaderComponents
                  textHeaderProps={"Year"}
                  textSubProps={"ปี"}
                  isRequir
                />
              </Col>
              <Col xs={4} sm={4} xl={4}>
                <Controller
                  name="Year"
                  control={control}
                  defaultValue={props.formData.Year}
                  rules={{ required: "Year is required.", pattern: /^\d{4}$/ }}
                  render={({ field, fieldState }) => (
                    <>
                      <InputText
                        id={field.name}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          // Your custom handleChange logic goes here if needed
                        }}
                        className={fieldState.invalid ? "p-invalid" : ""}
                      />
                      {fieldState.invalid && (
                        <small>
                          {" "}
                          <p style={{ color: "red" }}>
                            {fieldState?.error?.message ||
                              "Please enter a valid year (four digits)."}
                          </p>
                        </small>
                      )}
                    </>
                  )}
                />
              </Col>
            </Row>
            <Row style={{ paddingBottom: "10px" }}>
              <Col xs={2} sm={2} xl={2}>
                <TextHeaderComponents
                  textHeaderProps={"Active Status"}
                  textSubProps={"สถานะ"}
                />
              </Col>
              <Col xs={4} sm={4} xl={4}>
                <Controller
                  name="Active"
                  defaultValue={
                    props.formData.Active === undefined ||
                    props.formData.Active === "true"
                      ? true
                      : false
                  }
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputSwitch
                      inputId={field.name}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
              </Col>
            </Row>
          </div>
          <div className="footer-dialog">
            <button
              onClick={() => {
                reset();
                props.toggleMainDialog(false, "close");
              }}
              type="button"
              className="button-cancle"
            >
              Close
            </button>

            <button className="button-save" type="submit">
              <FiSave />
              Save
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};
