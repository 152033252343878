import React, { createContext, useState, useEffect } from "react";
import {
  GetAllMasterData,
  GetMasterDataByTypeNames,
} from "../Services/MasterDataService";
import { getDataLocal } from "../Helper/localStoreFunction";
import { LocalStoreType } from "../constants/local-type";

type IMasterSettingContextType = {
  masterSetting: { [key: string]: any };
  updateMasterData: (masterData: any[]) => void;
  getMasterSetting: () => Promise<{ [key: string]: any }>;
  getMasterSettingAll: () => Promise<any[]>;
};

export const MasterSettingContext =
  createContext<IMasterSettingContextType | null>(null);

export const MasterSettingContextProvider: React.FC = (props) => {
  const [masterSetting, setMaterSetting] = useState<{ [key: string]: any }>({});
  const [masterAll, setMasterAll] = useState<any[]>([]);
  const listMasterType = [
    "FormatDate",
    "RefSpec",
    "splitPage",
    "PdfAnnotate",
    "ComConfig",
    "form_limit",
  ];

  useEffect(() => {
    if (!getDataLocal(LocalStoreType.userData)) return;
    fecthData();
    fetchMasterSettingAll();
  }, [getDataLocal(LocalStoreType.userData)]);

  const fecthData = async () => {
    let settingDict = await getMasterSetting();
    setMaterSetting(settingDict);
  };
  const updateMasterData = (masterData: any[]) => {
    if (masterData.length > 0) {
      const _masterData = masterData.filter(
        (e) => listMasterType.includes(e.MasterType) && e.IsActive
      );

      let settingDict: any = {};
      _masterData.forEach((e: any) => {
        settingDict[e.MasterType] = e;
      });
      setMaterSetting(settingDict);
    }
  };

  const getMasterSetting = async () => {
    const masterData = await GetMasterDataByTypeNames(listMasterType)
      .then((e) => e.filter((e: any) => e.IsActive))
      .catch((err) => {
        console.error(err);
      });

    let settingDict: any = {};
    masterData?.forEach((e: any) => {
      settingDict[e.MasterType] = e;
    });
    return settingDict;
  };

  const fetchMasterSettingAll = async () => {
    const masterAll = await GetAllMasterData()
      .then((e) => e.filter((e: any) => e.IsActive))
      .catch((err) => {
        console.error(err);
      });
    setMasterAll(masterAll ?? []);
  };
  const getMasterSettingAll = async () => {
    return masterAll;
  };
  return (
    <MasterSettingContext.Provider
      value={{
        masterSetting,
        updateMasterData,
        getMasterSetting,
        getMasterSettingAll,
      }}
    >
      {props.children}
    </MasterSettingContext.Provider>
  );
};
