import React, { useEffect, useState } from "react";
import "./WorklistScreenComponent.css";
import moment from "moment";
import {
  BsFileEarmarkPdfFill,
  BsFileEarmarkExcelFill,
  BsFillFileEarmarkTextFill,
} from "react-icons/bs";
import { FaFilePowerpoint, FaFileWord } from "react-icons/fa";
import { getDataLocal } from "../../Helper/localStoreFunction";
import { LocalStoreType } from "../../constants/local-type";
interface Props {
  data: any;
}

export const AttachmentCardComponent = (props: Props) => {
  const [comploteLink, setComploteLink] = useState("");
  const formatDate = () => {
    let momentObj = moment(props.data.attach_date, "DD/MM/yyyy HH:mm:ss");
    return moment(momentObj).format("DD MMM yyyy , h:mm");
  };
  const _userData = JSON.parse(getDataLocal(LocalStoreType.userData) || "");
  const _sharepointSiteURL = _userData.SharepointSiteURL;

  useEffect(() => {
    showFile();
  }, []);

  const showFile = async () => {
    const attachPath = props.data.attach_path;
    let comploteLink = "";
    const _link = attachPath
      ? attachPath.charAt(0) !== "/" && !_sharepointSiteURL
        ? "/" + attachPath
        : attachPath
      : "";
    const protocol = window.location.protocol;
    if (_sharepointSiteURL) {
      comploteLink = _link;
    } else if (_userData.TinyURL) {
      const _port = location.port;
      if (location.host !== "localhost:5001") {
        if (_port) {
          _userData.TinyURL = _userData.TinyURL + ":" + _port;
        }
      }

      if (
        _link.includes("www") ||
        _link.includes(".com") ||
        _link.includes(".tv") ||
        _link.includes(".net") ||
        _link.includes("https") ||
        _link.includes("http") ||
        _link.includes(".com") ||
        _link.includes(".co.th")
      ) {
        if (_link.includes("https")) {
          comploteLink = `https://${_link.replaceAll("https://", "")}`;
          // window.open(
          //   `https://${_link.replaceAll("https://", "")}`,
          //   "_blank",
          //   "noreferrer"
          // );
        } else if (_link.includes("http")) {
          comploteLink = `http://${_link.replaceAll("http://", "")}`;
          // window.open(
          //   `http://${_link.replaceAll("http://", "")}`,
          //   "_blank",
          //   "noreferrer"
          // );
        } else {
          comploteLink = `https://${_link}`;
          // window.open(`https://${_link}`, "_blank", "noreferrer");
        }
      } else {
        comploteLink = `${protocol}//${_userData.TinyURL}${_link}`;
        // window.open(
        //   `${protocol}//${_userData.TinyURL}${_link}`,
        //   "_blank",
        //   "noreferrer"
        // );
      }
    } else if (!_userData.TinyURL) {
      comploteLink = `${_link}`;
      // window.open(`${_link}`, "_blank", "noreferrer");
    }
    setComploteLink(comploteLink);
  };

  const getFileIcon = (attachPath: string) => {
    if (attachPath.endsWith("pdf")) {
      return <BsFileEarmarkPdfFill fontSize={32} />;
    } else if (
      attachPath.endsWith("png") ||
      attachPath.endsWith("jpg") ||
      attachPath.endsWith("jpeg")
    ) {
      return <img src={comploteLink} alt="flow-img" />;
    } else if (attachPath.endsWith("doc") || attachPath.endsWith("docx")) {
      return <FaFileWord fontSize={32} />;
    } else if (
      attachPath.endsWith("csv") ||
      attachPath.endsWith("xls") ||
      attachPath.endsWith("xlsx")
    ) {
      return <BsFileEarmarkExcelFill fontSize={32} />;
    } else if (attachPath.endsWith("pptx") || attachPath.endsWith("ppt")) {
      return <FaFilePowerpoint fontSize={32} />;
    } else if (attachPath.endsWith("txt")) {
      return <BsFillFileEarmarkTextFill fontSize={32} />;
    }
  };

  return (
    <>
      {props.data && (
        <div
          className="card-attachment-container"
          onClick={() => window.open(comploteLink, "_blank", "noreferrer")}
        >
          <div className="show-img-container">
              {getFileIcon(props.data.attach_path)}
          </div>
          <div className="detail-container">
            <p className="text-header">{props.data.attach_file}</p>
            <p className="owner-file-name">{props.data.actor.NameEn}</p>
            <p className="text-detail">{props.data.description}</p>
          </div>
          <p className="date-upload">{formatDate()}</p>
        </div>
      )}
    </>
  );
};
