import React, { useEffect, useState } from "react";
import { AutoComplete, Button, Col, DatePicker, Row, Select } from "antd";
import { TextHeaderComponents } from "../../../../components/TextHeaderComponents/TextHeaderComponents";
import { ReportTable } from "./ReportTable";
import withPerMission from "../../../../components/HOC/withPermission";
import { GetAllDynamic } from "../../../../Services/DynamicService";
import useLoading from "../../../../hooks/useLoading";
import { getDocStatus } from "../../../../Services/MasterDataService";
import moment from "moment";
const { RangePicker } = DatePicker;
const PaymentReportScreen = (props: PaymentReportProps) => {
  const { setLoad } = useLoading();
  const [data, setData] = useState<any[]>([]);
  const [getCompany, setCompany] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchOptions, setSearchOptions] = useState<{ value: string }[]>([]);
  const [searchComId, setSearchComId] = useState<number>(0);
  const [docStatus, setDocStatus] = useState([]);
  const [rangeDate, setRangeDate] = useState<any[]>([
    moment().subtract("months", 1),
    moment(),
  ]);
  const [searchStatus, setSearchStatus] = useState("Wait for Approve");
  useEffect(() => {
    const abortController = new AbortController();
    retrieveData(abortController.signal);
    GetDocStatus();
    GetCompany();
    return () => {
      abortController.abort("abort");
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const handler = setTimeout(() => {
      fetchAutocompleteOptions(searchValue, 10, abortController.signal).then(
        (value) => {
          setSearchOptions(value);
        }
      );
    }, 1500);

    return () => {
      abortController.abort();
      clearTimeout(handler);
    };
  }, [searchValue]);

  function onSearch(textSearch: string) {
    setSearchValue(textSearch);
  }

  function onSelect(textSearch: string) {
    setSearchValue(textSearch);
  }

  async function fetchAutocompleteOptions(
    q: string,
    count: number,
    signal: AbortSignal
  ) {
    const reqBody = {
      q: q,
      count: count,
    };
    let resData: { value: string }[] = await fetch(
      `api/Frasers/PaymentReport/GetAutocompleteOptions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
        signal: signal,
      }
    )
      .then((r) => (r.ok ? r.json() : Promise.resolve({ data: [] })))
      .then((r) => r.data);
    return resData;
  }

  async function retrieveData(signal?: AbortSignal) {
    setLoad(true);

    const reqBody = {
      searchValue: searchValue,
      companyId: searchComId,
      startdate: rangeDate[0] ?? undefined,
      enddate: rangeDate[1] ?? undefined,
      StatusName: searchStatus,
    };
    let resData: PaymentReportResponse["data"] = await fetch(
      "api/Frasers/GetPaymentReport",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
        signal: signal,
      }
    )
      .then((r) => r.json())
      .then((r) => r.data);
    if (Array.isArray(resData)) {
      resData = resData.map((x) => {
        if (Array.isArray(x.children)) {
          x.children = x.children.map((y) => {
            y.key = `${x.desc}_${y.desc}`;
            return y;
          });
        }
        return x;
      });
    }
    setData([...resData]);
    setLoad(false);
  }

  const GetDocStatus = async () => {
    const docStatus = await getDocStatus();
    if (docStatus) {
      const obj = JSON.parse(docStatus.Value1);

      setDocStatus(
        obj.map((data: any) => {
          return {
            label: data.l,
            value: data.v,
          };
        })
      );
    }
  };

  const GetCompany = async () => {
    const companys = await GetAllDynamic("MasterCompany/GetAll");
    setCompany([...companys]);
  };

  return (
    <div className="main-container">
      <div className="worklist-container">
        <div>
          <br />
          <h3>Payment Voucher Report</h3>
          <form
            onSubmit={() => {
              retrieveData();
            }}
          >
            <Row
              gutter={[10, 10]}
              style={{ paddingBottom: "10px" }}
              align="middle"
            >
              <Col span={2}>
                <TextHeaderComponents
                  textHeaderProps={"Vendor Name"}
                  textSubProps={""}
                />
              </Col>
              <Col span={5}>
                <AutoComplete
                  value={searchValue}
                  options={searchOptions}
                  style={{ width: "100%" }}
                  onSearch={onSearch}
                  onSelect={onSelect}
                  placeholder="Search by Vendor Name"
                  allowClear
                />
              </Col>
              <Col span={2}>
                <TextHeaderComponents
                  textHeaderProps={"Doc Status"}
                  textSubProps={""}
                />
              </Col>
              <Col span={5}>
                <Select
                  options={[
                    "Rework",
                    "Recall",
                    "Rejected",
                    "Wait for Approve",
                    "Completed",
                  ].map((x) => {
                    return { label: x, value: x };
                  })}
                  style={{ width: "100%" }}
                  value={searchStatus}
                  placeholder="Search by Doc status"
                  onChange={setSearchStatus}
                  allowClear
                />
              </Col>
              <Col span={2}>
                <TextHeaderComponents
                  textHeaderProps={"Company"}
                  textSubProps={""}
                />
              </Col>
              <Col span={5}>
                <Select
                  options={getCompany.map((x) => {
                    return {
                      label: x.CompanyCodeWithName,
                      value: x.CompanyId,
                    };
                  })}
                  onChange={(e) => {
                    setSearchComId(e);
                  }}
                />
              </Col>
            </Row>
            <Row
              gutter={[10, 10]}
              style={{ paddingBottom: "10px" }}
              align="middle"
            >
              <Col span={2}>
                <TextHeaderComponents
                  textHeaderProps={"Payment Start date - End date"}
                  textSubProps={""}
                />
              </Col>
              <Col span={5}>
                <RangePicker
                  value={rangeDate as any}
                  format={"DD MMM YYYY"}
                  onChange={(e: any) => {
                    setRangeDate(e);
                  }}
                />
              </Col>
              <Col
                span={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button type="primary" onClick={() => retrieveData()}>
                  Search
                </Button>
              </Col>
            </Row>
          </form>
        </div>
        <div>
          <ReportTable data={data} />
        </div>
        <div style={{ textAlign: "end" }}>
          <b>
            Total :{" "}
            {data
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.amount,
                0
              )
              .toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
          </b>
        </div>
      </div>
    </div>
  );
};
export default withPerMission(PaymentReportScreen);
