import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import UnAurthorization from "../../screens/UnAuthorizePage/UnAurthorization";
import { GetNavbarMenu } from "../../Services/NavBarMenuService";
import { getDataLocal } from "../../Helper/localStoreFunction";
import { LocalStoreType } from "../../constants/local-type";
import useMenuEmail from "../../hooks/useMenuEmail";
const withPerMission =
  (Component: any) =>
    ({ ...props }) => {
      const history = useHistory();
      const location = useLocation();
      const { getMenuEmail } = useMenuEmail()
      const isAdmin = getDataLocal(LocalStoreType.isAdmin) === "true"
      const empData = JSON.parse(getDataLocal(LocalStoreType.userData));
      const query = new URLSearchParams(useLocation().search);
      const menus = getMenuEmail()
      const [onLoading, setOnLoading] = useState(!!menus.length);
      const [isAuthorize, setIsAuthorizeo] = useState(false);

      useEffect(() => {
        const fetchNav = async () => {
          const _query = query.get("name") || "";
  
          let _isAuthorize: boolean = !menus.length || isAuthorize;
          menus.forEach((menu,idx)=>{
           
            if (
              menu?.Url?.toLowerCase() === location.pathname?.toLowerCase() ||
              menu?.Url?.toLowerCase() === `/${_query?.toLowerCase()}` ||
              location?.pathname
                ?.toLowerCase()
                .includes(menu?.Url?.toLowerCase()) || isAdmin
            ) {
              _isAuthorize = true;
            }
          })
       
          setOnLoading(false);
          setIsAuthorizeo(_isAuthorize);
        };
        fetchNav();
      }, [query ,getMenuEmail()]);

      return onLoading ? (
        <div />
      ) : isAuthorize ? (
        <Component {...props} />
      ) : (
        <UnAurthorization />
      );
    };
export default withPerMission;
