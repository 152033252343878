import React, { useEffect, useMemo, useState } from "react";
import "../PDFTemplateComponent/PDFTemplateComponent.css";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css"; // CSS for react-grid-layout
import "react-resizable/css/styles.css"; //
import usePDFEditor from "../../../hooks/usePDFEditor";
import {
  IControlLayout,
  IControlProperty,
  ILayoutTemplate,
} from "../../../Context/PDFEditorContext";
import StaticInput from "./StaticInput";
import StaticImage from "./StaticImage";
import { FileImageOutlined } from "@ant-design/icons";
import img from "../../../assets/wolf-slide-left.png";
import { getDataLocal } from "../../../Helper/localStoreFunction";
import { LocalStoreType } from "../../../constants/local-type";
import DisplayTable from "./DisplayTable";

interface props {
  name: "header" | "body" | "footer" | "content";
  children: IControlLayout[];
  paperWidth: number;
}

const DropableComponent = ({ name, paperWidth, children }: props) => {
  const [rowHeight, setRowHeight] = useState<number>(14);
  const [previewImage, setPreviewImage] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  var _userData = JSON.parse(getDataLocal(LocalStoreType.userData));
  const _sharepointSiteURL = _userData.SharepointSiteURL;

  const {
    paperLayout,
    addLayout,
    setPaperLayout,
    pageProperty,
    onSelectChange,
    selectionBlock,
    onSelectPropertyChange,
    removeSelectedChild,
  } = usePDFEditor();

  const tableDisplay = (layout: IControlLayout) => {
    const controlAttribute = layout.template.attribute as IControlProperty;

    let node: React.ReactNode[] = [
      <tr>
        {controlAttribute?.column?.map((e) => {
          return (
            <th
              style={{
                fontFamily: controlAttribute?.fontFamily,
                fontSize: controlAttribute?.fontSize,
                fontStyle: controlAttribute?.isItalic ? "italic" : "normal",
                fontWeight: controlAttribute?.isBold ? 700 : 100,
              }}
            >
              {e.label}
            </th>
          );
        })}
      </tr>,
    ];
    // console.log(layout.h * pageProperty.gridSize);

    return node;
  };

  useEffect(() => {
    const handleKeyDown = (event: { keyCode: number }) => {
      if (event.keyCode === 46) {
        removeSelectedChild(name);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectionBlock]);

  const imgSize = {
    contain: { width: "100%" },
    cover: { hiegth: "100%" },
    fill: { width: "100%", hiegth: "100%" },
  };

  const renderContent = (layout: IControlLayout) => {
    const isStaticText = layout.template.type === "staticText";
    const isSelected = selectionBlock?.i === layout.i;
    const controlAttribute = layout.template.attribute as IControlProperty;

    if (layout.template.type === "tb") {
      return (
        <DisplayTable
          layout={layout}
          onSelectPropertyChange={onSelectPropertyChange}
          selectionBlock={selectionBlock}
        />
      );
    } else if (isStaticText) {
      return isSelected ? (
        <StaticInput
          template={layout.template}
          h={layout.h}
          onBlur={(val) => {
            if (val !== null) {
              setPaperLayout([
                ...paperLayout.map((e) => {
                  if (e.i === name) {
                    e.children = e.children?.map((child) => {
                      if (child.i === selectionBlock.i) {
                        child.template.label = val;
                      }

                      return child;
                    });
                  }
                  return e;
                }),
              ]);
            }
          }}
        />
      ) : (
        <span
          className="content"
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            fontFamily: controlAttribute?.fontFamily,
            overflow: "unset",
            margin: "unset",
          }}
        >{`${layout?.template?.label}`}</span>
      );
    } else if (layout.template.type === "staticImage") {
      const imgSize = {
        contain:
          layout.h > layout.w
            ? {
                width: "100%",
              }
            : { height: "100%", width: "auto" },
        cover: {},
        fill: { width: "100%", height: "100%" },
      };
      return isSelected ? (
        <StaticImage
          template={layout.template}
          h={layout.h}
          w={layout.w}
          onUploadComplete={(val) => {
            if (val !== null) {
              setPaperLayout([
                ...paperLayout.map((e) => {
                  if (e.i === name) {
                    e.children = e.children?.map((child) => {
                      if (child.i === selectionBlock.i) {
                        child.template.value = val;
                      }

                      return child;
                    });
                  }
                  return e;
                }),
              ]);
            }
          }}
        />
      ) : controlAttribute?.backgroundSize !== "cover" ? (
        <>
          {" "}
          {layout.template.value === "" ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#999999",
              }}
            />
          ) : (
            <img
              className="content"
              src={getLink(layout.template.value)}
              alt={layout.template.label}
              style={imgSize[controlAttribute?.backgroundSize]}
            />
          )}
        </>
      ) : (
        <div
          style={{ overflow: "hidden", maxWidth: "100%", maxHeight: "100%" }}
        >
          {layout.template.value === "" ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#999999",
              }}
            />
          ) : (
            <img
              className="content"
              src={getLink(layout.template.value)}
              alt={layout.template.label}
              style={imgSize[controlAttribute?.backgroundSize]}
            />
          )}
        </div>
      );
    } else if (layout.template.type === "l") {
      return (
        <span
          className="content"
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            fontFamily: controlAttribute?.fontFamily,
            overflow: "unset",
            margin: "unset",
          }}
        >{`${controlAttribute?.hideLabel ? "" : layout.template.label}`}</span>
      );
    } else {
      return (
        <span
          className="content"
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            fontFamily: controlAttribute?.fontFamily,
            overflow: "unset",
            margin: "unset",
          }}
        >{`${
          controlAttribute?.hideLabel ? "" : layout.template.label + " : "
        }  ${"value"}`}</span>
      );
    }
  };

  const getLink = (val: string) => {
    if (val === "") {
      return "";
    }
    let imgUrl = "";
    const protocol = window.location.protocol;

    if (_sharepointSiteURL) {
      imgUrl = _sharepointSiteURL + "/" + val;
    } else {
      const _port = window.location.port;
      let _tinyURL = _userData.TinyURL;
      if (_port) {
        _tinyURL = _userData.TinyURL;
      }
      imgUrl = `${protocol}//` + _tinyURL + val;
    }

    return imgUrl !== ""
      ? imgUrl
      : "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";
  };

  const childrenLayout = useMemo(() => {
    return children.map((layout, idx) => {
      const isSelected = selectionBlock?.i === layout.i;
      const isPortrait = layout.h > layout.w;
      const controlAttribute = layout.template.attribute as IControlProperty;

      return (
        <div
          className={`custom-grid child-grid`}
          title={layout.i}
          key={layout.i}
          data-grid={{
            i: layout.i,
            x: layout.x,
            y: layout.y,
            w: layout.w,
            h: layout.h,
            minW: layout?.minW,
            minH: layout?.minH,
            static: layout.static,
            resizeHandles: ["n", "ne", "nw", "s", "sw", "se"],
            isDraggable: isSelected, // Make it draggable if selected
            isResizable: layout.template?.type === "tb" ? false : isSelected, // Make it resizable if selected
            isBounded: true,
          }}
          style={{
            display: "flex",
            border: `${selectionBlock?.i === layout.i ? " 2px solid" : ""}`,
            alignItems: controlAttribute?.vAlign,
            justifyContent: controlAttribute?.hAlign,
            color: controlAttribute?.color,
            fontFamily: controlAttribute?.fontFamily,
            flexDirection:
              layout.template.type === "staticImage"
                ? isPortrait
                  ? "column"
                  : "row"
                : "unset",
            textDecoration: controlAttribute?.isUnderlined
              ? "underline"
              : "unset",
            fontSize: controlAttribute?.fontSize,
            fontStyle: controlAttribute?.isItalic ? "italic" : "normal",
            fontWeight: controlAttribute?.isBold ? 700 : 100,
            overflow:
              selectionBlock?.template.type === "tb" ? "hidden" : "unset",
            textAlign:
              controlAttribute?.hAlign === "flex-start"
                ? "left"
                : controlAttribute?.hAlign === "flex-end"
                ? "right"
                : "center",
          }}
          onClick={() => {
            onSelectChange(layout);
          }}
        >
          {renderContent(layout)}
        </div>
      );
    });
  }, [paperLayout, selectionBlock]);

  return (
    <>
      {" "}
      <GridLayout
        className="inner-layout"
        style={{ minHeight: rowHeight }}
        cols={Math.round(paperWidth / pageProperty.gridSize)}
        rowHeight={pageProperty.gridSize}
        isDroppable={true}
        isResizable
        isDraggable
        width={paperWidth}
        containerPadding={[pageProperty.padding, 0]}
        margin={[0, 0]}
        allowOverlap
        preventCollision
        isBounded
        layout={children}
        onDragStop={(layouts) => {
          setPaperLayout([
            ...paperLayout.map((e) => {
              if (e.i === name) {
                e.children = e.children?.map((child) => {
                  const layout = layouts.find((layout) => layout.i === child.i);
                  if (layout) {
                    child.x = layout.x;
                    child.y = layout.y;
                  }
                  return child;
                });
              }
              return e;
            }),
          ]);
        }}
        onResizeStop={(layouts) => {
          if (!pageProperty.isDragingFromOutSide) {
            setPaperLayout([
              ...paperLayout.map((e) => {
                if (e.i === name) {
                  e.children = e.children?.map((child) => {
                    const layout = layouts.find(
                      (layout) => layout.i === child.i
                    );

                    if (layout) {
                      child.w = layout.w;
                      child.h = layout.h;
                    }

                    return child;
                  });
                }
                return e;
              }),
            ]);
          }
        }}
        onDrop={(layouts, item: any, e: any) => {
          try {
            const data: ILayoutTemplate = JSON.parse(
              e.dataTransfer.getData("application/json")
            );
            addLayout(name, item, data);
          } catch (error) {
            console.error(error);
          }
        }}
      >
        {childrenLayout}
      </GridLayout>
    </>
  );
};
export default DropableComponent;
