import React, { createContext, useState, useEffect } from "react";
import { GetMasterDataByTypeNames } from "../Services/MasterDataService";
import { GetNavbarMenu } from "../Services/NavBarMenuService";
import { INavigationMenu } from "../IRequestModel/INavagationModel";
import { getDataLocal } from "../Helper/localStoreFunction";
import { LocalStoreType } from "../constants/local-type";
import { useUserContext } from "./UserContext";

type IMenuEmailContextType = {
  updateMenuEmail: (masterData: INavigationMenu[]) => void;
  getMenuEmail: () => INavigationMenu[]
};

export const MenuEmailContext =
  createContext<IMenuEmailContextType | null>(null);

export const MenuEmailContextProvider: React.FC = (props) => {
  const [menuEmail, setMenuEmail] = useState<INavigationMenu[]>([]);
  const [userData, setUserData] = useUserContext();
  useEffect(() => {
    if (!userData) return;
    fecthData();
  }, [userData]);

  const fecthData = async () => {
    const empData = userData;
    const menus = await GetNavbarMenu(empData.Email);
    setMenuEmail(menus);
  };
  
  const updateMenuEmail = (masterMenuEmail: any[]) => {
    if (masterMenuEmail.length > 0) {
      setMenuEmail(masterMenuEmail);
    }
  };

  const getMenuEmail =  () => {
    return menuEmail
  };

  return (
    <MenuEmailContext.Provider
      value={{ updateMenuEmail, getMenuEmail }}
    >
      {props.children}
    </MenuEmailContext.Provider>
  );
};
