import {
  AutoComplete,
  Col,
  DatePicker,
  Input,
  Row,
  Table,
  TablePaginationConfig,
  Tag,
  Empty
} from "antd";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import "../../AntdControlComponent/TableComponent/TableComponentCSS.css";
import "../Table.css";
import SortingButton from "../../ButtonComponents/SortingButton";
import { sorterFunc } from "../../../Helper/SortingFunction";
import moment, { Moment } from "moment";
import { Button } from "primereact/button";
import {
  exportExcel,
  reconStructionForExport,
} from "../../../Helper/ExportExcel";
import { updateDynamic } from "../../../Services/DynamicService";
import { useLocation } from "react-router";
import { useUserContext } from "../../../Context/UserContext";
import { IPaging } from "../../../IRequestModel/IPaginationOptionModel";
import useMasterSetting from "../../../hooks/useMasterSetting";
import { ColumnsType } from "antd/lib/table";
import {
  checkedForDataTypes,
  filterData,
  // filterData,
  formatData,
  getColumnSearchProps2,
} from "../../../Helper/TableHelper";
import { ColumnFilterItem, FilterValue } from "antd/lib/table/interface";
import { data } from "jquery";
import {
  Column,
  ColumnFilterElementTemplateOptions,
  ColumnFilterElementType,
} from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
const { RangePicker } = DatePicker;

type Props = {
  tableName: string;
  dataSource: any[];
  customColumns?: any[];
  setItemsCount?: (value: string) => void;
  canExport: boolean;
  setLoad?: (value: boolean) => void;
  actionBodyTemplate?: any;
  customImportFileButton?: any;
  rowClickAction?: any;
  canEdit?: any;
  toast?: any;
  loading?: boolean;
  reloadData?: (apiName: string) => void;
  VersionTempVCProps?: any;
  paginationOption?: TablePaginationConfig;
  onPageChange?: (paginationValue: IPaging) => void;
};

export type FilterComponentProps = {
  colProp: ColumnsProps;
  options: ColumnFilterElementTemplateOptions;
  filtersState: any;
  dataSource: any[];
  allColumnProps: ColumnsProps[];
};

export type ColumnsProps = {
  key: string;
  label: string;
  type: string;
  option: any[];
  maxNumberValue?: number;
  decimal?: number;
  symbol?: string;
  dateFormat?: string;
};

const DynamicTableV3 = ({
  tableName,
  dataSource,
  customColumns,
  canExport,
  setItemsCount,
  setLoad,
  canEdit,
  actionBodyTemplate,
  rowClickAction,
  reloadData,
  customImportFileButton,
  loading,
  paginationOption,
  onPageChange,
}: Props) => {
  const dataKey = customColumns
    ? customColumns.map((e) => e.key)
    : Object.keys(dataSource[0] ?? {});

  const [hoverAction, setHoverAction] = useState<boolean>(false);
  const [columnsProps, setColumnsProps] = useState<ColumnsProps[]>([]);
  const [columnsPropsFilterData, setColumnsPropFiltersData] = useState<ColumnsProps[]>([]);

  const [searchedData, setSearchedData] = useState<any[]>([]);
  const { masterSetting, getMasterSetting } = useMasterSetting();
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [showCols, setShowCols] = useState<any>();
  const [userData] = useUserContext();
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [filtersState, setFiltersState] = useState({});
  const [filtersStateValue, setFiltersStateValue] = useState({});

  useEffect(() => {
    initFunc();
    if (customColumns) {
      setSearchedData(
        dataSource.map((e, idx) => {
          e.rowIdx = idx;
          return e;
        })
      );
    } else {
      setSearchedData(dataSource);
    }

  }, [dataSource]);

  const initFunc = async (selectedCols?: string[]) => {

    let keyTypes: ColumnsProps[] = [];
    let _selectedCols: string[] = [];
    const _masterSetting: any = await getMasterSetting();

    if (customColumns) {
      keyTypes = [...checkedForDataTypes(dataKey, dataSource)];

      keyTypes.forEach((e) => {
        const customCol = customColumns.find(
          (customCol) => e.key === customCol.key
        );

        if (customCol) {
          _selectedCols.push(e.key);
          e.label = customCol.label;
          e.symbol = customCol.symbol;
          e.decimal = Number(customCol.Decimal ?? 2);
          e.type =
            customCol.FieldTypeFilterDynamic === "c"
              ? "number"
              : customCol.FieldTypeFilterDynamic === "d"
                ? "date"
                : customCol.FieldTypeFilterStatic === "Datetime"
                  ? "datetime"
                  : customCol.FieldTypeFilterDynamic === "at"
                    ? "file"
                    : "string";

          if (e.type === "date" && _masterSetting?.FormatDate?.Value2) {
            e.dateFormat = _masterSetting?.FormatDate?.Value2;
          } else if (customCol.FieldTypeFilterDynamic === "d") {
            const symbol =
              customCol.symbol && customCol.symbol !== ""
                ? customCol.symbol
                : " ";
            e.dateFormat = "DD" + symbol + "MMM" + symbol + "YYYY";
          } else if (e.type === "datetime") {
            e.dateFormat = _masterSetting?.FormatDate?.Value1;
          }

          if (e.type === "number") {
            const optionValue: any = e.option.map((option) => {
              return option?.value
                ? Number(option?.value?.replaceAll(",", ""))
                : 0;
            });
            e.maxNumberValue = Math.max(optionValue);
          }
        }
      });

      if (!selectedCols) {
        selectedCols = _selectedCols.filter(
          (key: string) => !key?.toLowerCase()?.includes("id")
        );
      }
    } else {
      if (actionBodyTemplate) {
        keyTypes = [
          ...checkedForDataTypes(
            dataKey,
            dataSource,
            _masterSetting?.FormatDate?.Value1?.replaceAll("d", "D")
          ),
        ];
      } else {
        keyTypes = [
          ...checkedForDataTypes(
            dataKey,
            dataSource,
            _masterSetting?.FormatDate?.Value1?.replaceAll("d", "D")
          ),
        ];
      }

      formatData(dataSource, keyTypes);

      if (!selectedCols) {
        selectedCols = dataKey.filter(
          (key: string) => !key?.toLowerCase()?.includes("id")
        );
      }
    }

    let initFilter: any = {};
    const lastResult =
      keyTypes.filter(
        (e) =>
          selectedCols?.includes(e.key) &&
          !["isEditing", "isPublishVersion"].includes(e.key)
      ) ?? [];

    lastResult.forEach((colProp) => {
      if (colProp.type === "string") {
        initFilter[colProp.key] = {
          value: null,
          matchMode: FilterMatchMode.IN,
        };
      } else if (colProp.type === "date" || colProp.type === "datetime") {
        initFilter[colProp.key] = {
          value: null,
          matchMode: FilterMatchMode.BETWEEN,
        };
      } else if (colProp.type === "number") {
        initFilter[colProp.key] = {
          value: null,
          matchMode: FilterMatchMode.BETWEEN,
        };
      }
    });
    setFiltersState({ ...initFilter });
    setFiltersStateValue({ ...initFilter });

    setColumnsProps(
      keyTypes.filter(
        (e) =>
          selectedCols?.includes(e.key) &&
          !["isEditing", "isPublishVersion"].includes(e.key)
      ) ?? []
    );
    setColumnsPropFiltersData(keyTypes.filter((e) => selectedCols?.includes(e.key) && !["isEditing", "isPublishVersion"].includes(e.key)) ?? []);

    setShowCols(selectedCols ?? []);
  };

  const sorterDate = (a: Moment | null, b: Moment | null) => {
    if (a === null && b === null) return 0;
    if (a === null) return -1;
    if (b === null) return 1;

    if (!a.isValid() && !b.isValid()) return 0;
    if (!a.isValid()) return -1;
    if (!b.isValid()) return 1;

    if (a.isBefore(b)) return -1;
    if (a.isAfter(b)) return 1;
    return 0;
  };

  const renderColumnBody = (value: any, colProp: ColumnsProps, record: any) => {
    switch (colProp.type) {
      case "number":
        if (colProp.symbol) {
          return value
            ? Number(value.toString()?.replaceAll(",", "")).toLocaleString(
              undefined,
              { minimumFractionDigits: colProp.decimal }
            ) + colProp.symbol
            : "";
        } else {
          return value
            ? Number(value.toString()?.replaceAll(",", "")).toLocaleString(
              undefined,
              { minimumFractionDigits: colProp.decimal }
            )
            : "";
        }

      case "date":
        const date = value as Moment;
        try {
          return date?.isValid() ? date.format(colProp.dateFormat) : "";
        } catch (error) {
          return date;
        }
      case "datetime":
        const datetime = value as Moment;
        try {
          return datetime?.isValid()
            ? datetime.format(colProp.dateFormat?.replaceAll("d", "D"))
            : "";
        } catch (error) {
          return datetime;
        }

      case "file":
        return value;

      default:
        if (colProp.key === "TemplateName") {
          return (
            <>
              {value.toString()}
              {record.isPublishVersion === "true" && (
                <Tag color="#007bff" style={{ borderRadius: "6px" }}>
                  Publish
                </Tag>
              )}
              {record.isEditing === "true" && (
                <Tag color="#dc3545" style={{ borderRadius: "6px" }}>
                  Editing
                </Tag>
              )}
            </>
          );
        } else if (colProp.key === "AmountFrom_AmountTo") {
          const dd = value?.split(":");
          let htmlBody: any[] = [];
          dd.forEach((data: any, idx: number) => {
            if (idx < 4) {
              htmlBody.push(<p>{data}</p>);
            } else {
              htmlBody.push(<p>.......</p>);
            }
          });
          return <>{htmlBody}</>;
        } else {
          return value?.toString();
        }
    }
  };

  const onExportClick = async () => {
    setExportLoading(true);
    const columnHeader = columnsProps.map((col) => ({
      label: col.label,
      key: col.key,
    }));
    await exportExcel(
      columnHeader,
      tableName,
      reconStructionForExport(searchedData, columnsProps),
      setExportLoading
    );
    setExportLoading(false);
  };

  const importExcel = async (e: any) => {
    const file = e.target.files[0];
    try {
      import("xlsx").then((xlsx) => {
        const reader = new FileReader();
        try {
          reader.onload = async (e: any) => {
            const wb = xlsx.read(e.target.result, { type: "array" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
            const cols: any = data[0];
            data.shift();
            let _importedData = data.map((d: any) => {
              return cols.reduce((obj: any, c: any, i: any) => {
                obj[c] = d[i];
                return obj;
              }, {});
            });
            if (_importedData.length !== 0) {
              let res = null;
              for (let i = 0; i < _importedData.length; i++) {
                const element = _importedData[i];
                var apiNamereplace = tableName?.replace("/GetAll", "");
                if (
                  !_importedData[i][apiNamereplace + "Id"] ||
                  _importedData[i][apiNamereplace + "Id"] === ""
                ) {
                  _importedData[i].CreatedBy = userData.EmployeeId.toString();
                  _importedData[i].ModifiedBy = userData.EmployeeId.toString();
                } else {
                  _importedData[i].ModifiedBy = userData.EmployeeId.toString();
                }
                setImportLoading(true);

                res = await updateDynamic(apiNamereplace, element);
              }

              if (res.result === "success") {
                if (reloadData) {
                  reloadData(tableName);
                }
              }
              setImportLoading(false);
            }
          };

          reader.readAsArrayBuffer(file);
        } catch (error) {
          setImportLoading(false);
        }
      });
    } catch (error) {
      setImportLoading(false);
    }
    e.target.value = null;
  };

  const getWitdthFormText = (str: string) => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var width: number = 0;

    if (ctx) {
      ctx.font = "14px ";
      width = ctx.measureText(str).width;
    }
    return width;
  };

  useEffect(() => {
    setItemsCount && setItemsCount(showCols?.length ? dataSource.length.toString() : "0");
  }, [dataSource, showCols?.length]);

  return (
    <div className="dynamictable-container">
      <Row>
        <Col span={12} style={{ padding: 5 }}>
          <MultiSelect
            value={showCols}
            options={
              customColumns ?? dataKey.filter((e) => !e.toLowerCase().endsWith("id"))
            }
            onChange={(e) => {
              let selectedColumns = e.value;
              let orderedSelectedColumns = columnsPropsFilterData.filter((col: any) =>
                selectedColumns?.some((sCol: any) => sCol === col?.key)
              );
              setColumnsProps(orderedSelectedColumns);
              setShowCols(selectedColumns);
            }}
            showClear
            placeholder="Select columns"
            style={{ width: "50%" }}
            optionLabel={customColumns ? "label" : undefined}
            optionValue={customColumns ? "key" : undefined}
          />
        </Col>
        <Col span={8}></Col>
        {canExport && (
          <Col span={4} style={{ padding: 5 }}>
            <Button
              label="Export to excel"
              icon="pi pi-file-excel"
              data-pr-tooltip="XLS"
              loading={exportLoading}
              onClick={onExportClick}
              style={{
                height: "38px",
                background: " #28a745",
                border: "1px solid #28a745",
                color: "#ffffff",
                fontSize: "1em",
                borderRadius: "6px",
                margin: "0px 11px 5px 0px", 
                justifyContent: "center",
                width:
                  !location.pathname.includes("DynamicReport") &&
                    !location.search?.includes("DelegateList") &&
                    !location.search?.includes("TimeStamp")
                    ? "unset"
                    : "100%",
              }}
            />
            {!location.pathname.includes("DynamicReport") &&
              !location.search?.includes("DelegateList") &&
              !location.search?.includes("TimeStamp") && (
                <>
                  {customImportFileButton ? (
                    customImportFileButton
                  ) : (
                    <>
                      <input
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={importExcel}
                        style={{ display: "none" }}
                        id="file"
                      />
                      <label
                        htmlFor="file"
                        style={{
                          height: "38px",
                          background: " #28a745",
                          border: "1px solid #28a745",
                          color: "#ffffff",
                          fontSize: "1em",
                          borderRadius: "6px",
                          padding: "0px 5px",
                        }}
                        className="import"
                      >
                        <span className="import-span">
                          <i
                            className="pi pi-file-excel"
                            style={{ margin: "3px 5px 5px 5px" }}
                          ></i>
                          Import from excel
                        </span>
                      </label>
                    </>
                  )}
                </>
              )}
          </Col>
        )}
        <Col span={24}>
          <DataTable
            value={showCols?.length ? searchedData : []}
            stripedRows
            paginator
            rows={10}
            dataKey={tableName + "Id"}
            filters={filtersState}
            filterDisplay="row"
            scrollable={showCols?.length ? true : false}
            removableSort
            onRowClick={(e) => {
              if (rowClickAction && !hoverAction)
                rowClickAction(e.data, e.index, e);
            }}
            onFilter={(e) => {
              const resData = filterData(dataSource, e.filters, columnsProps);
              if (setItemsCount) {
                setItemsCount(resData.length.toLocaleString());
              }
              setSearchedData(resData);
              setFiltersStateValue({ ...e.filters });
            }}
            scrollHeight="100vh"
            loading={loading}
            emptyMessage={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            size="small"
          >
            {canEdit && (
              <Column
                columnKey={"action"}
                frozen
                style={{ width: "50px", flex: "unset" }}
                body={(record) => (
                  <div
                    onMouseOver={(e: any) => setHoverAction(true)}
                    onMouseLeave={(e: any) => setHoverAction(false)}
                  >
                    {actionBodyTemplate(record)}
                  </div>
                )}
              />
            )}

            {columnsProps.map((colProp) => {
              const colWidth = Math.max(
                getWitdthFormText(colProp.label) * 3 + 30,
                getWitdthFormText(dataSource[0][colProp.key])
              );
              return (
                <Column
                  key={colProp.key}
                  columnKey={colProp.key}
                  field={colProp.key}
                  header={colProp.label}
                  alignHeader={"left"}
                  headerClassName="custom"
                  align={colProp.type === "number" ? "right" : "left"}
                  sortable
                  style={{
                    minWidth: colWidth,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  filter
                  filterHeaderStyle={{ padding: 5 }}
                  filterElement={(option) =>
                    getColumnSearchProps2(
                      option,
                      colProp,
                      dataSource,
                      columnsProps,
                      filtersStateValue
                    )
                  }
                  body={(rowData) =>
                    renderColumnBody(rowData[colProp.key], colProp, rowData)
                  }
                />
              );
            })}
          </DataTable>
        </Col>
      </Row>
    </div>
  );
};

export default DynamicTableV3;
