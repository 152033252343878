import React, { useEffect } from "react";
import { enumToObject } from "../../../../Helper/LogicHelper";
import { ILogicCondition } from "../../../../IRequestModel/ILogicModel";
import { Space, Form, Select, Input } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { LogicOperatorType } from "../../../../constants/logic-type";
import { useForm } from "antd/lib/form/Form";
import { LogicFormProps, refInfors } from "../JsonLogicForm";
import "./formLogic.css";
const { Option } = Select;

type Props = {
  key: number;
  name: number;
  remove: (index: number) => void;
  onRowChange: (rowData: {
    [name: number]: {
      action: string;
      condition: string;
      field: string;
      label: string;
      objectkey: string;
    };
  }) => void;
  defaultValue?: {
    action: string;
    condition: string;
    field: string;
    label: string;
    objectkey: string;
    value: string;
  };
};

const DataSourceRelateConditionRow = ({
  key,
  name,
  remove,
  onRowChange,
  defaultValue,
}: Props) => {
  const [form] = useForm();
  const operators = enumToObject(LogicOperatorType);
  useEffect(() => {
    form.setFieldsValue({
      [name]: defaultValue
        ? defaultValue
        : {
            field: null,
            operator: "=",
            label: null,
            comparewith: null,
          },
    });
  }, []);

  const onValueChange = (changedValues: any, allValues: any) => {
    if (changedValues[name].objectkey) {
      allValues[name].label = "";
    } else if (changedValues[name].label) {
      allValues[name].objectkey = null;
    }

    onRowChange(allValues);
  };

  return (
    <>
      <Form form={form} onValuesChange={onValueChange}>
        <Space
          className="form-logic-conditions"
          key={key}
          size={"middle"}
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          // }}
          align="start"
        >
          <Form.Item
            name={[name, "field"]}
            rules={[{ required: true, message: "Missing field" }]}
          >
            <Input placeholder="Field" style={{ minWidth: 200 }} />
          </Form.Item>

          <Form.Item name={[name, "action"]}>
            <Select
              placeholder="Action"
              style={{ minWidth: 200 }}
              showSearch
              options={[
                { label: "AND", value: "AND" },
                { label: "OR", value: "OR" },
              ]}
            />
          </Form.Item>
          <Form.Item
            name={[name, "Condition"]}
            validateTrigger={["onChange", "onBlur"]}
            rules={[{ required: true, message: "Missing condition" }]}
          >
            <Select
              placeholder="Condition"
              style={{ minWidth: 200 }}
              showSearch
              allowClear
            >
              {Object.entries(operators)
                .filter(
                  ([value, label], index) =>
                    value !== "contain" &&
                    value !== "startwith" &&
                    value !== "endwith"
                )
                .map(([value, label], index) => (
                  <Option key={index} value={value}>
                    {label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={[name, "value"]}
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                validator: (_, value) => {
                  form.setFieldsValue({
                    [name]: { objectkey: null, label: null },
                  });

                  if (
                    !value &&
                    !form.getFieldValue(name)["objectkey"] &&
                    !form.getFieldValue(name)["label"]
                  ) {
                    return Promise.reject(
                      "label,value or comparewith cannot be empty"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="value" />
          </Form.Item>
          <Form.Item
            name={[name, "label"]}
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                validator: (_, value) => {
                  form.setFieldsValue({
                    [name]: { objectkey: null, value: null },
                  });

                  if (
                    !value &&
                    !form.getFieldValue(name)["objectkey"] &&
                    !form.getFieldValue(name)["value"]
                  ) {
                    return Promise.reject(
                      "label,value or comparewith cannot be empty"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="label" />
          </Form.Item>
          <Form.Item
            name={[name, "objectkey"]}
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                validator: (_, value) => {
                  form.setFieldsValue({ [name]: { label: null, value: null } });

                  if (
                    !value &&
                    !form.getFieldValue(name)["label"] &&
                    !form.getFieldValue(name)["value"]
                  ) {
                    return Promise.reject(
                      "label,value or comparewith cannot be empty"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              placeholder="Compare with informations"
              style={{ minWidth: 200 }}
              allowClear
              showSearch
            >
              {refInfors.map((item, index) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <MinusCircleOutlined onClick={() => remove(name)} rev={undefined} />
        </Space>
      </Form>
    </>
  );
};

export default DataSourceRelateConditionRow;
