import {
  Space,
  Button,
  AutoComplete,
  DatePicker,
  Divider,
  Slider,
  InputNumber,
  Input,
} from "antd";
import { ColumnType } from "antd/lib/table";
import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { ColumnsProps } from "../components/TableComponents/DynamicTableV2/DynamicTable";
import moment, { Moment } from "moment";
import SearchTextInTable from "../components/TableComponents/DynamicTableV2/SearchTextInTable";
import { FilterValue } from "antd/lib/table/interface";
import StringFilterComponent from "../components/TableComponents/DynamicTableV3/FilterBody/StringFilterComponent";
import NumberFilterComponent from "../components/TableComponents/DynamicTableV3/FilterBody/NumberFilterComponent";
import DataFilterComponent from "../components/TableComponents/DynamicTableV3/FilterBody/DataFilterComponent";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
const { RangePicker } = DatePicker;
export const getColumnSearchProps = (
  colProp: ColumnsProps,
  dataSource: any[],
  filterState: Record<string, FilterValue | null>,
  allColumnProps: ColumnsProps[]
): ColumnType<any> => {
  if (colProp.type === "date" || colProp.type === "datetime") {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div
            style={{
              padding: 5,
              gap: 5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <RangePicker
              value={selectedKeys[0] as any}
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                "This Year": [moment().startOf("year"), moment().endOf("year")],
              }}
              format={"DD MMM YYYY"}
              onChange={(e) => {
                if (e) {
                  setSelectedKeys([e as any]);
                } else {
                  clearFilters && clearFilters();
                }
              }}
            />
            <div
              className="ant-table-filter-dropdown-btns"
              style={{ padding: 5 }}
            >
              <Button
                onClick={() => clearFilters && clearFilters()}
                size="small"
                disabled={selectedKeys.length === 0}
                type="link"
              >
                Reset
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  confirm({ closeDropdown: true });
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      },
      onFilter: (value, record) => {
        const selectedVal: Moment[] = value as any;
        const rowData: Moment = record[colProp.key];
        if (!rowData) return false;
        if (colProp.type === "date") {
          return rowData
            .add(1, "seconds")
            .isBetween(
              selectedVal[0].startOf("day"),
              selectedVal[1].endOf("day")
            );
        } else {
          return rowData.isBetween(
            selectedVal[0].startOf("day"),
            selectedVal[1].endOf("day")
          );
        }
      },
    };
  } else if (colProp.type === "number") {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        const optionValue: any = dataSource.map((option) => {
          return option
            ? Number(String(option[colProp.key])?.replaceAll(",", ""))
            : 0;
        });

        const val: [number, number] = selectedKeys[0] as any;

        const minOption = Math.min(...optionValue);
        const maxOption = Math.max(...optionValue);
        const minVal = val ? val[0] : minOption;
        const maxVal = val ? val[1] : maxOption;

        const decimal = colProp.decimal ?? 2;
        const decimalStep = Math.pow(10, -decimal);
        return (
          <div
            style={{
              padding: 5,
              gap: 5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: 300,
              textAlign: "center",
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Slider
              range
              defaultValue={[0, maxOption]}
              value={[minVal, maxVal]}
              step={decimalStep}
              min={minOption === maxOption ? 0 : minOption}
              max={maxOption}
              onChange={(e) => {
                setSelectedKeys([e as any]);
              }}
            />
            <div
              style={{
                padding: 5,
                gap: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <InputNumber
                min={minOption}
                max={maxOption}
                style={{ margin: "0 16px" }}
                value={minVal}
                step={decimalStep.toString()}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                stringMode
                controls={false}
                onChange={(e) => {
                  if (e) {
                    setSelectedKeys([[Number(e), maxVal] as any]);
                  }
                }}
              />
              -
              <InputNumber
                min={minOption}
                max={maxOption}
                style={{ margin: "0 16px" }}
                controls={false}
                value={maxVal}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                step={decimalStep.toString()}
                stringMode
                onChange={(e) => {
                  if (e) {
                    setSelectedKeys([[minVal, Number(e)] as any]);
                  }
                }}
              />
            </div>
            <div
              className="ant-table-filter-dropdown-btns"
              style={{ padding: 5 }}
            >
              <Button
                onClick={() => clearFilters && clearFilters()}
                size="small"
                disabled={selectedKeys.length === 0}
                type="link"
              >
                Reset
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  confirm({ closeDropdown: true });
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      },
      onFilter: (value: any, record) => {
        const recordVal = Number(record[colProp.key]);

        return recordVal >= value[0] && recordVal <= value[1];
      },
    };
  } else if (colProp.type === "string") {
    return {
      filterDropdown: (filterDropDownProps) => {
        return (
          <div
            style={{
              padding: 5,
              gap: 5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <SearchTextInTable
              colProp={colProp}
              option={colProp.option}
              filterDropDownProps={filterDropDownProps}
              columnLabel={colProp.label}
              filterState={filterState}
              dataSource={dataSource}
              allColumnProps={allColumnProps}
            />
            <div
              className="ant-table-filter-dropdown-btns"
              style={{ padding: 5 }}
            >
              <div></div>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  filterDropDownProps.confirm({ closeDropdown: true });
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      },
      onFilter: (value, record) => {
        if (typeof value === "string") {
          return (
            record[colProp.key]?.toString().toLowerCase() ===
            (value as string).toString()?.toLowerCase()
          );
        } else {
          return record[colProp.key] == value.toString();
        }
      },
    };
  } else {
    return { sorter: false };
  }
};

export const checkedForDataTypes = (
  dataKeys: string[],
  dataSource: any[],
  masterFormat: string = "DD MMM YYYY"
) => {
  let keyTypes: ColumnsProps[] = [];
  let foundKeys: Set<string> = new Set();

  let validKeys = dataKeys.filter((key) => {
    return dataSource.some((row) => row[key] !== null);
  });

  for (let i = 0; i < dataSource.length; i++) {
    for (let key of dataKeys) {
      let dataType = "string";

      if (["createddate", "modifieddate"].includes(key.toLowerCase())) {
        dataType = "datetime";
      } else if (key.toLowerCase().includes("date")) {
        dataType = "date";
      } else if (key === "AttachFile") {
        dataType = "file";
      }

      if (!foundKeys.has(key)) {
        keyTypes.push({
          key: key,
          label: key,
          option: [],
          type: dataType,
          dateFormat:
            dataType === "date" || dataType === "datetime"
              ? masterFormat
              : undefined,
        });
        foundKeys.add(key);
      }
    }

    if (foundKeys.size === validKeys.length) {
      break;
    }
  }

  return keyTypes;
};

export const formatData = async (
  dataSource: any[],
  dataKey: ColumnsProps[]
) => {
  let newData = dataSource;
  dataKey.forEach((colProp) => {
    if (colProp.type === "file") return;
    if (["date", "datetime"].includes(colProp.type.toLowerCase())) {
      newData.forEach((e: any, index: number) => {
        e[colProp.key] = moment(e[colProp.key]);
      });
    } else {
      newData.forEach((e: any, index: number) => {
        e[colProp.key] = e[colProp.key]?.toString();
      });
    }
  });
  return newData;
};

export const filterData = (
  data: any[],
  filters: { [x: string]: any },
  colProps?: ColumnsProps[],
  colProp?: ColumnsProps
) => {

  return data.filter((item: any) => {

    return Object.keys(filters).every((key) => {

      const filter = filters[key];

      const _colProp = colProp
        ? colProp
        : colProps?.find((col) => col.key === key);
      const data = item[key];

      if (!filter.value) return true;
      if (!_colProp) return true;
      if (data === undefined || data === null) return true;

      switch (filter.matchMode) {
        case "in": {
          const { searchText, checkedList } = filter.value;
          const _searchText = searchText?.toLowerCase();

          return _searchText !== "" && checkedList.length > 0 ? checkedList?.includes(data?.toLowerCase()) && data?.toLowerCase().includes(_searchText) : checkedList?.length > 0 && _searchText === "" ? checkedList?.includes(data) : data?.toLowerCase().includes(_searchText);
        }
        case "between": {
          const selectedVal: any[] = filter.value;
          const rowData: any = data;
          if (!rowData) return false;
          if (_colProp.type === "date") {
            return rowData
              .add(1, "seconds")
              .isBetween(
                selectedVal[0].startOf("day"),
                selectedVal[1].endOf("day")
              );
          } else if (_colProp.type === "date") {
            return rowData.isBetween(
              selectedVal[0].startOf("day"),
              selectedVal[1].endOf("day")
            );
          } else {
            const recordVal = Number(data);
            return recordVal >= selectedVal[0] && recordVal <= selectedVal[1];
          }
        }
        default:
          return true;
      }
    });
  });
};

export const getColumnSearchProps2 = (
  options: ColumnFilterElementTemplateOptions,
  colProp: ColumnsProps,
  dataSource: any,
  allColumnProps: ColumnsProps[],
  filtersState: any
) => {
  if (colProp.type === "date" || colProp.type === "datetime") {
    return (
      <DataFilterComponent
        colProp={colProp}
        options={options}
        filtersState={filtersState}
        allColumnProps={allColumnProps}
        dataSource={dataSource}
      />
    );
  } else if (colProp.type === "number") {
    return (
      <NumberFilterComponent
        colProp={colProp}
        options={options}
        filtersState={filtersState}
        allColumnProps={allColumnProps}
        dataSource={dataSource}
      />
    );
  } else if (colProp.type === "string") {
    return (
      <div
        style={{
          padding: 5,
          gap: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <StringFilterComponent
          colProp={colProp}
          options={options}
          filtersState={filtersState}
          allColumnProps={allColumnProps}
          dataSource={dataSource}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
