import { Form, InputNumber } from "antd";
import React from "react";
import ComponentLabel from "../../ControlComponents/ComponentLabel";
import { Col } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { TableInputProps } from "../TableComponent/TableComponent";
import { replaceSpecialChar } from "../../../Helper/ReplaceSpecialChar";
import useAlert from "../../../hooks/useAlert";
import $ from "jquery";
import { checkRequireFromTemplate } from "../../../Helper/RequestScreenHelper";

type InputProps = {
  template: any;
  data: any;
  rowIdx: number;
  colIdx: number;
  col?: any;
  colText?: number;
  colAction?: number;
  name: string;
  control: any;
  onControlChange: (
    controlTemplate: any,
    controlValue: any,
    controlUpdate: any
  ) => any;
  controlUpdate: any;
  canEditDoc: boolean;
  checkActionPage: string;
  buttonType: string;
};

type InputNumberProps = {
  ref?: any;
  id?: string;
  template: any;
  value?: any;
  status?: any;
  onChange: (value: any) => void;
  isEditing?: any;
  canEditDoc: boolean;
  toggleEdit?: any;
  checkActionPage: string;
};

export const InputNumberComponent: React.FC<InputNumberProps> = ({
  id,
  template,
  value,
  status,
  onChange,
  canEditDoc,
  isEditing,
  toggleEdit,
  ref,
}) => {
  return (
    <>
      <InputNumber
        id={replaceSpecialChar(id ?? "")}
        // tabIndex={parseInt(idx)}
        autoFocus={isEditing}
        size={"large"}
        className={`input-component table-input ${template.attribute.align === "r" ? "text-right" : "text-left"
          } ${template.attribute.symbol !== ""
            ? template.attribute.symbolPosition == "E" && "symbol-after"
            : template.attribute.symbolPosition == "B" && "symbol-before"
          }`}
        placeholder={template.attribute.description}
        addonAfter={
          template.attribute.symbolPosition == "E" && template.attribute.symbol
        }
        addonBefore={
          template.attribute.symbolPosition == "B" && template.attribute.symbol
        }
        controls={false}
        formatter={(_value) => {
          if (value === null || _value === "") {
            return "";
          }
          if (isNaN(Number(_value.replaceAll(",", "")))) {
            return "";
          } else {
            if (template.attribute.useComma === "Y") {
              return Number(_value.replaceAll(",", "")).toLocaleString(
                "en-US",
                {
                  minimumFractionDigits: Number(template.attribute.decimal),
                }
              );
            } else {
              return String(
                Number(_value.replaceAll(",", "")).toFixed(
                  Number(template.attribute.decimal)
                )
              );
            }
          }
        }}
        style={{
          width: "100%",
        }}
        disabled={!canEditDoc || template.attribute.readonly === "Y"}
        // readOnly={!canEditDoc || template.attribute.readonly === "Y"}
        value={value}
        defaultValue={template.attribute.default}
        onFocus={(e) => {
          if (toggleEdit) {
            toggleEdit();
          }
          e.target.select();
        }}
        onBlur={(e) => {
          e.preventDefault();
          let _value = "";
          if (
            e.target.value != "" &&
            !isNaN(Number(e.target.value.replaceAll(",", "")))
          ) {
            _value = Number(e.target.value.replaceAll(",", "")).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: Number(template.attribute.decimal),
                maximumFractionDigits: Number(template.attribute.decimal),
              }
            );
          }
          onChange(_value.replaceAll(",", ""));
        }}
        onKeyDown={(e) => {
          if (e.code?.toLocaleLowerCase() === "tab") {
            setTimeout(() => {
              /**
               * หลังจากที่มีการ set state จะเรียกดูค่า tabindex ของ element ปัจจุบันเพื่อที่จะเพิ่มค่า tabindex อีก 1 เพื่อที่จะ focus input ถัดไป
               */
              let curIndex = document
                .getElementById(id!)
                ?.getAttribute("tabindex");
              let nxtIdx = parseInt(curIndex!) + 1;
              $(
                "input[tabindex=" +
                nxtIdx +
                "], textarea[tabindex=" +
                nxtIdx +
                "]"
              )
                .focus()
                .click();
            }, 300);
          }
        }}
        onPressEnter={(e: any) => {
          e.target.blur();
        }}
        status={status}
        ref={ref}
      />
    </>
  );
};

export const InputNumberControlComponent: React.FC<InputProps> = ({
  colIdx,
  rowIdx,
  control,
  name,
  data,
  template,
  col,
  colAction,
  colText,
  onControlChange,
  canEditDoc,
  checkActionPage,
  controlUpdate,
  buttonType,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={
        canEditDoc
          ? {
            required: checkRequireFromTemplate(template, buttonType) && `${template.label}`,
            min:
              template.attribute.min && template.attribute.min?.length > 0
                ? Number(template.attribute.min)
                : undefined,
            max:
              template.attribute.max && template.attribute.max?.length > 0
                ? Number(template.attribute.max)
                : undefined,
          }
          : undefined
      }
      render={({
        field: { onChange, value },
        formState: { isSubmitted, errors },
      }) => {
        return (
          <>
            <ComponentLabel
              col={col}
              colText={colText}
              rowIdx={rowIdx}
              colIdx={rowIdx}
              template={template}
            />
            <Col
              sm={col === undefined ? 12 : 12}
              md={col === undefined ? colAction : 12}
              xs={col === undefined ? 12 : 12}
              xl={col === undefined ? colAction : 12}
              className={"padding-controller"}
            >
              <div
                className={`input-component-container  ${isSubmitted &&
                    errors?.items &&
                    errors?.items[rowIdx] &&
                    errors?.items[rowIdx].layout[colIdx]
                    ? "set-layout-required"
                    : ""
                  }`}
              >
                <InputNumberComponent
                  {...{ canEditDoc, checkActionPage }}
                  id={template.label.replaceAll(" ", "_")}
                  template={template}
                  onChange={(value) => {
                    let intValue: any = value == "" ? null : value;
                    onChange(intValue);
                    onControlChange(template, intValue, false);
                  }}
                  value={value}
                  status={
                    errors?.items &&
                    errors?.items[rowIdx] &&
                    errors?.items[rowIdx].layout[colIdx] &&
                    "error"
                  }
                />
                {errors?.items &&
                  errors?.items[rowIdx] &&
                  errors?.items[rowIdx].layout[colIdx] &&
                  errors?.items[rowIdx]?.layout[colIdx]?.data?.value?.type ===
                  "validate" && (
                    <small id="Name-help" className="p-error p-d-block">
                      {template.label} : is required.
                    </small>
                  )}
                {errors?.items &&
                  errors?.items[rowIdx] &&
                  errors?.items[rowIdx].layout[colIdx] &&
                  (errors?.items[rowIdx]?.layout[colIdx]?.data?.value?.type! ===
                    "min" ||
                    errors?.items[rowIdx]?.layout[colIdx]?.data?.value
                      ?.type! === "max") && (
                    <small id="Name-help" className="p-error p-d-block">
                      {template.label} must be between {template.attribute.min}{" "}
                      to {template.attribute.max}.
                    </small>
                  )}
              </div>
            </Col>
          </>
        );
      }}
    />
  );
};

export const InputNumberTableComponent: React.FC<TableInputProps> = ({
  rowIdx,
  colIdx,
  name,
  template,
  saveFunc,
  inputRef,
  isEditing,
  onEdit,
  children,
  canEditDoc,
  checkActionPage,
  buttonType,
}) => {
  const { toggleAlert } = useAlert();

  if (!isEditing) {
    return (
      <>
        <div
          style={
            template.type === "c" &&
              Number(template.attribute.min) === Number(template.attribute.max)
              ? { width: "100%" }
              : (template.type === "c" &&
                Number(template.attribute.max) !== 0 &&
                Number(children[1]) !== 0.0 &&
                Number(children[1]) > Number(template.attribute.max)) ||
                (template.type === "c" &&
                  Number(template.attribute.max) !== 0 &&
                  Number(children[1]) !== 0.0 &&
                  Number(children[1]) < Number(template.attribute.min))
                ? {
                  width: "100%",
                  border: "1px solid red",
                  borderRadius: "6px",
                }
                : {
                  width: "100%",
                }
          }
        >
          <InputNumberComponent
            id={rowIdx + "_" + colIdx + "_" + template.label}
            {...{ canEditDoc, checkActionPage, buttonType }}
            value={children[1]}
            template={template}
            onChange={saveFunc}
            toggleEdit={onEdit}
          />
        </div>
        {template.type === "c" &&
          template.attribute.min === template.attribute.max ? (
          <></>
        ) : (
          (template.type === "c" &&
            Number(template.attribute.max) !== 0.0 &&
            Number(children[1]) !== 0.0 &&
            Number(children[1]) > Number(template.attribute.max) && (
              <small id="Name-help" className="p-error p-d-block">
                {template.label} must be between {template.attribute.min} to{" "}
                {template.attribute.max}.
              </small>
            )) ||
          (template.type === "c" &&
            Number(template.attribute.min) !== 0.0 &&
            Number(children[1]) !== 0.0 &&
            Number(children[1]) < Number(template.attribute.min) && (
              <small id="Name-help" className="p-error p-d-block">
                {template.label} must be between {template.attribute.min} to{" "}
                {template.attribute.max}.
              </small>
            ))
        )}
      </>
    );
  }

  return (
    <>
      <Form.Item
        style={{ margin: 0 }}
        name={name}
        rules={
          canEditDoc && [
            {
              validator: (_, value) => {
                let correct = true;
                let msg = "";

                if (value) {
                  const formatValue = Number(value);
                  const formatMaxValue = Number(template.attribute.max);
                  const formatMinValue = Number(template.attribute.min);
                  if (formatMaxValue !== 0 && formatValue > formatMaxValue) {
                    correct = false;
                    msg = `${template.label} must be between ${template.attribute.min} to ${template.attribute.max} value.`;
                  } else if (
                    formatMinValue !== 0 &&
                    formatValue < formatMinValue
                  ) {
                    correct = false;
                    msg = `${template.label} must be between ${template.attribute.min} to ${template.attribute.max} value.`;
                  }
                } else if (value === "" && template.attribute.require === "Y") {
                  correct = false;
                }

                return correct
                  ? Promise.resolve()
                  : Promise.reject(new Error(msg));
              },
            },
          ]
        }
      >
        <InputNumberComponent
          id={rowIdx + "_" + colIdx + "_" + template.label}
          {...{ canEditDoc, checkActionPage }}
          template={template}
          isEditing={isEditing}
          ref={inputRef}
          onChange={() => {
            saveFunc(template.type, template.type);
          }}
        />
      </Form.Item>
    </>
  );
};
