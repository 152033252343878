import React, { useState, useEffect } from "react";
import DrawArea from "./DrawPdfArea";
import { Document, Page, pdfjs } from "react-pdf";
import { MdNavigateNext } from "react-icons/md";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PreviewPdfPageProps {
  pdf: string;
  pageChange: (pageNumber: number) => void;
  getPaths: (el: any) => void;
  flag: string;
  getBounds: (obj: any) => void;
  changeFlag: () => void;
  cursor: string;
  buttonType: string;
  resetButtonType: () => void;
  pdfError: boolean;
  pdfSetError: any;
  strokeColor: string;
}

const PreviewPdf: React.FC<PreviewPdfPageProps> = (props) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const fileName = props?.pdf?.split('/').pop();
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }

  useEffect(() => {
    props.pageChange(pageNumber);
  }, [pageNumber, props]);

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  const onDocumentLoadError = (err: any) => {
    console.error("onDocumentLoadError=>", err);
    props.pdfSetError(true);
  };

  const { pdf } = props;


  return (
    <>
      {props?.pdfError ?
        <>
          <div className="text-center mt-4">
            <p>ไม่พบไฟล์ PDF ที่จะทำการแก้ไข</p>
          </div>
        </>
        :
        <>
          <div className="text-center mt-2">
            <p style={{fontSize: 18}}>
              หน้าที่ {pageNumber || (numPages ? 1 : "-")} ใน {numPages || "-"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between px-4">
            <div className="">
              <button 
                className="rounded-circle btn btn-primary border border-light" 
                disabled={pageNumber <= 1}
                onClick={previousPage}
                style={{
                  height: 50, 
                  width: 50,
                  display: numPages !== null && numPages > 1 ? "block" : "none",
                }}
              >
                <MdNavigateNext style={{fontSize: 20, color: "#fff", rotate: "180deg"}}/>
              </button>
            </div>
            <div className="">
              <Document
                file={pdf}
                onSourceError={(err: any) => { console.log("onSourceError", err) }}
                onSourceSuccess={() => console.log("SUCCESS")}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadError}
              >
                <DrawArea
                  getPaths={props.getPaths}
                  page={pageNumber}
                  flag={props.flag}
                  getBounds={props.getBounds}
                  changeFlag={props.changeFlag}
                  cursor={props.cursor}
                  buttonType={props.buttonType}
                  resetButtonType={props.resetButtonType}
                  fileName={fileName}
                  strokeColor={props.strokeColor}
                >
                  <Page
                    pageNumber={pageNumber}
                  />
                </DrawArea>
              </Document>
            </div>
            <div>
              <button 
                className="rounded-circle btn btn-primary border border-light" 
                disabled={pageNumber >= (numPages || 0)}
                onClick={nextPage}
                style={{
                  height: 50, 
                  width: 50,
                  display: numPages !== null && numPages > 1? "block" : "none",
                }}>
                <MdNavigateNext style={{fontSize: 20, color: "#fff"}}/>
              </button>
            </div>
          </div>
        </>
      }

    </>
  );
};

export default PreviewPdf;