import React, { useEffect, useRef, useState } from "react";
import './freehand.css';
import './freehandLayout.css';
import PreviewPdf from "./PreviewPdf";
import UploadPdfComponent from "./uploadPdfComponent";
import AutoTextArea from './AddTextToPdf';
import { IoReload } from "react-icons/io5";
import { FiRefreshCw } from "react-icons/fi";
import { IoIosArrowRoundBack } from "react-icons/io";
import { FaRegEdit, FaUndo, FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";
import { BiText } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { ErrorPageComponent } from "../ErrorPageComponent/ErrorPageComponent";
import { Spin, Select } from "antd";
interface Props {
    path: string;
    memo: any;
    fileName: any;
}

interface ResultItem {
    id: string;
    x: number;
    y: number;
    text: string;
    page: number;
    type: 'text' | 'freehand';
    ref: React.RefObject<HTMLDivElement>;
}

const EditPdfMainPage = (props: Props) => {

    const [result, setResult] = useState<ResultItem[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [redoStack, setRedoStack] = useState<ResultItem[]>([]);
    const [flag, setFlag] = useState<string>("");
    const [bounds, setBounds] = useState<any>({});
    const [buttonType, setButtonType] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [disabledBtn, setDisabledBtn] = useState<boolean>(() => {
        return localStorage.getItem('disabledBtn') === 'false' ? false : true;
    });
    const [disabledReloadBtn, setDisabledReloadBtn] = useState<boolean>(() => {
        return localStorage.getItem('disabledReloadBtn') === 'false' ? true : false;
    });
    const [strokeColor, setStrokeColor] = useState<string>("");
    const [isText, setIsText] = useState(false);
    const history = useHistory();
    const tempRef = useRef(null);
    const option = [
        { label: "สีแดง", value: "red" },
        { label: "สีเหลือง", value: "yellow" },
        { label: "สีดำ", value: "black" },
        { label: "สีเขียว", value: "green" },
    ]

    useEffect(() => {
        localStorage.removeItem('disabledBtn');
        localStorage.removeItem('disabledReloadBtn');
    }, [])

    useEffect(() => {
        if (isText) {
            setIsText(false);
        }
    }, [result])

    const pageChange = (num: number) => {
        setPageNumber(num);
    };

    const addText = () => {
        setIsText(true);
        const addText = document.getElementById("drawArea");
        if (addText) {
            addText.addEventListener("click", (e) => {
                e.preventDefault();
                setResult(result => [...result,
                {
                    id: generateKey(e.pageX),
                    x: e.pageX,
                    y: e.pageY - 70,
                    text: "",
                    page: pageNumber,
                    type: "text",
                    ref: tempRef
                }]);
            }, { once: true });
        }
    }

    const generateKey = (pre: any) => {
        return `${pre}_${new Date().getTime()}`;
    }

    const onTextChange = (id: any, txt: any, ref: any) => {
        let indx = result.findIndex(x => x.id === id);
        let item = { ...result[indx] };
        item.text = txt;
        item.ref = ref;
        result[indx] = item;
        const filterData = result?.filter(item => item.text !== "");
        setResult(filterData);
    }

    const undo = () => {
        const temp: ResultItem | undefined = result.pop();
        if (temp) {
            if (temp.type === "freehand") {
                setFlag("undo");
            }
            setRedoStack((stack) => [...stack, temp]);
            setResult(result);
        }
    };

    const changeFlag = () => {
        setFlag("");
    };

    const redo = () => {
        const top: ResultItem | undefined = redoStack.pop();
        if (top) {
            if (top.type === "freehand") {
                setFlag("redo");
            }
            setResult((res) => [...res, top]);
        }
    };

    const removeAll = () => {
        setFlag("removeAll");
        setResult([])
    };

    const getPaths = (el: ResultItem) => {
        setResult(res => [...res, el]);
    };

    const getBounds = (obj: any) => {
        setBounds(obj);
    };

    const changeButtonType = (type: string) => {
        setButtonType(type);
    };

    const resetButtonType = () => {
        setButtonType("");
    };

    const refreshScreen = () => {
        localStorage.setItem('disabledBtn', 'false');
        localStorage.setItem('disabledReloadBtn', 'false');
        window.location.reload();
    };

    const back = () => {
        localStorage.removeItem('disabledBtn');
        localStorage.removeItem('disabledReloadBtn');
        history.goBack();
    }

    const btnRender = () => {
        return (
            <>
                <div className="wrapFreehandBtns">
                    <button className="btnFreehand" onClick={() => back()} style={{ marginTop: "1%", marginBottom: "1%", background: "#535c68" }}>
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <div>
                                <IoIosArrowRoundBack />
                            </div>
                            <div>
                                Back
                            </div>
                        </div>
                    </button>
                    <button disabled={disabledReloadBtn} className="btnFreehand" onClick={() => refreshScreen()} style={{ marginTop: "1%", marginBottom: "1%", background: disabledReloadBtn ? "#7e7e7e" : "#9400D3", cursor: disabledReloadBtn ? "not-allowed" : "pointer" }}>
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <div>
                                <FiRefreshCw />
                            </div>
                            <div>
                                Refresh
                            </div>
                        </div>
                    </button>
                    <button 
                        disabled={disabledBtn}
                        className="btnFreehand" 
                        onClick={removeAll} 
                        style={{ marginTop: "1%", marginBottom: "1%", background: disabledBtn ? "#7e7e7e" : "#e84118", cursor: disabledBtn ? "not-allowed" : "pointer" }}
                    >
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <div>
                                <FaRegTrashAlt />
                            </div>
                            <div>
                                Remove all
                            </div>
                        </div>
                    </button>
                    <button 
                        disabled={disabledBtn}
                        className="btnFreehand" 
                        onClick={addText} 
                        style={{ marginTop: "1%", marginBottom: "1%", background: disabledBtn ? "#7e7e7e" : "#3d3d3d", cursor: disabledBtn ? "not-allowed" : "pointer" }}
                    >
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <div>
                                <BiText />
                            </div>
                            <div>
                                Text
                            </div>
                        </div>
                    </button>
                    <button 
                        disabled={disabledBtn} 
                        className="btnFreehand" 
                        onClick={undo} 
                        style={{ marginTop: "1%", marginBottom: "1%", background: disabledBtn ? "#7e7e7e" : "#feca57", cursor: disabledBtn ? "not-allowed" : "pointer" }}
                    >
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <div>
                                <FaUndo />
                            </div>
                            <div>
                                Undo
                            </div>
                        </div>
                    </button>
                    <button
                        disabled={disabledBtn}
                        className="btnFreehand"
                        onClick={redo}
                        style={{ marginTop: "1%", marginBottom: "1%", background: disabledBtn ? "#7e7e7e" : "#5f27cd", cursor: disabledBtn ? "not-allowed" : "pointer" }}
                    >
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <div>
                                <IoReload />
                            </div>
                            <div>
                                Redo
                            </div>
                        </div>
                    </button>
                    <button
                        disabled={disabledBtn}
                        className="btnFreehand"
                        onClick={() => changeButtonType("draw")}
                        style={{ marginTop: "1%", marginBottom: "1%", background: disabledBtn ? "#7e7e7e" : "#3F51B5", cursor: disabledBtn ? "not-allowed" : "pointer" }}
                    >
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <div>
                                <FaRegEdit />
                            </div>
                            <div>
                                Edit
                            </div>
                        </div>
                    </button>
                    <button
                        disabled={disabledBtn}
                        className="btnFreehand"
                        onClick={() => changeButtonType("download")}
                        style={{ marginTop: "1%", marginBottom: "1%", background: disabledBtn ? "#7e7e7e" : "#4cd137", cursor: disabledBtn ? "not-allowed" : "pointer" }}
                    >
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <div>
                                <MdOutlineFileUpload />
                            </div>
                            <div>
                                Upload
                            </div>
                        </div>
                    </button>
                </div>
            </>
        )
    }

    const selectChange = (e: any) => {
        setStrokeColor(e)
    }

    return (
        <>
            {props.path ?
                <>
                    <Spin
                        tip="Loading..."
                        spinning={loading}
                    >
                        <div className="pdfEditWrapper">
                            {
                                result?.map((res) => {
                                    if (res?.type === "text") {
                                        let isShowing = "hidden";
                                        if (res?.page === pageNumber) {
                                            isShowing = "visible";
                                        }
                                        return (
                                            <>
                                                <AutoTextArea
                                                    key={res.id}
                                                    unique_key={res.id}
                                                    val={res.text}
                                                    onTextChange={onTextChange}
                                                    style={{
                                                        visibility: isShowing,
                                                        fontWeight: 'normal',
                                                        fontSize: 16,
                                                        zIndex: 20,
                                                        position: "absolute",
                                                        left: res.x + 'px',
                                                        top: res.y + 'px',
                                                    }}>
                                                </AutoTextArea>
                                            </>
                                        )
                                    }
                                    else {
                                        return (null);
                                    }
                                })
                            }
                            {error ?
                                <>
                                    <div className="wrapFreehandBtns">
                                        <button className="btnFreehand" onClick={() => history.goBack()} style={{ marginTop: "1%", marginBottom: "1%", background: "#535c68" }}>
                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                <div>
                                                    <IoIosArrowRoundBack />
                                                </div>
                                                <div>
                                                    ย้อนกลับ
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </> :
                                <>
                                    {btnRender()}
                                </>
                            }
                        </div>
                        {/* <div className="">
                            <Select
                                className=""
                                placeholder="เลือกสี"
                                onChange={(e) => selectChange(e)}
                                options={option}
                                style={{width: "50%"}}
                            />
                        </div> */}
                        <div className="">
                            <PreviewPdf
                                resetButtonType={resetButtonType}
                                buttonType={buttonType}
                                cursor={"default"}
                                pdf={props.path}
                                pageChange={pageChange}
                                getPaths={getPaths}
                                flag={flag}
                                getBounds={getBounds}
                                changeFlag={changeFlag}
                                pdfError={error}
                                pdfSetError={setError}
                                strokeColor={strokeColor}
                            />
                        </div>
                        <div className=''>
                            <UploadPdfComponent
                                resetButtonType={resetButtonType}
                                buttonType={buttonType}
                                pdf={props.path}
                                result={result}
                                bounds={bounds}
                                memo={props.memo}
                                fileName={props.fileName}
                                setLoading={setLoading}
                            />
                        </div>
                    </Spin>
                </> :
                <>
                    <ErrorPageComponent />
                </>
            }
        </>
    )
}

export default EditPdfMainPage;