import React from "react";
import { FilterComponentProps } from "../DynamicTableV3";
import { InputNumber } from "antd";

const NumberFilterComponent = ({
  colProp,
  options,
  dataSource,
}: FilterComponentProps) => {
  const optionValue: any = Array.from(
    new Set(
      dataSource.map((option) => {
        return option ? Number(option[colProp.key]?.replaceAll(",", "")) : 0;
      })
    )
  );
  const val: [number, number] = options?.value && (options?.value as any);
  const minOption = Math.min(...optionValue);
  const maxOption = Math.max(...optionValue);
  const minVal = val ? val[0] : minOption;
  const maxVal = val ? val[1] : maxOption;
  const decimal = colProp.decimal ?? 2;
  const decimalStep = Math.pow(10, -decimal);
  return (
    <div
      style={{
        gap: 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        textAlign: "center",
      }}
    >
      <InputNumber
        min={minOption}
        max={maxOption}
        style={{ margin: "0 16px" }}
        value={minVal}
        step={decimalStep.toString()}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        stringMode
        controls={false}
        onChange={(e) => {
          options.filterApplyCallback([e, maxVal]);
        }}
      />
      -
      <InputNumber
        min={minOption}
        max={maxOption}
        style={{ margin: "0 16px" }}
        controls={false}
        value={maxVal}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        step={decimalStep.toString()}
        stringMode
        onChange={(e) => {
          options.filterApplyCallback([minVal, e]);
        }}
      />
    </div>
  );
};

export default NumberFilterComponent;
