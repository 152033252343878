import { Checkbox, Table } from "antd";
import React, { useState } from "react";

type Props = {
  newRows: any[];
  columns: any[];
  onCheckedHeader: (checked: boolean) => void;
};

const ExcelTablePreview = ({ newRows, columns, onCheckedHeader }: Props) => {
  const [pageSize, setPageSize] = useState(10);
  return (
    <>
      <Table
        dataSource={newRows}
        columns={columns.filter((e) => e.key !== "operation")}
        scroll={{ x: 1500 - 1000, y: 500 }}
        pagination={{
          pageSize: pageSize,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
      />
    </>
  );
};

export default ExcelTablePreview;
