import { promises } from "dns";
import { GetAllDynamic } from "./DynamicService";
import { GetRolePermissionByUser } from "./RoleServices";

export const GetAllAuthorized = async () => {
  const respone = await fetch("api/Authorized/GetAll")
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => { });
  return respone;
};
export const GetMemoAuthorized = async (request: any) => {
  console.log("request", request);

  const respone = await fetch("api/Memo/GetMemoPermission", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => { });

  return respone;
};
export const GetMemoAuthorizedViewAndPrint = async (request: any) => {
  console.log("request", request);

  const respone = await fetch("api/Memo/GetMemoPermissionViewAndPrint", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => { });

  return respone;
};
export const RegisterWolfAccount = async (request: any) => {
  console.log("request", request);

  const respone = await fetch("api/Authentication/RegisterWolfAccount", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => { });

  return respone;
};

export const CheckRolePermission = async (EmployeeId: number) => {
  let isAdmin: boolean = false;
  let permissions: any[] = await GetRolePermissionByUser();
  let roles: any[] = await GetAllDynamic("Roles/GetAll", undefined);

  if (permissions) {
    let thispermiss = permissions.filter(
      (permiss: any) => permiss.EmployeeId === EmployeeId
    );

    roles?.forEach((role: any) => {
      thispermiss.forEach((permis: any) => {
        if (permis.IsDelete !== true) {
          if (permis.RoleId === role.RoleId) {
            if (role.RoleId === 1) {
              isAdmin = true;
            }
          }
        }
      });
    });
  }

  return isAdmin;
};

export const confirmResetPassword = async (guidVerify: string): Promise<{ data?: any, error?: boolean }> => {
  try {
    const response = await fetch("api/Authentication/ConfirmResetPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ GuidVerify: guidVerify }),
    });

    const data = await response.json();
    if(!data) return { error: true }; 
    return { data }; 

  } catch (err) {
    console.error("Error during password reset confirmation:", err);
    return { error: true }; 
  }
};

export const forgotPassword = async( body: {email :string , new_password  : string}):Promise<{ data?: any, error?: boolean }> =>{
  const _baseUrl = window.location.hostname;
  try {
    const response = await fetch("api/Authentication/ForgotPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: body.email,
        newPassword: body.new_password,
        webUrl:
          process.env.NODE_ENV === "development"
            ? "qar2.wolfapprove.com"
            : _baseUrl,
      }),
    });
    const data = await response.json();
    if(!data) return { error: true }; 
    return { data }; 
  } catch (err) {
    console.error("Error during password reset confirmation:", err);
    return { error: true }; 
  }
}