import { Button, Divider, Form, Input, Select, Space } from "antd";
import React, { useState } from "react";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { LogicFormProps, refInfors } from "../JsonLogicForm";
import DataSourceRelateConditionRow from "./DataSourceRelateConditionRow";
import { enumToObject } from "../../../../Helper/LogicHelper";
import { LogicOperatorType } from "../../../../constants/logic-type";
import "./formLogic.css";
const { Option, OptGroup } = Select;

const DataSourceRelatedToLoadData = ({
  advanceFormItem,
  form,
}: LogicFormProps) => {
  const [labelActionValues, setLabelActionValues] = useState<any[]>([]);
  const operators = enumToObject(LogicOperatorType);

  const controlOption: any[] = advanceFormItem
    .map((item) => {
      if (item.type === "tb" && item.columns) {
        return {
          label: "Tb :" + item.label,
          type: item.type,
          options: item.columns.map((col) => ({
            label: col.label,
            type: item.type,
            value: col.label + "|Tb",
          })),
        };
      }
      return {
        label: item.label,
        type: item.type,
        value: item.label,
      };
    })
    .filter((e) => e.label);
  return (
    <>
      {" "}
      <Form.Item label="Label action" name="labelactions">
        <Select
          placeholder="Label to action"
          mode="multiple"
          showSearch
          style={{ minWidth: 200 }}
          value={labelActionValues}
          onChange={(e) => {
            setLabelActionValues(e);
          }}
        >
          {refInfors.map((item, index) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
          {controlOption
            .filter((e) => {
              
              if (labelActionValues.length === 0) return true;

              if (labelActionValues.every((e: string) => e?.endsWith("|Tb"))) {
                return e.type === "tb";
              }
              return e.type !== "tb";
            })
            .map((item: any, index) => {
              if (item.options) {
                return (
                  <>
                    <Option key={item.label} value={item.value}>
                      {item.label}
                    </Option>
                    <OptGroup label={item.label + "'s columns"}>
                      {item.options.map((option: any) => (
                        <Option
                          key={item.label + "'s columns" + option.value}
                          value={option.value}
                        >
                          {option.label}
                        </Option>
                      ))}
                    </OptGroup>
                  </>
                );
              } else {
                return (
                  <Option key={item.label} value={item.value}>
                    {item.label}
                  </Option>
                );
              }
            })}
        </Select>
      </Form.Item>
      <Form.Item label="Auto load value to label">
        <Input.Group
          compact
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Form.Item
            name={["autoloadvaluelabel", "label"]}
            style={{
              marginBottom: 0,
              marginRight: 5,
            }}
          >
            <Select
              placeholder="load value to label"
              showSearch
              style={{ minWidth: 200 }}
            >
              {controlOption
                .filter((e) => {
                  
                  if (labelActionValues.length === 0) return true;

                  if (
                    labelActionValues.every(
                      (e: string) =>
                        e?.endsWith("|Tb") || e.startsWith("Company_")
                    )
                  ) {
                    return e.type === "tb";
                  }
                  return ["dd", "r", "ch","t" ,"cb"].includes(e.type);
                })
                .map((item: any, index) => {
                  if (item.options) {
                    return (
                      <>
                        <Option key={item.label} value={item.value}>
                          {item.label}
                        </Option>
                        <OptGroup label={item.label + "'s columns"}>
                          {item.options.map((option: any) => (
                            <Option
                              key={item.label + "'s columns" + option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Option>
                          ))}
                        </OptGroup>
                      </>
                    );
                  } else {
                    return (
                      <Option key={item.label} value={item.value}>
                        {item.label}
                      </Option>
                    );
                  }
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name={["autoloadvaluelabel", "value"]}
            noStyle
            rules={[{ required: true, message: "Street is required" }]}
          >
            <Input style={{ width: "100%" }} placeholder="Field name" />
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item label="Table" name="table">
        <Input placeholder="Table" />
      </Form.Item>
      <Divider>Fields</Divider>
      <Form.List name="fields">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "field"]}
                    rules={[{ required: true, message: "Missing field" }]}
                  >
                    <Input placeholder="Field" />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <Divider>Conditions</Divider>
      <Form.List name={["conditions"]}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  className="form-logic-conditions"
                  key={idx}
                  size={"middle"}
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  // }}
                  align="start"
                >
                  <Form.Item
                    name={[name, "field"]}
                    rules={[{ required: true, message: "Missing field" }]}
                  >
                    <Input placeholder="Field" style={{ minWidth: 200 }} />
                  </Form.Item>
                  <Form.Item name={[name, "action"]}>
                    <Select
                      placeholder="Action"
                      style={{ minWidth: 200 }}
                      showSearch
                      options={[
                        { label: "AND", value: "AND" },
                        { label: "OR", value: "OR" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, "Condition"]}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[{ required: true, message: "Missing condition" }]}
                  >
                    <Select
                      placeholder="Condition"
                      style={{ minWidth: 200 }}
                      showSearch
                      allowClear
                    >
                      {Object.entries(operators)
                        .filter(
                          ([value, label], index) =>
                            value !== "contain" &&
                            value !== "startwith" &&
                            value !== "endwith"
                        )
                        .map(([value, label], index) => (
                          <Option key={index} value={value}>
                            {label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={[name, "value"]}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        validator: (_, value) => {
                          form.setFieldsValue({
                            [name]: { objectkey: null, label: null },
                          });

                          if (
                            !value &&
                            !form.getFieldValue(name)["objectkey"] &&
                            !form.getFieldValue(name)["label"]
                          ) {
                            return Promise.reject(
                              "label,value or comparewith cannot be empty"
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="value" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "label"]}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            !value &&
                            !form.getFieldValue("conditions")[name]["objectkey"]
                          ) {
                            return Promise.reject(
                              "label or comparewith cannot be empty"
                            );
                          }
                          let formValue =
                            form.getFieldValue("conditions")[name];
                          formValue["objectkey"] = null;

                          form.setFieldsValue(formValue);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Label" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "objectkey"]}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            !value &&
                            !form.getFieldValue("conditions")[name]["label"]
                          ) {
                            return Promise.reject(
                              "label or comparewith cannot be empty"
                            );
                          }
                          let formValue =
                            form.getFieldValue("conditions")[name];
                          formValue["label"] = null;

                          form.setFieldsValue(formValue);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Select
                      placeholder="Compare with informations"
                      style={{ minWidth: 200 }}
                      allowClear
                      showSearch
                    >
                      {refInfors.map((item, index) => (
                        <Option key={item.value} value={item.label}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default DataSourceRelatedToLoadData;
