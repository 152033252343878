import { useContext } from "react";
import { MenuEmailContext } from "../Context/MenuEmailContext";

const useMenuEmail = () => {
  const context = useContext(MenuEmailContext);
  if (!context)
    throw new Error("MenuEmail Context must be use inside MenuEmailContext Provider");
  return context;
};

export default useMenuEmail;
