import { Button, Divider, Form, Input, Space, Switch ,Select} from "antd";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

type Props = {};

const ReturnMessageTab = ({}: Props) => {
  return (
    <>
      <Form.Item label="Head" name={["ReturnMessage", "HEAD"]}>
        <Input placeholder="Head" />
      </Form.Item>
      <Form.Item  label="Type" name={["ReturnMessage", "TYPE"]}>
          <Select
            placeholder="TYPE"
            style={{ minWidth: 100 }}
            options={[
              {
                value: "Single",
                label: "Single",
              },
              {
                value: "List",
                label: "List",
              },
              {
                value: "listsinglepost",
                label: "List Single Post",
              }
            ]}
          />
        </Form.Item>
      <Form.Item label="Operator" name={["ReturnMessage", "OPERATOR"]}>
        <Input placeholder="Operator" />
      </Form.Item>
      <Form.Item
        label="Allow"
        name={["ReturnMessage", "ALLOW"]}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item label="Message key" name={["ReturnMessage", "MESSAGE_KEY"]}>
        <Input placeholder="Message key" />
      </Form.Item>
      <Divider>Condition error</Divider>
      <Space
        size={"middle"}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        align="start"
      >
        <Form.Item
          label="Key"
          name={["ReturnMessage", "CONDITIONERROR", "KEY"]}
        >
          <Input placeholder="Key" />
        </Form.Item>
        <Form.Item    name={["ReturnMessage", "CONDITIONERROR", "TYPE"]}>
          <Select
            placeholder="TYPE"
            style={{ minWidth: 100 }}
            options={[
              {
                value: "equals",
                label: "EQUALS",
              },
              {
                value: "not_equals",
                label: "NOT_EQUALS",
              }
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Value"
          name={["ReturnMessage", "CONDITIONERROR", "VALUE"]}
        >
          <Input placeholder="Value" />
        </Form.Item>
      </Space>
    </>
  );
};

export default ReturnMessageTab;
