export const CostCenter = async (formData: any) => {
  const respone = await fetch("api/CostCenter/GetCostCenter", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });

  return respone;
};

export const UpdateCostCenter = async (dataJson: any) => {
  const respone = await fetch("api/CostCenter/UpdateData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    });

  return respone;
};
export const AddCostCenter = async (dataJson: any) => {
  const respone = await fetch("api/CostCenter/AddData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    });

  return respone;
};

export const AddInternalOrder = async (dataJson: any) => {
  const respone = await fetch("api/InternalOrder/AddData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataJson),
  })
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    });

  return respone;
};
