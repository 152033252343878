import React, { useState, useEffect, useRef } from 'react';
import Immutable from 'immutable';
interface DrawAreaProps {
  getPaths: (path: any) => void;
  flag: string;
  changeFlag: () => void;
  buttonType: string;
  resetButtonType: () => void;
  cursor: string;
  page: number;
  children: React.ReactNode;
  getBounds: any;
  fileName: string | undefined;
  strokeColor: string;
}

const DrawArea: React.FC<DrawAreaProps> = (props: any) => {
  const [lines, setLines] = useState<any[]>([]);
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [redoEl, setRedoEl] = useState<any[]>([]);
  const [isCrosshair, setIsCrosshair] = useState<boolean>(false);
  const [width, setWidth] = useState<Number>();
  const [height, setHeight] = useState<Number>();
  const stroke: string = props.strokeColor;
  const drawAreaEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const drawArea = document.getElementById("drawArea");
    if (drawArea) {
      drawArea.addEventListener("mouseup", handleMouseUp);
      drawArea.addEventListener("mouseup", handleMouseUp);
      drawArea.addEventListener("mousemove", handleMouseMove);
      drawArea.addEventListener("touchstart", handleTouchStart, { passive: false });
      drawArea.addEventListener("touchmove", handleTouchMove, { passive: false });
      drawArea.addEventListener("touchend", handleTouchEnd, { passive: false });
    }

    if (drawAreaEl.current) {
      const rect = drawAreaEl.current.getBoundingClientRect();
      props.getBounds({
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
      });
    }

    return () => {
      if (drawArea) {
        drawArea.removeEventListener("mouseup", handleMouseUp);
        drawArea.removeEventListener("mouseup", handleMouseUp);
        drawArea.removeEventListener("mousemove", handleMouseMove);
        drawArea.removeEventListener("touchstart", handleTouchStart);
        drawArea.removeEventListener("touchmove", handleTouchMove);
        drawArea.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  useEffect(() => {
    if (props.flag === "undo") {
      setRedoEl((arr) => [...arr, lines.pop()]);
      setLines([...lines]);
    }
    if (props.flag === "redo") {
      setLines((lines) => [...lines, redoEl.pop()]);
    }
    if (props.flag === "removeAll") {
      setRedoEl([]);
      setLines([]);
      props.changeFlag();
    }
    props.changeFlag();
  }, [props.flag]);

  useEffect(() => {
    if (props.buttonType === "draw") {
      addMouseDown();
      addTouchDown();
      props.resetButtonType();
    }
  }, [props.buttonType]);

  useEffect(() => {
    if (!isDrawing && lines.length) {
      props.getPaths(lines[lines.length - 1]);
    }
  }, [isDrawing]);

  useEffect(() => {
    const splitFileName = props.fileName.split(".").shift();
    if (splitFileName) {
      if (splitFileName.includes("Retirement")) {
        setWidth(850)
        setHeight(600)
      } else if (splitFileName.includes("WorkingReport")) {
        setWidth(650)
        setHeight(845)
      } else {
        setWidth(650)
        setHeight(850)
      }
    }
  },[])

  const handleMouseUp = () => {
    setIsCrosshair(false);
    setIsDrawing(false);
  };

  const handleMouseDown = (e: MouseEvent) => {
    if (e.button !== 0) {
      return;
    }
    const point = relativeCoordinatesForEvent(e);
    let obj = {
      arr: [point],
      page: props.page,
      type: "freehand",
    };
    setLines((prevLines) => [...prevLines, obj]);
    setIsDrawing(true);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement> | any) => {
    if (!isDrawing) {
      return;
    }
    const point = relativeCoordinatesForEvent(e);
    let last = lines.pop();
    last.arr.push(point);
    setLines(prevLines => [...prevLines, last]);
  }

  const relativeCoordinatesForEvent = (e: MouseEvent) => {
    const boundingRect = drawAreaEl.current!.getBoundingClientRect();
    return Immutable.fromJS({
      x: e.clientX - boundingRect.left,
      y: e.clientY - boundingRect.top,
    });
  }

  const addMouseDown = () => {
    setIsCrosshair(true);
    const drawArea = document.getElementById("drawArea");
    if (drawArea) {
      drawArea.addEventListener("mousedown", handleMouseDown, { once: true });
    }
  };

  const addTouchDown = () => {
    setIsCrosshair(true);
    const drawArea = document.getElementById("drawArea");
    if (drawArea) {
      drawArea.addEventListener("touchstart", handleTouchStart, { once: true });
    }
  };

  const handleTouchStart = (e: TouchEvent) => {
    const touch = e.touches[0];
    const point = relativeCoordinatesForTouchEvent(touch);

    let obj = {
      arr: [point],
      page: props.page,
      type: "freehand",
    };
    setLines((prevLines) => [...prevLines, obj])
    setIsDrawing(true);
  };
   
  const handleTouchMove = (e: TouchEvent) => {
    const touch = e.touches[0];
    const point = relativeCoordinatesForTouchEvent(touch);
  
    setLines((prevLines) => {
      const updatedLines = [...prevLines];
      const lastLine = updatedLines[updatedLines.length - 1];
  
      if (lastLine) {
        lastLine.arr.push(point);
      }
  
      return updatedLines;
    });
  };
  
  const handleTouchEnd = () => {
    setIsDrawing(false);
  };
  
  const relativeCoordinatesForTouchEvent = (touch: Touch) => {
    const boundingRect = drawAreaEl.current!.getBoundingClientRect();
    return Immutable.fromJS({
      x: touch.clientX - boundingRect.left,
      y: touch.clientY - boundingRect.top,
    });
  };

  return (
    // minwidth ส่งผลทำให้เส้น SVG แสดงผลไม่ถูกต้องตอนอัพโหลด
    // ใช้ css border เพื่อดูขนาดของกรอบที่ครอบ PDF เพื่อจัดตำแหน่ง ความกว้าง - ความสูงใหม่
    <>
        <div
          id="drawArea"
          ref={drawAreaEl}
          style={{
            cursor: isCrosshair ? "crosshair" : props.cursor,
            width: "612px",
            height: `${height}px`,
            // border: "1px solid black",
            minWidth: `${width}px`,
            margin: "auto",
            position: "relative",
            marginTop: 3,
            marginBottom: 20,
            touchAction: "none",
            transform: "none",
            // stroke: stroke,
          }}
          onMouseMove={handleMouseMove}
        >
          {props.children}
          <Drawing lines={lines} page={props.page}/>
        </div>
    </>
  );
};

interface DrawingProps {
  lines: any[];
  page: number;
}

const Drawing: React.FC<DrawingProps> = ({ lines, page }) => {
  return (
    <svg 
      className="drawing"
      style={{ zIndex: 10 }}
      xmlns="http://www.w3.org/2000/svg"
    >
      {lines.map((line, index) => (
        <DrawingLine key={index} line={line} page={page} />
      ))}
    </svg>
  );
};

interface DrawingLineProps {
  line: any;
  page: number;
}

const DrawingLine: React.FC<DrawingLineProps> = ({ line, page }) => {
  const pathData = line?.arr
    ? "M " +
      line.arr
        .map((p: any) => {
          return `${p.get('x')},${p.get('y')}`;
        })
        .join(" L ")
    : ""; 
  
  if (line?.page === page) {
    return <path className="path" d={pathData} />;
  }
  return null;
};

export default DrawArea;