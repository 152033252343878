import React, { useEffect, useRef, useState } from "react";
import { ILayoutTemplate } from "../../../Context/PDFEditorContext";
import { Input } from "antd";
const { TextArea } = Input;
type Props = {
  template: ILayoutTemplate;
  h: number;
  onBlur: (value: string | null) => void;
};

const StaticInput = ({ template, h, onBlur }: Props) => {
  const [value, setValue] = useState<string>(template.label);

  return (
    <>
      <TextArea
        value={value}
        style={{
          padding: 0,
          zIndex: 0,
          textAlign :
          template?.attribute && "hAlign" in template.attribute
            ? template.attribute.hAlign === "flex-start"
              ? "left"
              : template.attribute.hAlign === "flex-end"
              ? "right"
              : "center"
            : "center",
        
          resize: "none",
          border: 0,
          overflow: "hidden",
          color: template?.attribute && "color" in template.attribute ? template.attribute?.color : "",
          backgroundColor: "none",
          fontFamily: template?.attribute && "fontFamily" in template.attribute ? template.attribute?.fontFamily : "",
          fontSize: template?.attribute && "fontSize" in template.attribute ? template.attribute?.fontSize : "",
          fontStyle: template?.attribute && "isItalic" in template.attribute
          ? template.attribute.isItalic
            ? "italic"
            : "normal"
          : "normal",
          fontWeight:  template?.attribute && "isBold" in template.attribute
          ? template.attribute.isBold
            ? 700
            : "unset"
          : "unset",
          background: "rgb(0 0 0 / 0%)",
        }}
        autoSize
        placeholder="Input text"
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          onBlur(value);
        }}
      />
      <span
        hidden
        className="content"
        style={{
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          fontFamily: template?.attribute && "fontFamily" in template.attribute ? template.attribute?.fontFamily : "",
          overflow: "unset",
          margin: "unset",
        }}
      >{`${value}`}</span>
    </>
    // <textarea
    //   value={value}
    //   style={{
    //     resize: "none",
    //     textAlign:
    //       template.attribute?.hAlign === "flex-start"
    //         ? "left"
    //         : template.attribute?.hAlign === "flex-end"
    //         ? "right"
    //         : "center",
    //     color: template.attribute?.color,
    //     backgroundColor: "none",
    //     border: "0px",
    //     height: "100%",
    //     width: "100%",
    //     overflow: "hidden",
    //     fontFamily: template.attribute?.fontFamily,
    //     fontSize: template.attribute?.fontSize,
    //     fontStyle: template.attribute?.isItalic ? "italic" : "normal",
    //     fontWeight: template.attribute?.isBold ? 700 : "unset",
    //   }}
    //   rows={h}
    //   placeholder="Input text"
    //   onChange={(e) => {
    //     setValue(e.target.value);
    //   }}
    //   onBlur={() => {
    //     onBlur(value);
    //   }}
    // />
  );
};

export default StaticInput;
