export const formatKeyLogicData = (logicData: any) => {
  try {
    const _logicData = { ...logicData, data: [...logicData.data] };

    if (
      !_logicData.data.some((item: any) => item.item === "-- Please Select --")
    ) {
      _logicData.data.unshift({ item: "-- Please Select --" });
    }

    _logicData.data = _logicData.data.map((data: any) => {
      const val = getPropertyIgnoreCase(data, logicData.labelactionfield);
      if (val) {
        
        return { item: val };
      }
      
      return { item : Object.values(data)[0]}
    });

    _logicData.data = Array.from(
      new Map(
        _logicData.data
          .filter((item: { item: any }) => 
            item && item.item 
          )
          .map((item: { item: any }) => [item.item, item])
      ).values()
    );
    

    return _logicData;
  } catch (error) {
    console.error("logic=>formatKeyLogicData=>error", error);
  }
};

function getPropertyIgnoreCase(
  obj: { [x: string]: any; hasOwnProperty: (arg0: string) => any },
  key: string
) {
  const lowerKey = key?.toLowerCase();
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop) && prop?.toLowerCase() === lowerKey) {
      return obj[prop];
    }
  }
  return undefined;
}
