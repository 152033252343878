import {
  ILogic,
  ILogicReferenceField,
  ILogicReferenceInformation,
  ILogicTypeDataLineApprove,
  ILogicTypeHideControl,
  ILogicTypeReadOnly,
  ILogicTypeReference,
  ILogicTypeReladToLoadData,
  ILogicTypeShowHide,
  ILogicTypeSourceRelated,
} from "../IRequestModel/ILogicModel";
import { IMemoDetailModel } from "../IRequestModel/IMemoDetailModel";
import { IRolePermission } from "../IRequestModel/IRolePermission";
import { IUserModel } from "../IRequestModel/IUserModel";
import { GetRolePermissionByUser } from "../Services/RoleServices";
import {
  LoadDataFormControlRef,
  onDatareladToLoadData,
} from "../Services/TemplateService";
import { LogicOperatorType, LogicType } from "../constants/logic-type";
import {
  callGenAutoNumber,
  genAutoNum,
  getDefValueInTemplate,
  getRefInforValue,
} from "./RequestScreenHelper";
import { onCheckUserRolePermissionInLogic } from "./RolePermisstion";
import { AutoNumber } from "../components/AntdControlComponent/AutoNumberFix/AutoNumberFix";
import { IAutoNumberAttibute } from "../IRequestModel/IAutoNumberFormat";
import { onProcessLogicLineApprove } from "./LineApprove";
export type advanceFormItemType = {
  value?: any;
  label: string;
  type: string;
  items?: string[];
  columns?: { label: string; type: string; isInTable: boolean }[];
};

export const enumToObject = (
  emun: Record<string, string>
): { [key: string]: string } => {
  const readableLabels: { [key: string]: string } = Object.fromEntries(
    Object.entries(emun).map(([key, value]) => [
      value,
      key.replace(/([a-z])([A-Z])/g, "$1 $2"),
    ])
  );
  return readableLabels;
};

export const getTranFormAdvanceForm = (advanceForm: {
  items: any[];
}): advanceFormItemType[] => {
  let result: advanceFormItemType[] = [];
  advanceForm.items.forEach((item) => {
    const layout = item.layout;
    layout.forEach((control: any) => {
      const temp = control.template;
      if (temp.type === "em") {
        return;
      }

      if (temp.type === "dd") {
        result.push({
          label: temp.label,
          type: temp.type,
          value: temp?.data?.value,
          items: temp.attribute.items.map((e: any) => e.item),
        });
      } else if (temp.type === "tb") {
        result.push({
          label: temp.label,
          type: temp.type,
          value: temp?.data?.value,
          columns: temp.attribute.column.map((e: any) => {
            return {
              label: e.control.template.label,
              type: e.control.template.type,
            };
          }),
        });
      } else {
        result.push({
          label: temp?.label,
          type: temp?.type,
          value: temp?.data?.value,
        });
      }
    });
  });

  return result;
};
export const checkIsCorrect = (
  aValue: string | number,
  operator: LogicOperatorType,
  bValue: string | number
): boolean => {
  switch (operator) {
    case LogicOperatorType.Equal:
      if (typeof aValue === "string" && typeof bValue === "string") {
        bValue = bValue.replace(/\t/g, "");
        const aValueParts = aValue.split(",").map((part) => part.trim());
        return aValueParts.includes(bValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue === bValue;
      }

      if (
        aValue === null ||
        aValue === undefined ||
        bValue === null ||
        bValue === undefined
      ) {
        return aValue !== bValue;
      }

      return false;
    case LogicOperatorType.MoreThan:
      return Number(aValue) > Number(bValue);
    case LogicOperatorType.LessThan:
      return Number(aValue) < Number(bValue);
    case LogicOperatorType.GreaterThanAndEqual:
      return Number(aValue) >= Number(bValue);
    case LogicOperatorType.LessThanAndEqual:
      return Number(aValue) <= Number(bValue);
    case LogicOperatorType.NotEqual:
      if (typeof aValue === "string" && typeof bValue === "string") {
        bValue = bValue.replace(/\t/g, "");
        const aValueParts = aValue.split(",").map((part) => part.trim());

        return !aValueParts.includes(bValue);
      }

      if (typeof aValue === "number" && typeof bValue === "number") {
        return aValue !== bValue;
      }
      if (
        aValue === null ||
        aValue === undefined ||
        bValue === null ||
        bValue === undefined
      ) {
        return aValue !== bValue;
      }
      return false;
    case LogicOperatorType.Contain:
      return String(aValue).includes(String(bValue));
    case LogicOperatorType.StartWith:
      return String(aValue).startsWith(String(bValue));
    case LogicOperatorType.EndWith:
      return String(aValue).endsWith(String(bValue));
    default:
      return false;
  }
};

export const implementValueWithJsonAction = (
  layout: any,
  jsonAction: string
) => {
  switch (jsonAction) {
    case "readonly":
      layout.isReadOnly = true;
      break;
    case "hide":
      layout.isShow = false;
      break;
    case "editable":
      layout.isEditable = true;
      layout.isReadOnly = false;
      break;

    default:
      break;
  }
};

export const isRefInfo = (compareWith: string) => {
  const refInfos = [
    "Requestor_Employee_code",
    "Requestor_NameEn",
    "Requestor_NameTh",
    "Requestor_PositonEn",
    "Requestor_PositonTh",
    "Requestor_DivisionEn",
    "Requestor_DivisionTh",
    "Requestor_DepartmentEn",
    "Requestor_DepartmentTh",
    "Requestor_Email",
    "Actor_Employee_code",
    "Actor_NameEn",
    "Actor_NameTh",
    "Actor_PositonEn",
    "Actor_PositonTh",
    "Actor_DivisionEn",
    "Actor_DivisionTh",
    "Actor_DepartmentEn",
    "Actor_DepartmentTh",
    "Actor_Email",
  ];

  return refInfos.includes(compareWith);
};

export const processHideControlLogic = (
  jsonValue: ILogicTypeHideControl | ILogicTypeReadOnly,
  _control: any,
  updateTo: string,
  memoDetail: IMemoDetailModel,
  userData: IUserModel,
  controlValue?: any,
  controlTemplate?: any,
  ColumnJsonConditions?: any,
  lineApproval?: any
) => {
  let isTable = false;
  let isConditionSatisfied = false;
  try {
    let fieldsToCheck1: any = {};

    jsonValue.conditions.forEach((maincondition: any) => {
      let _con = maincondition;
      _con.conditions.forEach((subCondition: any) => {
        try {
          if (typeof subCondition.field === "string") {
            subCondition.field = JSON.parse(subCondition.field);
          }
        } catch (error) {
          subCondition.field = subCondition.field;
        }

        let checkValue = controlValue ? controlValue : _con.value;

        if (subCondition.comparewith) {
          _control.forEach((control: { layout: any[] }) => {
            control.layout.forEach((layout) => {
              if (
                layout.template.label === subCondition.comparewith ||
                isRefInfo(_con.comparewith || "")
              ) {
                checkValue = getRefInforValue(
                  _con.comparewith || "",
                  memoDetail,
                  userData,
                  layout.data.value
                );
              }
            });
          });
        }

        _control.forEach((control: { layout: any[] }) => {
          control.layout.forEach((layout) => {
            if (layout.template.label === subCondition.field) {
              if (layout.template.type === "cb") {
                const value =
                  layout.data.value !== "" ? layout.data.value : null;

                fieldsToCheck1[subCondition.field] =
                  value === "" ? null : value;
              } else {
                fieldsToCheck1[subCondition.field] = layout.data.value
                  ? layout.data.value
                  : getDefValueInTemplate(layout.template);
              }
            }
          });
        });
        subCondition.Value = checkValue;
      });
    });
    const objLabel2 = JSON.parse(jsonValue.label);

    isConditionSatisfied = isConditionSatisfiedFunc2(fieldsToCheck1, jsonValue);

    //ทำเพื่อดักให้ใช้ได้ทุกเคสทั้งเก่าและใหม่
    try {
      const objLabel = JSON.parse(jsonValue.label);

      if (
        objLabel?.isInTable ||
        (controlTemplate && controlTemplate?.type === "tb")
      ) {
        isTable = true;
      } else {
        _control.forEach((control: { layout: any[] }) => {
          control.layout.forEach((layout: any) => {
            if (layout.template.label) {
              const checkAction = ColumnJsonConditions?.Conditions?.some(
                (con: any) =>
                  con.action ===
                    (updateTo === "isShow" ? "hide" : "readonly") &&
                  con.fieldaction.some(
                    (field: any) => field.key === objLabel.label
                  )
              );
              const current_approval_level = memoDetail?.current_approval_level;
              const checklineCondValue = lineApproval?.some((line: any) => {
                if (line?.sequence !== current_approval_level) return false;

                const columnJsonCondValue = JSON.parse(
                  line.ColumnJsonCondValue
                );

                return columnJsonCondValue.Conditions?.some(
                  (con: any) =>
                    con.action ===
                      (updateTo === "isShow" ? "hide" : "readonly") &&
                    con.fieldaction.some(
                      (field: any) => field.key === objLabel.label
                    )
                );
              });
              if (layout.template.label === objLabel.label) {
                if (updateTo === "isShow") {
                  if (!checkAction && !checklineCondValue)
                    layout.isShow = !isConditionSatisfied;
                } else {
                  if (!checkAction && !checklineCondValue)
                    layout.isReadOnly = isConditionSatisfied;
                }

                if (isConditionSatisfied && jsonValue.resetonaction) {
                  if (layout?.data?.item) {
                    (layout.data.item = getDefValueInTemplate(
                      layout.template
                    )?.item),
                      true;
                  }
                  layout.data.value = getDefValueInTemplate(
                    layout.template,
                    true
                  );
                }
              } else if (layout.template.label === objLabel.isInTable) {
                layout.template.attribute.column.map(
                  (e: {
                    label: string;
                    isShow: boolean;
                    isReadOnly: boolean;
                  }) => {
                    if (e.label === objLabel.label) {
                      const checkAction =
                        ColumnJsonConditions?.Conditions?.some(
                          (con: any) =>
                            con.action ===
                              (updateTo === "isShow" ? "hide" : "readonly") &&
                            con.fieldaction.some(
                              (field: any) => field.key === objLabel.label
                            )
                        );
                      if (updateTo === "isShow") {
                        if (!checkAction) e.isShow = !isConditionSatisfied;
                      } else {
                        if (!checkAction) e.isReadOnly = isConditionSatisfied;
                      }
                    }
                    return e;
                  }
                );
              }
            }
          });
        });
      }
    } catch (error) {
      _control.forEach((control: { layout: any[] }) => {
        control.layout.forEach((layout: any) => {
          if (layout.template.label) {
            if (layout.template.label === jsonValue.label) {
              const checkAction = ColumnJsonConditions?.Conditions?.some(
                (con: any) =>
                  con.action ===
                    (updateTo === "isShow" ? "hide" : "readonly") &&
                  con.fieldaction.some(
                    (field: any) => field.key === jsonValue.label
                  )
              );

              if (updateTo === "isShow") {
                if (!checkAction) layout.isShow = !isConditionSatisfied;
              } else {
                if (!checkAction) layout.isReadOnly = isConditionSatisfied;
              }

              if (isConditionSatisfied && jsonValue.resetonaction) {
                layout.data.value = getDefValueInTemplate(layout.template);
              }
              console.log(layout.data);
            } else if (layout.template.label === jsonValue?.label?.isInTable) {
              layout.template.attribute.column.map(
                (e: {
                  label: string;
                  isShow: boolean;
                  isReadOnly: boolean;
                }) => {
                  if (e.label === jsonValue.label) {
                    const checkAction = ColumnJsonConditions?.Conditions?.some(
                      (con: any) =>
                        con.action ===
                          (updateTo === "isShow" ? "hide" : "readonly") &&
                        con.fieldaction.some(
                          (field: any) => field.key === jsonValue.label
                        )
                    );
                    if (updateTo === "isShow") {
                      if (!checkAction) e.isShow = !isConditionSatisfied;
                    } else {
                      if (!checkAction) e.isReadOnly = isConditionSatisfied;
                    }
                  }
                }
              );
            }
          }
        });
      });
    }
  } catch (error) {
    console.error(error);
  }
  return isTable;
};

export function isConditionSatisfiedFunc(
  controlLabel: string,
  value: any,
  jsonValue: ILogicTypeHideControl | ILogicTypeReadOnly
) {
  try {
    for (let condition of jsonValue?.conditions) {
      for (let cond of condition.conditions) {
        if (cond.field === controlLabel) {
          if (checkIsCorrect(value, cond.operator, cond.value)) {
            return true;
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return false;
}

export const isConditionSatisfiedFunc2 = (
  fields: { [x: string]: string | number },
  data: any
) => {
  for (let conditionGroup of data.conditions) {
    if (areSubConditionsSatisfied(fields, conditionGroup.conditions)) {
      return true;
    }
  }
  return false;
};

const areSubConditionsSatisfied = (
  fields: { [x: string]: string | number },
  data: any[]
) => {
  for (let cond of data) {
    if (
      !checkIsCorrect(
        fields[cond.field],
        cond.operator,
        cond.comparewith ? cond.Value : cond.value
      )
    ) {
      return false;
    }
  }
  return true;
};

export const findValueToSet = (
  inputObject: {
    [key: string]: string;
  },
  jsonValue: any
): string | { copyFrom: string } | null => {
  for (const consGroup of jsonValue.conditions) {
    let isConditionsSatisfied = true;

    for (const condition of consGroup.conditions) {
      const inputValue = inputObject[condition.field];

      if (
        !inputValue ||
        !checkIsCorrect(inputValue, condition.operator, condition.value)
      ) {
        isConditionsSatisfied = false;
        break;
      }
    }

    if (isConditionsSatisfied) {
      if (consGroup.copyFrom) {
        return { copyFrom: consGroup.copyFrom };
      } else {
        return consGroup.valuetoset;
      }
    }
  }
  return null;
};

export const checkLogicTemplate = (
  type: LogicType,
  logic: ILogic,
  controlTemplate: any
) => {
  let callLogic: any[] = [];

  const jsonValue: ILogicTypeSourceRelated =
    logic.jsonvalue?.length > 0 &&
    JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
  switch (type) {
    case LogicType.DataSourceRelated:
      if (controlTemplate.type === "tb") {
        for (let j = 0; j < controlTemplate.attribute.column.length; j++) {
          const col = controlTemplate.attribute.column[j];
          jsonValue.label === col.label && callLogic.push(logic);
        }
      } else {
        jsonValue.label === controlTemplate.label && callLogic.push(logic);
      }
      return callLogic;
    case LogicType.DataAjaxLoadTable:
      const selectAction = jsonValue.labelactions.find(
        (e) => e.label === controlTemplate.label
      );
      selectAction && callLogic.push(logic);
      return callLogic;
    case LogicType.DataRelatedToLoadData:
      const jsonValueLoadData: ILogicTypeReladToLoadData =
        logic.jsonvalue?.length > 0 &&
        JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
      if (controlTemplate.type === "tb") {
        for (let i = 0; i < jsonValueLoadData.labelactions.length; i++) {
          const labelAction = jsonValueLoadData.labelactions[i];
          for (let j = 0; j < controlTemplate.attribute.column.length; j++) {
            const col = controlTemplate.attribute.column[j];
            labelAction.label === col.label && callLogic.push(logic);
          }
        }
      } else {
        for (let i = 0; i < jsonValueLoadData.labelactions.length; i++) {
          const labelAction = jsonValueLoadData.labelactions[i];
          labelAction.label === controlTemplate.label && callLogic.push(logic);
        }
      }
      return callLogic;
    case LogicType.Reference:
      const jsonValueReference: ILogicTypeReference =
        logic.jsonvalue?.length > 0 &&
        JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
      for (let i = 0; i < jsonValueReference.Filter.length; i++) {
        const filter = jsonValueReference.Filter[i];
        filter.TBColumn === controlTemplate.label && callLogic.push(logic);
      }
      return callLogic;
    case LogicType.DataLineApprove:
      const jsonValueApprove: ILogicTypeDataLineApprove =
        logic.jsonvalue?.length > 0 &&
        JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
      jsonValueApprove.label === controlTemplate.label && callLogic.push(logic);
      return callLogic;
    case LogicType.SetValueControl:
    case LogicType.HideControl:
    case LogicType.ReadOnlyControl:
      const jsonValueControl: any = JSON.parse(logic.jsonvalue);
      let allLabel: any[] = [];
      const tempColumns: string[] =
        controlTemplate?.attribute?.column?.map(
          (e: { label: any }) => e.label
        ) ?? [];
      jsonValueControl.conditions.map((con: any) => {
        try {
          if (typeof con.field === "string") {
            con.field = JSON.parse(con.field);
          }
          con.conditions.forEach((condition: any) => {
            allLabel.push(condition.field);
          });
        } catch (error) {
          console.error(error);
        }
      });
      (allLabel.includes(controlTemplate.label) ||
        allLabel.filter((e) => tempColumns.includes(e)).length > 0) &&
        callLogic.push(logic);
      return callLogic;
    default:
      return [];
  }
};

export const getRequestorValue = (required: string, requestor: any) => {
  const {
    DefaultLang,
    EmployeeCode,
    NameEn,
    NameTh,
    PositionNameEn,
    PositionNameTh,
    DivisionNameEn,
    DivisionNameTh,
    DepartmentNameEn,
    DepartmentNameTh,
    Email,
  } = requestor;

  const values: Record<string, string> = {
    Requestor_Employee_code: EmployeeCode,
    Requestor_Name: DefaultLang === "EN" ? NameEn : NameTh,
    Requestor_Positon: DefaultLang === "EN" ? PositionNameEn : PositionNameTh,
    Requestor_Division: DefaultLang === "EN" ? DivisionNameEn : DivisionNameTh,
    Requestor_Department:
      DefaultLang === "EN" ? DepartmentNameEn : DepartmentNameTh,
    Requestor_Email: Email,
  };

  return values[required] || "";
};

export const deserializeColumnJsonCondValue = (ColumnJsonCondValue: string) => {
  if (!ColumnJsonCondValue) return [];
  let condValue = JSON.parse(ColumnJsonCondValue);
  if (!condValue) {
    return [];
  } else if (!condValue.Conditions && condValue.action) {
    return [condValue];
  } else if (Array.isArray(condValue.Conditions)) {
    return condValue.Conditions.filter((x: any) => x.action);
  }
};

export const prepareDatasourceload = (logic: ILogic, _templateDesc: any) => {
  const jsonValue: any = logic.jsonvalue?.length && JSON.parse(logic.jsonvalue);
  let dataSourceLoadGroup: any[] = [];
  let table_dd_to_dd_Result: any[] = [];
  _templateDesc.items.forEach((templateItems: any, rowIndex: number) => {
    templateItems.layout.forEach((_layout: any, colIndex: number) => {
      const { template } = _layout;
      if (template.type !== "tb") {
        if (template.label === jsonValue.label) {
          dataSourceLoadGroup.push({
            isInTable: false,
            col: colIndex,
            row: rowIndex,
            data: logic.logicid,
            jsonValue: logic.jsonvalue,
          });
        }
      } else if (Array.isArray(template.attribute.column)) {
        template.attribute.column.forEach((column: any) => {
          if (column.label === jsonValue.label) {
            table_dd_to_dd_Result.push(logic);
          }
        });
      }
    });
  });
  return { dataSourceLoadGroup, table_dd_to_dd_Result };
};
type TemplateType = {
  isShow: boolean;
  pos: {
    row: number;
    col: number;
    tableCols?: number[];
    notUseCols?: number[];
  }[];
  readonly?: boolean;
  notInPos: { row: number; col: number }[];
};
export const prepareRole = async (
  logic: ILogic,
  _templateDesc: any,
  _memoDetail: any,
  userData: IUserModel,
  _lineApproval?: any[]
) => {
  let showTemplate: TemplateType = { isShow: false, pos: [], notInPos: [] };
  const jsonValue: ILogicTypeShowHide =
    logic.jsonvalue?.length > 0 && JSON.parse(logic.jsonvalue);

  if (jsonValue.action !== "showpdf" && jsonValue?.roleids?.length) {
    let userRole: IRolePermission[] = [];
    let roles: IRolePermission[] = await GetRolePermissionByUser();
    const currentApproval = _lineApproval?.find(
      (e: any) => e.sequence == _memoDetail.current_approval_level
    ).emp_id;
    userRole =
      _memoDetail.status === "Wait for Process"
        ? roles.filter(
            (role) =>
              role.IsDelete === false &&
              role.EmployeeId === currentApproval.emp_id
          )
        : roles.filter(
            (role) =>
              role.IsDelete === false && role.EmployeeId === userData.EmployeeId
          );

    const isShow = onCheckUserRolePermissionInLogic(
      userRole,
      jsonValue.roleids
    );
    showTemplate = {
      ...showTemplate,
      readonly: jsonValue.action === "readonly" ? true : showTemplate.readonly,
      isShow,
    };

    if (jsonValue.fieldaction) {
      for (let k = 0; k < jsonValue.fieldaction.length; k++) {
        const field = jsonValue.fieldaction[k];
        for (let i = 0; i < _templateDesc.items.length; i++) {
          const templateItems = _templateDesc.items[i];
          for (let j = 0; j < templateItems.layout.length; j++) {
            const templateLayouts = templateItems.layout[j];

            if (
              field.lable === templateLayouts.template.label ||
              field.isInTable === templateLayouts.template.label ||
              field === templateLayouts.template.label
            ) {
              if (templateLayouts.template.type === "tb") {
                let targetCols: number[] = [];
                let notTargetCols: number[] = [];

                templateLayouts.template.attribute.column.forEach(
                  (e: any, colIndex: number) => {
                    e.label === field.lable
                      ? targetCols?.push(colIndex)
                      : notTargetCols.push(colIndex);
                  }
                );
                showTemplate.pos.push({
                  row: i,
                  col: j,
                  tableCols: targetCols,
                  notUseCols: notTargetCols,
                });
              } else {
                showTemplate.pos.push({
                  row: i,
                  col: j,
                });
              }
            } else {
              showTemplate.notInPos.push({
                row: i,
                col: j,
              });
            }
          }
        }
      }
    }
    if (showTemplate.pos.length) {
      showTemplate.pos.forEach((pos) => {
        const layoutItem = _templateDesc.items[pos.row].layout[pos.col];
        const { template } = layoutItem;
        if (template.type === "tb") {
          layoutItem.isShow = true;
          template.attribute.column = template.attribute.column.map(
            (column: any, colIndex: number) => {
              if (column.label !== "rowstate") {
                if (pos.tableCols?.includes(colIndex)) {
                  if (showTemplate.readonly) {
                    column.control.template.attribute.require = "Y";
                  }
                  column.isShow =
                    jsonValue.action !== "hide" ? isShow : !isShow;
                }
              } else {
                column.isShow = false;
              }
              return column;
            }
          );
        } else {
          if (jsonValue.action === "readonly") {
            layoutItem.isReadOnly = isShow;
          } else {
            layoutItem.isShow = jsonValue.action !== "hide" ? isShow : !isShow;
          }
        }
      });
    }
  }
  return _templateDesc;
};

export const prepareReference = (
  permittedValues: any[],
  templateDesc: any,
  responseData: { loadTo: any[]; data: any[]; row: number; col: number }
) => {
  let _templateDesc = JSON.parse(JSON.stringify(templateDesc));
  let resRow: any[] = [];
  if (permittedValues) {
    for (let i = 0; i < permittedValues.length; i++) {
      const perValue = permittedValues[i];
      let colData = new Array(
        templateDesc?.items[responseData.row].layout[
          responseData.col
        ].template.attribute.column.length
      ).fill({ value: "" });
      for (const [key, value] of Object.entries(perValue)) {
        const tableCols =
          templateDesc?.items[responseData.row].layout[responseData.col]
            .template.attribute.column;
        Array.isArray(tableCols) &&
          tableCols.forEach((_tableCol, idx) => {
            if (_tableCol.label === key) {
              colData[idx] = { value };
            }
          });
      }
      resRow.push(colData);
    }
    const layoutData =
      _templateDesc.items[responseData.row].layout[responseData.col].data;

    if (resRow.length > 0 && (!layoutData.row || layoutData.row.length === 0)) {
      layoutData.row = [...resRow];
    }
  }
  return { resRow, _templateDesc };
};

export const prepareReferenceNofilter = (
  permittedValues: any[],
  templateDesc: any,
  responseData: { loadTo: any[]; data: any[]; row: number; col: number }
) => {
  let resRow: any[] = [];
  let _templateDesc = JSON.parse(JSON.stringify(templateDesc));
  for (let i = 0; i < permittedValues.length; i++) {
    const perValue = permittedValues[i];

    let colData = new Array(
      templateDesc?.items[responseData.row].layout[
        responseData.col
      ].template.attribute.column.length
    ).fill({ value: "" });
    Object.entries(perValue).forEach(([key, value]) => {
      const columns =
        templateDesc?.items[responseData.row].layout[responseData.col].template
          .attribute.column;
      const colIndex = columns.findIndex((col: any) => col.label === key);
      if (colIndex !== -1) {
        colData[colIndex] = { value };
      }
    });
    resRow.push(colData);
  }
  const layoutData =
    _templateDesc.items[responseData.row].layout[responseData.col].data;

  if (resRow.length > 0 && (!layoutData.row || layoutData.row.length === 0)) {
    layoutData.row = [...resRow];
  }
  return { resRow, _templateDesc };
};

export const prepareDatareladtoloaddata = (
  logic: ILogic,
  _templateDesc: any,
  isFirstRun: React.MutableRefObject<boolean>
) => {
  const jsonValue: ILogicTypeReladToLoadData =
    logic.jsonvalue &&
    logic.jsonvalue.length > 0 &&
    JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
  for (let i = 0; i < _templateDesc.items.length; i++) {
    const templateItems = _templateDesc.items[i];
    for (let j = 0; j < templateItems.layout.length; j++) {
      const _layout = templateItems.layout[j];
      if (
        _layout.template.type !== "tb" &&
        _layout.template.label === jsonValue.autoloadvaluelabel.label
      ) {
        if (
          _layout.data.value === null ||
          (!isFirstRun.current && _layout.data.value === null)
        ) {
          _layout.data.value = getDefValueInTemplate(_layout.template);
        }
      }
    }
  }
  return _templateDesc;
};

export const prepareRefinfomation = (
  logic: ILogic,
  _templateDesc: any,
  _memoDetail: any,
  masterCompanies: any[],
  userData: IUserModel,
  isFirstRun: React.MutableRefObject<boolean>,
  allLogic: any[]
) => {
  const jsonValue: ILogicReferenceInformation =
    logic.jsonvalue &&
    logic.jsonvalue.length > 0 &&
    JSON.parse(logic.jsonvalue);
  let table_dd_to_dd_Result: any = [];
  let hasDetalineapprove: any = null;
  let actionTemplate: any = null;
  _templateDesc.items.forEach((templateItems: any, i: number) => {
    templateItems.layout.forEach((_layout: any, j: number) => {
      const { template, data } = _layout;
      if (template.type !== "tb") {
        try {
          let objMemo = _memoDetail;

          if (!objMemo.company_id) {
            const { CompanyId, NameEn } = masterCompanies[0];
            objMemo = {
              ...objMemo,
              company_id: CompanyId,
              company_name: NameEn,
            };
          }

          const refValue = getRefInforValue(
            jsonValue.required,
            objMemo,
            userData
          );

          if (template.label === jsonValue.condition) {
            data.value =
              jsonValue.required.startsWith("Company_") && refValue !== ""
                ? masterCompanies.find(({ CompanyId }) => CompanyId == refValue)
                    ?.NameEn || ""
                : refValue;

            if (!isFirstRun.current) {
              const newLogic = checkCallLogic(allLogic, template);
              hasDetalineapprove = newLogic.find(
                (val) => val.logictype === "datalineapprove"
              );
              actionTemplate = _layout;
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        const conditions = jsonValue.condition.split(" | ");
        if (conditions[0] === template.label) {
          table_dd_to_dd_Result.push(logic);
        }
      }
    });
  });

  return {
    table_dd_to_dd_Result,
    hasDetalineapprove,
    actionTemplate,
    _templateDesc,
  };
};

export const prepareDatasourcerelated = async (
  logic: ILogic,
  _templateDesc: any,
  templateDesc: any,
  autoNumFormat: IAutoNumberAttibute,
  memoDetail: any
) => {
  const jsonValue: any =
    logic.jsonvalue &&
    logic.jsonvalue.length > 0 &&
    JSON.parse(logic.jsonvalue);
  const logicRelated = logic;
  let dataValue;
  let jsonRelated: any[] = [];
  let actionDesCription = "";
  const conditions = jsonValue.conditions;
  if (Array.isArray(conditions) && conditions.length) {
    conditions.forEach((e: { value: any; label: any }) => {
      if (!e.value) {
        _templateDesc.items.forEach((item: any) => {
          item.layout.forEach((_layout: any) => {
            const _label = _layout.label || _layout.template.label;
            if (e.label === _label) {
              jsonRelated.push({
                Key: _label,
                Value: _layout.data.value,
                logicid: logic.logicid,
              });
            }
          });
        });
      }
    });
  } else {
    _templateDesc.items.forEach((templateItems: any) => {
      templateItems.layout.forEach((_layout: any) => {
        if (_layout.template.label === jsonValue.label) {
          dataValue = _layout.data.value || _layout.template.attribute?.default;
          actionDesCription =
            _layout.template.description ||
            _layout.template.attribute?.description;
        }
      });
    });
  }

  let responseData = null;
  if (dataValue) {
    const dataJson = {
      Key: jsonValue.label,
      Value: dataValue,
      logicid: logicRelated.logicid,
    };
    responseData = await onProcessLogicDataSourceRelated(
      dataJson,
      jsonValue,
      templateDesc,
      actionDesCription
    );
  } else if (jsonRelated.length > 0) {
    responseData = await onProcessLogicDataSourceRelated(
      jsonRelated,
      jsonValue,
      templateDesc,
      actionDesCription
    );
  }

  if (responseData && responseData?.data?.length === 1) {
    let newControl = _templateDesc.items;

    responseData.relateGroup.forEach((relateGroup: any) => {
      responseData.data.forEach(async (relateData: any) => {
        const layoutItem = newControl[relateGroup.row].layout[relateGroup.col];
        const relateValue = relateData[relateGroup.relateValue.value];

        if (layoutItem.template.type === "cb") {
          const resVals: string[] = relateValue.split(",");
          const items = layoutItem.template.attribute.items.map((e: any) =>
            resVals.includes(e.item) ? "Y" : "N"
          );
          layoutItem.data.item = items;
          layoutItem.data.value = relateValue;
        } else {
          layoutItem.data.value = relateValue;
        }

        if (callGenAutoNumber(autoNumFormat, layoutItem.template)) {
          newControl = await genAutoNum(
            newControl,
            autoNumFormat,
            memoDetail.template_id,
            memoDetail.status
          );
        }
      });
    });
  }
  return { jsonRelated, _templateDesc };
};
export const prepareDatalineapprove = async (
  logic: ILogic,
  _templateDesc: any,
  setJsonConditions: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  if (logic.logictype === "datalineapprove") {
    const jsonValue =
      logic.jsonvalue?.length > 0
        ? JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"))
        : null;

    if (jsonValue) {
      for (const templateItems of _templateDesc.items || []) {
        for (const layout of templateItems.layout) {
          if (jsonValue.label === layout.template.label) {
            const responseData = await onProcessLogicLineApprove(
              logic,
              layout.template,
              layout.data.value,
              _templateDesc
            );
            if (responseData) {
              setJsonConditions(responseData);
              return; // Exit the function after setting jsonConditions
            }
          }
        }
      }
    }
  }
  return _templateDesc;
};
export const prepareSetValueControl = (
  logic: ILogic,
  _templateDesc: any,
  isFirstRun: React.MutableRefObject<boolean>,
  allLogic: any[],
  autoNumFormat: IAutoNumberAttibute,
  memoDetail: any,
  setIsControlLoading: (valaue: boolean) => void,
  getLineApproveForAmount: (
    _amount: number,
    jsonCondition?: any,
    logicType?: string,
    template?: any,
    _requestor?: any
  ) => Promise<void>
) => {
  const jsonValue: any =
    logic.jsonvalue &&
    logic.jsonvalue.length > 0 &&
    JSON.parse(logic.jsonvalue.replace(`},\r\n],`, "}],"));
  jsonValue.actionControl = JSON.parse(jsonValue.actionControl);
  let table_dd_to_dd_Result: any[] = [];
  let hasDatalineapprove: any = null;
  let actionTemplate: any = null;
  if (!jsonValue?.actionControl?.isInTable) {
    table_dd_to_dd_Result.push(logic);
  } else {
    let valueToSet: any = null;
    jsonValue.conditions.forEach(
      async (consGroup: { conditions: string | any[]; valuetoset: any }) => {
        let isConditionsSatisfied = true;

        Array.isArray(consGroup.conditions) &&
          consGroup.conditions.forEach((con) => {
            const conditionSatisfied = _templateDesc.items.some((item: any) =>
              item.layout.some((_layout: any) => {
                const _label = _layout.label || _layout.template.label;

                if (con.field === _label) {
                  const dataValue =
                    _layout.data.value ??
                    getDefValueInTemplate(_layout.data.value);
                  return checkIsCorrect(dataValue, con.operator, con.value);
                }
                return false;
              })
            );

            isConditionsSatisfied = isConditionsSatisfied && conditionSatisfied;
          });

        if (isConditionsSatisfied) {
          valueToSet = consGroup.valuetoset;
        }

        _templateDesc.items.forEach((control: { layout: any[] }) => {
          control.layout.forEach((layout) => {
            if (layout.template.label === jsonValue.actionControl.label) {
              layout.data.value = valueToSet;
              if (!isFirstRun.current) {
                const newLogic = checkCallLogic(allLogic, layout.template);
                hasDatalineapprove = newLogic.find(
                  (e) => e.logictype === "datalineapprove"
                );
                actionTemplate = layout;
              }
            } else {
              try {
                if (
                  layout.template.label === jsonValue.actionControl.isInTable
                ) {
                  const colIdx = layout?.template?.attribute?.column
                    ?.map((e: any) => e.label)
                    .indexOf(jsonValue.actionControl.label);

                  layout.data.row?.map((row: any) => {
                    row[colIdx].value = valueToSet;
                  });
                }
              } catch (error) {
                console.error(error);
              }
            }
          });
        });
        if (actionTemplate) {
          if (callGenAutoNumber(autoNumFormat, actionTemplate?.template)) {
            _templateDesc.items = await genAutoNum(
              _templateDesc.items,
              autoNumFormat,
              memoDetail.template_id,
              memoDetail.status
            );
          }

          if (hasDatalineapprove) {
            const res = await onProcessLogicLineApprove(
              hasDatalineapprove,
              actionTemplate.template,
              actionTemplate.data.value,
              _templateDesc.items
            );

            if (res) {
              await getLineApproveForAmount(
                memoDetail?._amount,
                res,
                LogicType.DataLineApprove,
                actionTemplate.template
              );
              setIsControlLoading(false);

              // return { type: "lineapprove", value: responseData };
            }
          }
        }
      }
    );
  }
  return { table_dd_to_dd_Result, _templateDesc };
};

export const onProcessLogicDataSourceRelated = async (
  jsonRelated: any,
  jsonValue: any,
  _templateDesc: any,
  actionDesCription: string
) => {
  let responseDataSourceLoadRelated: any = {
    relateGroup: [],
    data: [],
  };

  responseDataSourceLoadRelated.data = await onDatareladToLoadData(jsonRelated);

  if (responseDataSourceLoadRelated.data?.length > 0) {
    for (let i = 0; i < jsonValue.relatedvalue?.length; i++) {
      let relateValue = jsonValue?.relatedvalue[i];

      for (let j = 0; j < _templateDesc?.items?.length; j++) {
        const item = _templateDesc?.items[j];
        for (let k = 0; k < item.layout?.length; k++) {
          const layout = item?.layout[k];

          if (layout.template.label === relateValue.label) {
            responseDataSourceLoadRelated.relateGroup.push({
              row: j,
              col: k,
              relateValue,
            });
          }
        }
      }
    }
  }
  return responseDataSourceLoadRelated;
};

export const checkCallLogic = (logics: any, controlTemplate: any) => {
  let callLogic: any[] = [];
  logics.forEach((logic: ILogic) => {
    let getLogics: any[] = [];
    if (logic.logictype === LogicType.DataSourceRelated) {
      getLogics = checkLogicTemplate(
        LogicType.DataSourceRelated,
        logic,
        controlTemplate
      );
    } else if (logic.logictype === LogicType.DataAjaxLoadTable) {
      getLogics = checkLogicTemplate(
        LogicType.DataAjaxLoadTable,
        logic,
        controlTemplate
      );
    } else if (logic.logictype === LogicType.DataRelatedToLoadData) {
      getLogics = checkLogicTemplate(
        LogicType.DataRelatedToLoadData,
        logic,
        controlTemplate
      );
    } else if (logic.logictype === LogicType.Reference) {
      getLogics = checkLogicTemplate(
        LogicType.Reference,
        logic,
        controlTemplate
      );
    } else if (logic.logictype === LogicType.DataLineApprove) {
      getLogics = checkLogicTemplate(
        LogicType.DataLineApprove,
        logic,
        controlTemplate
      );
    } else if (
      [
        LogicType.SetValueControl,
        LogicType.HideControl,
        LogicType.ReadOnlyControl,
      ].includes(logic.logictype)
    ) {
      getLogics = checkLogicTemplate(logic.logictype, logic, controlTemplate);
    }
    callLogic = [...callLogic, ...getLogics];
  });
  return callLogic;
};

export async function handleDatasourcerelated(
  logic: ILogic,
  _templateDesc: any,
  templateDesc: any,
  memoDetail: IMemoDetailModel,
  autoNumFormat: IAutoNumberAttibute
) {
  const jsonValue: any =
    logic.jsonvalue &&
    logic.jsonvalue.length > 0 &&
    JSON.parse(logic.jsonvalue);
  let dataValue;
  let actionDesCription = "";
  const conditions = jsonValue.conditions;
  let jsonRelated: any = [];

  if (conditions.length > 0) {
    conditions.forEach((e: any) => {
      if (e.label) {
        for (let i = 0; i < _templateDesc.items.length; i++) {
          const templateItems = _templateDesc.items[i];
          for (let j = 0; j < templateItems.layout.length; j++) {
            const _layout = templateItems.layout[j];

            if (_layout.template.label === e.label) {
              dataValue =
                _layout.data.value || _layout.template.attribute?.default;
              actionDesCription =
                _layout.template.description ||
                _layout.template.attribute?.description;
              jsonRelated.push({
                Key: e.label,
                Value: dataValue,
                logicid: logic.logicid,
              });
            }
          }
        }
      }
    });
  }

  if (jsonRelated.length > 0 && jsonRelated.every((e: any) => e.Value)) {
    const responseData = await onProcessLogicDataSourceRelated(
      jsonRelated,
      jsonValue,
      templateDesc,
      actionDesCription
    );

    if (responseData.data.length === 1) {
      for (let i = 0; i < responseData.relateGroup.length; i++) {
        const relateGroup = responseData.relateGroup[i];
        for (let j = 0; j < responseData.data.length; j++) {
          const relateData = responseData.data[j];

          if (
            _templateDesc.items[relateGroup.row].layout[relateGroup.col]
              .template.type === "cb"
          ) {
            let items =
              _templateDesc.items[relateGroup.row].layout[relateGroup.col]
                .template.attribute.items;
            const resVals: string[] =
              relateData[relateGroup.relateValue.value].split(",");

            items = items.map((e: any) => {
              if (resVals.includes(e.item)) {
                return "Y";
              }
              return "N";
            });
            _templateDesc.items[relateGroup.row].layout[
              relateGroup.col
            ].data.item = items;
            _templateDesc.items[relateGroup.row].layout[
              relateGroup.col
            ].data.value = relateData[relateGroup.relateValue.value];
          } else {
            _templateDesc.items[relateGroup.row].layout[
              relateGroup.col
            ].data.value = relateData[relateGroup.relateValue.value];
          }
          if (
            callGenAutoNumber(
              autoNumFormat,
              _templateDesc.items[relateGroup.row].layout[relateGroup.col]
                .template
            )
          ) {
            _templateDesc.items = await genAutoNum(
              _templateDesc.items,
              autoNumFormat,
              memoDetail.template_id,
              memoDetail.status
            );
          }
        }
      }
    }
  }
}
