import React, { useState } from "react";
import { LogicFormProps } from "../JsonLogicForm";
import { Button, Form, Input, Select, Space } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import SetValueControlModal from "./SetValueControlModal";
import { ButtonComponents } from "../../../ButtonComponents/ButtonComponents";
import "./formLogic.css";

const SetValueControlForm = ({ advanceFormItem, form }: LogicFormProps) => {
  const [selectedDataIdx, setSelectedDataIdx] = useState<number>(-1);

  const controlOption: any[] = advanceFormItem
    .map((item) => {
      if (item.type === "tb" && item.columns) {
        return {
          label: item.label,
          value: JSON.stringify({
            label: item.label,
            type: item.type,
          }),
          options: item.columns.map((col) => ({
            label: col.label,
            value: JSON.stringify({
              label: col.label,
              type: col.type,
              isInTable: item.label,
            }),
          })),
        };
      }
      return {
        label: item.label,
        value: JSON.stringify({
          label: item.label,
          type: item.type,
        }),
      };
    })
    .filter((e) => e.label);
  return (
    <>
      <Form.Item label="Action control" name="actionControl">
        <Select
          placeholder="Action control"
          showSearch
          style={{ minWidth: 200 }}
          options={controlOption}
        />
      </Form.Item>
      <Form.List name="conditions">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  size={"middle"}
                  align="start"
                  className="edit-conditions-form-logic"
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  // }}
                >
                  <Form.Item name={[name, "conditions"]}>
                    <ButtonComponents
                      key={"con" + idx}
                      setStyleProps={{
                        width: "150px",
                        borderRadius: "6px",
                        boxShadow: "none",
                        border: "1px solid #282f6a",
                        fontSize: "13px",
                        paddingLeft: "16px",
                      }}
                      typeProps={"button"}
                      setClassNameProps={"p-button-text-position"}
                      setLabelProps={"Edit Conditions"}
                      onClickProps={() => {
                        setSelectedDataIdx(idx);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, "valuetoset"]}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Input placeholder="Value to set" />
                  </Form.Item>
                  <Form.Item name={[name, "copyFrom"]}>
                    <Select
                      placeholder="Copy from"
                      showSearch
                      style={{ minWidth: 200 }}
                      options={controlOption.filter(
                        (e) => e.label !== form.getFieldValue("actionControl")
                      )}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  className="mt-3"
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add condition
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <SetValueControlModal
        advanceFormItem={advanceFormItem}
        rowData={
          selectedDataIdx !== -1
            ? form.getFieldValue("conditions")[selectedDataIdx] ?? {
                conditions: [],
              }
            : undefined
        }
        onSubmit={(formData: any) => {
          if (formData) {
            let allField: any = form.getFieldsValue();

            allField["conditions"][selectedDataIdx].conditions =
              formData.conditions;

            form.setFieldsValue(allField);
          }
          setSelectedDataIdx(-1);
        }}
        form={form}
      />
    </>
  );
};

export default SetValueControlForm;
