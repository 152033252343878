import moment from "moment";
import { IUserModel } from "../IRequestModel/IUserModel";
import {
  IGetWorklistRequest,
  ITodo,
  ITodoModel,
} from "../IRequestModel/IWorklistModel";
import { GetAllEmployee } from "./EmployeeService";

export const GetWorkListByTaskGroup = async (
  dataJson: IGetWorklistRequest,
  signal?: AbortSignal
): Promise<ITodoModel> => {
  // const email = JSON.parse(window.localStorage.getItem("email") || "");
  // dataJson.UserPrincipalName = email;
  const response = await fetch("api/Worklist/GetWorkListByTaskGroup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(dataJson),
    signal: signal,
  })
    .then((response) => response.json())
    .catch((error) => {
      if (error instanceof DOMException && error.name === 'AbortError') {
        console.warn('Fetch aborted:', signal?.reason);
      } else {
        console.error('Fetch error:', error);
      }
      return false;
    });

  return response;
};

export const Getinfomation = async (
  dataJson: IGetWorklistRequest,
  signal?: AbortSignal
): Promise<any> => {
  // const email = JSON.parse(window.localStorage.getItem("email") || "");
  // dataJson.UserPrincipalName = email;
  const response = await fetch("api/Worklist/Getinfomation", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(dataJson),
    signal: signal,
  })
    .then((response) => response.json())
    .catch((error) => {
      if (error instanceof DOMException && error.name === 'AbortError') {
        console.warn('Fetch aborted:', signal?.reason);
      } else {
        console.error('Fetch error:', error);
      }

      return { totalCount: 0, workListDtos: [] };
    });

  return response;
};
